import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router';
import { VendorService } from '../../../services/user/vendor.service';
import { useSelector } from "react-redux";
import { CategoryService } from '../../../services/user/category.services';
//import CommonCategoryForm from './category/common-categoryform.component';

import { handleNotify, TOASTER_TYPE, TOASTER_POSITION } from '../../common/notification/toaster_notify.component';
import CategoryModal from '../category/categorymodal';

const AddVendorComponent = ({ isInPayment = false, showBreadcrumbs = true, showWrapper = true, isPopUp = false, handleClosePopup, onCancel, setNewVendorId }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [newCategoryId, setNewCategoryId] = useState(null);
    const pageNumber = location.state?.pageNumber || 0;
    //const vendorData = location.state?.vendorData || {};
    const vendorId = location.state?.vendorId || 0;
    const isEditMode = !!vendorId;
    const selectedStore = useSelector(state => state?.user?.selectedStoreId);
    const [vendorNameExist, setVendorNameExist] = useState(false);

    const formik = useFormik({
        initialValues: {
            vendorId: 0,
            storeId: selectedStore,
            vendorName: '',
            categoryId: 0,
            rebates: 0,
            contactPersonFirstName: '',
            contactPersonLastName: '',
            contactTitle: '',
            phoneNumber: '',
            faxNumber: '',
            email: '',
            address: '',
            city: '',
            state: '',
            country: '',
            zip: '',    
        },
        validationSchema: Yup.object({
            vendorName: Yup.string().required('Vendor Name is required'),
            categoryId: Yup.string().required('Category is required'),
            phoneNumber: Yup.string().matches(/^\d{10}$/, 'Phone Number must be exactly 10 digits'),
            email: Yup.string().email('Invalid email address'),
        }),

        onSubmit: async (values) => {
            try {
                if (isEditMode) {
                    await VendorService.updateVendorById(vendorId, values)
                        .then(response => {
                            if (response?.isSucess) {
                                navigate('/accountsetup/vendormaster', { state: { pageNumber: pageNumber } });
                            }
                        });
                } else {
                    await VendorService.createVendor(values).then(response => {
                        if (response?.isSucess) {
                            if (isPopUp) {
                                handleClosePopup();
                                setNewVendorId(response.vendorId);
                            }
                            else {
                                navigate('/accountsetup/vendormaster', { state: { pageNumber: pageNumber } });
                            }
                        }
                    });
                }
                // navigate('/accountsetup/vendormaster');
            } catch (error) {
                console.error('Error', error);
                handleNotify('An unexpected error occurred. Please contact support.', TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            }
        }
    });

    const handleCancel = () => {
        if (isPopUp)
            handleClosePopup()
        else
            navigate('/accountsetup/vendormaster', { state: { pageNumber: pageNumber } });
    };

    const fetchCategory = async () => {
        try {
            const response = await CategoryService.getAllCategory(selectedStore);
            const sortedCategory = response.sort((a, b) => b.categoryId - a.categoryId);
            formik.values.categoryId = sortedCategory?.[0]?.categoryId;
            setCategories(sortedCategory);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    useEffect(() => {
        fetchCategory();
    }, [isModalOpen == false]);

    useEffect(() => {
        if (isEditMode) {
            VendorService.getVendorById(vendorId)
                .then(response => {
                    formik.setValues({
                        vendorId: response.vendorId || 0,
                        storeId: selectedStore,
                        vendorName: response.vendorName || '',
                        categoryId: response.categoryId || 0,
                        // rebates: response.rebates?.toString() || 0,
                        rebates: response.rebates || 0,
                        contactPersonFirstName: response.contactPersonFirstName || '',
                        contactPersonLastName: response.contactPersonLastName || '',
                        contactTitle: response.contactTitle || '',
                        phoneNumber: response.phoneNumber || '',
                        faxNumber: response.faxNumber || '',
                        email: response.email || '',
                        address: response.address || '',
                        city: response.city || '',
                        state: response.state || '',
                        country: response.country || '',
                        zip: response.zip || '',
                    });
                })
                .catch(error => {
                    console.error('Error fetching vendor:', error);
                    handleNotify('Error fetching vendor data.', TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
                });
        }
    }, []);

    const handleCancelClick = () => {
        navigate('/accountsetup/vendormaster', { state: { pageNumber: pageNumber } });
    };

    return (
        <div className={showWrapper ? 'page-wrapper' : ''}>
            <div className="page-content">
                {showBreadcrumbs && !isInPayment && (
                    <>
                        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                            <div className="breadcrumb-title pe-3">Vendor Master</div>
                            <div className="ps-3">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 p-0">
                                        <li className="breadcrumb-item"></li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {isEditMode ? 'Edit Vendor Master' : 'Add Vendor Master'}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="page-breadcrumb d-xl-none d-xl-block align-items-center mb-3">
                            <div className="breadcrumb-item pe-3 ">{isEditMode ? 'Edit Vendor Master' : 'Add Vendor Master'}</div>
                        </div>
                        <hr />
                    </>
                )}

                <div className="card">
                    <div className="card-body p-4">
                        <form onSubmit={formik.handleSubmit} className="row g-3">
                            <div className="col-12 mt-3">
                                <strong>Vendor:</strong>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="vendorName" className="form-label">Vendor Name:<span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className={`form-control ${formik.touched.vendorName && formik.errors.vendorName ? 'is-invalid' : ''}`}
                                    id="vendorName"
                                    name="vendorName"
                                    autoComplete="off"
                                    value={formik.values.vendorName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.vendorName && formik.errors.vendorName && (
                                    <div className='text-danger'>{formik.errors.vendorName}</div>
                                )}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="categoryId" className="form-label">Category:<span className="text-danger">*</span></label>
                                <div className="d-flex align-items-center">
                                    <select
                                        className={`form-select ${formik.touched.categoryId && formik.errors.categoryId ? 'is-invalid' : ''}`}
                                        id="categoryId"
                                        name="categoryId"
                                        value={formik.values.categoryId}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    >
                                        {/* <option value="">Select Category</option>  */}
                                        {categories.map(category => (
                                            <option key={category.categoryId} value={category.categoryId}>
                                                {category.categoryName}
                                            </option>
                                        ))}
                                    </select>
                                    <div
                                        className="circle-plus ms-2" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add New Vendor"
                                    >
                                        <i className="lni lni-circle-plus"
                                            onClick={() => setIsModalOpen(true)}></i>
                                    </div>
                                    {formik.touched.categoryId && formik.errors.categoryId && (
                                        <div className='text-danger'>{formik.errors.categoryId}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Rebates</label>
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            id="rebatesYes"
                                            name="rebates"
                                            value="1"
                                            checked={formik.values.rebates === '1'}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <label className="form-check-label" htmlFor="rebatesYes">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            id="rebatesNo"
                                            name="rebates"
                                            value="0"
                                            checked={formik.values.rebates === '0'}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <label className="form-check-label" htmlFor="rebatesNo">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mt-3">
                                <strong>Contact Person:</strong>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="contactPersonFirstName" className="form-label">First Name:</label>
                                <input
                                    type="text"
                                    className='form-control'
                                    id="contactPersonFirstName"
                                    name="contactPersonFirstName"
                                    placeholder="First Name"
                                    autoComplete="off"
                                    value={formik.values.contactPersonFirstName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="contactPersonLastName" className="form-label">Last Name:</label>
                                <input
                                    type="text"
                                    className='form-control'
                                    id="contactPersonLastName"
                                    name="contactPersonLastName"
                                    placeholder="Last Name"
                                    autoComplete="off"
                                    value={formik.values.contactPersonLastName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="contactTitle" className="form-label">Contact Title:</label>
                                <input
                                    type="text"
                                    className='form-control'
                                    id="contactTitle"
                                    name="contactTitle"
                                    placeholder="Contact Title"
                                    autoComplete="off"
                                    value={formik.values.contactTitle}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="phoneNumber" className="form-label">Phone Number:</label>
                                <input
                                    type="text"
                                    className={`form-control `}
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    placeholder="Phone Number"
                                    autoComplete="off"
                                    value={formik.values.phoneNumber}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="faxNumber" className="form-label">Fax Number:</label>
                                <input
                                    type="text"
                                    className='form-control'
                                    id="faxNumber"
                                    name="faxNumber"
                                    autoComplete="off"
                                    placeholder="Fax Number"
                                    value={formik.values.faxNumber}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="email" className="form-label">Email:</label>
                                <input
                                    type="email"
                                    className={`form-control `}
                                    id="email"
                                    name="email"
                                    placeholder="Email"
                                    autoComplete="off"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="address" className="form-label">Address:</label>
                                <input
                                    type="text"
                                    className='form-control'
                                    id="address"
                                    name="address"
                                    placeholder="Address"
                                    autoComplete="off"
                                    value={formik.values.address}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="city" className="form-label">City:</label>
                                <input
                                    type="text"
                                    className='form-control'
                                    id="city"
                                    name="city"
                                    placeholder="City"
                                    autoComplete="off"
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="state" className="form-label">State:</label>
                                <input
                                    type="text"
                                    className='form-control'
                                    id="state"
                                    name="state"
                                    placeholder="State"
                                    autoComplete="off"
                                    value={formik.values.state}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="country" className="form-label">Country:</label>
                                <input
                                    type="text"
                                    className='form-control'
                                    id="country"
                                    name="country"
                                    placeholder="Country"
                                    autoComplete="off"
                                    value={formik.values.country}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="zip" className="form-label">Zip Code:</label>
                                <input
                                    type="text"
                                    className='form-control'
                                    id="zip"
                                    name="zip"
                                    placeholder="Zip Code"
                                    autoComplete="off"
                                    value={formik.values.zip}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="col-md-12 mt-5">
                                <button type="submit" className="btn btn-primary px-4 me-3">{isEditMode ? 'Update' : 'Save'}</button>
                                {/* <button type="button" className="btn btn-danger" onClick={isInPayment ? onCancel : handleCancelClick}>Cancel</button> */}
                                <button type="button" className="btn btn-danger px-4" onClick={isInPayment ? handleCancel : handleCancelClick}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* Category Modal */}
            {isModalOpen &&
                <CategoryModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    setNewCategoryId={setNewCategoryId}
                />
            }
            {/* <CommonCategoryForm 
                formik={formik}
                saveButtonText={isEditMode? 'Update' : 'Save'}
                onCancel={handleCancelClick}
                /> */}
        </div>
    );
};

export default AddVendorComponent;
