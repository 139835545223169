import { API_RESPONSE_STATUS, ResponseModel, handleGetAsync, handlePutAsync, handlePostAsync, handleDeleteAsync } from "../../components/common/commonclass";
import { EndPoint } from "../../components/common/config";
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from "../../components/common/notification/toaster_notify.component";

var responseModel = new ResponseModel();

export class FuelSalesService {
    static async getFuelSales(storeId, searchText = "") {
        responseModel = new ResponseModel();
        const requestURL = EndPoint.FETCH_FUEL_SALES;
        const requestModel = {
            storeId: storeId,
            searchText: searchText
        };

        responseModel = await handleGetAsync(requestURL, true, requestModel);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            return responseModel.data;
        } else {
            console.error('Failed to fetch fuel sales data.');
            return [];
        }
    }

    // get by ID
    static async getFuelSaleById(id) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.FUEL_SALES}/${id}`;

        responseModel = await handleGetAsync(requestURL, true);

        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            return responseModel.data;
        } else {
            throw new Error('Failed to fetch fuel sale details.');
        }
    }

    // Update fuel sales
    static async updateFuelSale(id, data) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.FUEL_SALES}/${id}`;

        responseModel = await handlePutAsync(requestURL, true, data);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify('Fuel sales  data updated successfully.', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if (responseModel.status === API_RESPONSE_STATUS.FAIL) {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else {
            throw new Error('Failed to update fuel sale.');
        }
    }

    // Create new fuel sale
    static async createFuelSale(data) {
        responseModel = new ResponseModel();
        const requestURL = EndPoint.FUEL_SALES;

        responseModel = await handlePostAsync(requestURL, true, data);

        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify('Fuel sales added successfully!', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if (responseModel.status === API_RESPONSE_STATUS.FAIL) {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        }
        else {
            throw new Error('Failed to create new fuel sale.');
        }
    }

    // Delete 
    static async deleteFuelSale(id) {
        const requestURL = `${EndPoint.FUEL_SALES}/${id}`;

        try {
            responseModel = await handleDeleteAsync(requestURL, true);
            if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
                handleNotify('Fuel sale deleted successfully.', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
                return responseModel.data;
            } else {
                throw new Error('Failed to delete fuel sale.');
            }
        } catch (error) {
            console.error('Error deleting fuel sale:', error);
            throw error;
        }
    }

    // delete many
    static async deleteManyFuelSale(ids) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.FUEL_SALES}/deletemany`;

        responseModel = await handleDeleteAsync(requestURL, true, ids);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify(responseModel.message, TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel.data;
        }
        else {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
        }
    }
};