import React, { useEffect } from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { StoreService } from "../../../services/user/store.services";
import { toast } from "react-toastify";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { addStore, logoutUser, selectStore } from "../../../redux/user/userslice";
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from "../../common/notification/toaster_notify.component";
// import Moment from 'react-moment'
// import moment from 'moment'

const AddStoreComponent = ({ screen, children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const storeData = location.state?.storeData || {};
    const isEditMode = !!storeData.storeId;
    const user = useSelector(({ user }) => user, shallowEqual)
    const pageNumber = location.state?.pageNumber || 0;

    const dispatch = useDispatch();

    useEffect(() => {
        if (isEditMode) {
            StoreService.getStoreById(storeData.storeId).then(response => {
                // setData(response);
                formik.setValues({
                    storeName: response.storeName,
                    storeAddress1: response.storeAddress1,
                    storeAddress2: response.storeAddress2,
                    storeCity: response.storeCity,
                    storeState: response.storeState,
                    storeZipcode: response.storeZipcode,
                    storePhone: response.storePhone,
                    storeManager: response.storeManager,
                    userId: user?.id
                })
            })
        }
    }, [])
    const formik = useFormik({
        initialValues: {
            userId: user?.id,
            storeName: '',
            storeAddress1: '',
            storeAddress2: '',
            storeCity: '',
            storeState: '',
            storeZipcode: '',
            storePhone: '',
            storeManager: '',
        },

        validationSchema: Yup.object({
            storeName: Yup.string().required('Store Name is required'),
            storeCity: Yup.string().required('City is required'),
            storeState: Yup.string().required('State is required'),
            storePhone: Yup.string().required('Phone is required'),
            storeManager: Yup.string().required('Store Manager is required'),
        }),

        onSubmit: async (values) => {
            try {
                if (isEditMode) {
                    await StoreService.updateStoreById(storeData.storeId, values).then(response => {
                        if (response && response?.isSucess) {
                            navigate('/accountsetup/storemaster', { state: { pageNumber: pageNumber } })
                        }

                    })
                } else {
                    const response = await StoreService.addStore(values)
                    if (response && response && response.isSucess) {
                        //also need to get the store for upadte the redux
                        const store = await StoreService.getStoreByUserId(user?.id)
                        if (screen === "root") {
                            if (store && store.length > 0) {
                                dispatch(selectStore(store[0]?.storeId))
                                dispatch(addStore(store))
                                navigate('/accountsetup/storemaster', { state: { pageNumber: pageNumber } })
                            }
                        } else {
                            dispatch(selectStore(user?.selectedStoreId))
                            dispatch(addStore(store))
                            navigate('/accountsetup/storemaster', { state: { pageNumber: pageNumber } })
                        }
                    }
                    if (response && !response.isSucess)
                        toast.success(response.errormessage)
                }
            } catch (error) {
                handleNotify('An error occurred while saving the daily sales.', TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            }
        }
    });

    const handleCancel = () => {
        navigate('/accountsetup/storemaster');
    }

    const handleLogout = () => {
        dispatch(logoutUser());
        localStorage.clear();
        navigate('/login')
    };

    const isAddStorePage = location.pathname.includes('/storemaster/addstore');

    return (
        <div className={screen === "root" ? "container" : "page-wrapper"}>
            <div className="page-content">
                {screen !== "root" &&
                    <>
                        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                            <div className="breadcrumb-title pe-3">Store Master</div>
                            <div className="ps-3">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 p-0">
                                        <li className="breadcrumb-item"></li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {isEditMode ? 'Edit Store Master' : 'Add Store Master'}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="page-breadcrumb d-xl-none d-xl-block align-items-center mb-3">
                            <div className="breadcrumb-item pe-3 ">{isEditMode ? 'Edit Store Master' : 'Add Store Master'}</div>
                        </div>
                        <hr />
                    </>}
                <div className="row">
                    <div className="col-xl-12 mx-auto">
                        <div className="card">
                            <div className="card-body p-4">
                                {children && children}
                                <form onSubmit={formik.handleSubmit} className="row g-3 needs-validation">
                                    <div className="col-md-6">
                                        <label htmlFor="storeName" className="form-label">
                                            Store Name: <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="storeName"
                                            id="storeName"
                                            autoComplete="off"
                                            value={formik.values.storeName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={`form-control ${formik.touched.storeName && formik.errors.storeName ? 'is-invalid' : ''}`}
                                            placeholder="Store Name"
                                        />
                                        {formik.touched.storeName && formik.errors.storeName ? (
                                            <div className="text-danger">{formik.errors.storeName}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="storeAddress1" className="form-label">Address 1:</label>
                                        <input
                                            type="text"
                                            name="storeAddress1"
                                            id="storeAddress1"
                                            autoComplete="off"
                                            value={formik.values.storeAddress1}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="form-control"
                                            placeholder="Address 1"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="storeAddress2" className="form-label">Address 2:</label>
                                        <input
                                            type="text"
                                            name="storeAddress2"
                                            id="storeAddress2"
                                            autoComplete="off"
                                            value={formik.values.storeAddress2}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="form-control"
                                            placeholder="Address 2"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="city" className="form-label">
                                            City: <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="storeCity"
                                            id="storeCity"
                                            autoComplete="off"
                                            value={formik.values.storeCity}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={`form-control ${formik.touched.storeCity && formik.errors.storeCity ? 'is-invalid' : ''}`}
                                            placeholder="City"
                                        />
                                        {formik.touched.storeCity && formik.errors.storeCity ? (
                                            <div className="text-danger">{formik.errors.storeCity}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="storeState" className="form-label">
                                            State: <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="storeState"
                                            id="storeState"
                                            autoComplete="off"
                                            value={formik.values.storeState}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={`form-control ${formik.touched.storeState && formik.errors.storeState ? 'is-invalid' : ''}`}
                                            placeholder="State"
                                        />
                                        {formik.touched.storeState && formik.errors.storeState ? (
                                            <div className="text-danger">{formik.errors.storeState}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="storeZipcode" className="form-label">Zip Code:</label>
                                        <input
                                            type="text"
                                            name="storeZipcode"
                                            id="storeZipcode"
                                            autoComplete="off"
                                            value={formik.values.storeZipcode}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="form-control"
                                            placeholder="Zip Code"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="storePhone" className="form-label">
                                            Phone: <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="storePhone"
                                            id="storePhone"
                                            autoComplete="off"
                                            value={formik.values.storePhone}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={`form-control ${formik.touched.storePhone && formik.errors.storePhone ? 'is-invalid' : ''}`}
                                            placeholder="Phone"
                                        />
                                        {formik.touched.storePhone && formik.errors.storePhone ? (
                                            <div className="text-danger">{formik.errors.storePhone}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="storeManager" className="form-label">Store Manager:<span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            name="storeManager"
                                            id="storeManager"
                                            autoComplete="off"
                                            value={formik.values.storeManager}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={`form-control ${formik.touched.storeManager && formik.errors.storeManager ? 'is-invalid' : ''}`}
                                            placeholder="Store Manager"
                                        />
                                        {formik.touched.storeManager && formik.errors.storeManager ? (
                                            <div className="text-danger">{formik.errors.storeManager}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-12 mt-5">
                                        <button type="submit" className="btn btn-primary px-4 me-3">
                                            {isEditMode ? 'Update' : 'Save'}
                                        </button>

                                        {isAddStorePage || isEditMode ? (
                                            <button type="button" className="btn btn-danger px-4 me-3" onClick={handleCancel}>
                                                Cancel
                                            </button>
                                        ) : (
                                            !isEditMode && (
                                                <button type="button" className="btn btn-danger px-4 me-3" onClick={handleLogout}>
                                                    Logout
                                                </button>
                                            )
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default AddStoreComponent;
