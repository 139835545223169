import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "users",
  initialState: {
    id: "",
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    userRole: "",
    address1: "",
    stores: [],
    selectedStoreId: 0
  },
  reducers: {
    addUser: (state, action) => {
      return {
        ...state,
        id: action.payload.id,
        username: action.payload.userName,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        userRole: action.payload.userRole,
        address1: action.payload.address1,
        stores: action.payload.stores,
        selectedStoreId: action.payload?.stores[0]?.storeId
      };

    },
    logoutUser: (state, action) => {
      return {
        ...state,
        id: "",
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        userRole: "",
        address1: "",
        stores: [],
        selectedStoreId: 0
      }
    },
    selectStore: (state, action) => {
      return {
        ...state,
        selectedStoreId: action.payload
      }
    }
    ,
    addStore: (state, action) => {
      return {
        ...state,
        stores: action.payload
      }
    }
  },
});
export const { addUser, logoutUser, selectStore, addStore } = userSlice.actions;
// export default userSlice.reducer;
