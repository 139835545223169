import React, { useState, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component";
import { useNavigate, useLocation } from "react-router-dom";
import DeleteModelPopupComponent from "../common-model-popup/deleteModelPopup.component";
import { DailySalesService } from "../../../services/user/dailysales.services";
import { useSelector } from "react-redux";
import AuthGuard from "../../common/authguard.component";
import moment from "moment";
import TOASTER_TYPE from "../../common/notification/toaster_types.component";
import TOASTER_POSITION from "../../common/notification/toaster_positions.component";
import { handleNotify } from "../../common/notification/toaster_notify.component";

const DailySalesComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [filterText, setFilterText] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const selectedStore = useSelector(state => state?.user?.selectedStoreId);
    const pageNumber = location.state?.pageNumber || 1;
    const [currentPage, setCurrentPage] = useState(pageNumber);
    const [selectedRows, setSelectedRows] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await DailySalesService.getDailySalesService(selectedStore);
                setData(response);
            } catch (error) {
                console.error('Error fetching daily sales data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [selectedStore]);

    const columns = [
        // { width: '10%', name: 'Date', selector: row => moment(row.invoiceDate).format('MM/DD/YYYY'), sortable: true },
        {
            width: '10%', name: 'Date',
            selector: row => {
                // Define the format of the input date
                const inputFormat = 'MM/DD/YYYY';
                const formattedDate = moment(row.invoiceDate, inputFormat).format('MM/DD/YYYY');
                return formattedDate;
            },
            sortable: true
        },

        { width: '10%', name: 'Inside Sales', selector: row => row.insideTotal, sortable: true },
        { width: '9%', name: 'Fuel', selector: row => row.fuel, sortable: true },
        { width: '9%', name: 'Lotto', selector: row => row.lotto, sortable: true },
        { width: '9%', name: 'Scratch Off', selector: row => row.scratchOff, sortable: true },
        { width: '9%', name: 'Sales Tax', selector: row => row.salesTax, sortable: true },
        { width: '10%', name: 'Total', selector: row => row.total, sortable: true },
        { width: '10%', name: 'Bank Deposit', selector: row => row.debit, sortable: true },
        {
            width: '20%', name: 'Actions', cell: row => (
                <div className="d-flex">
                    <button type="button"
                        className="btn btn-primary me-2"
                        onClick={() => handleEditClick(row)} >
                        <span className="d-none d-md-block"> Edit</span>
                        <i className="d-md-none d-lg-none fadeIn animated bx bx-edit-alt"></i>
                    </button>

                    <button type="button"
                        className="btn btn-danger"
                        onClick={() => handleDeleteClick(row)}>
                        <span className="d-none d-md-block">Delete</span>
                        <i className="d-md-none d-lg-none fadeIn animated bx bx-trash-alt"></i>
                    </button>
                </div>
            ),
        }
    ];

    const handleAddNewClick = () => {
        navigate('/salesinfo/dailysales/create', { state: { pageNumber: currentPage } });
    };

    const handleEditClick = (item) => {
        try {
            navigate('/salesinfo/dailysales/edit', { state: { dailySalesId: item?.dailySalesId, pageNumber: currentPage } });
        } catch (error) {
            console.error('Error fetching item details:', error);
        }
    };

    const handleSearch = (event) => {
        setFilterText(event.target.value);
    };

    const handleDeleteClick = (item) => {
        setShowModal(true);
        setItemToDelete(item);
    };

    //delete many
    const handleDeleteSelectedRowClick = () => {
        if (selectedRows.length > 0) {
            setShowModal(true);
            setSelectedRows(selectedRows);
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            const idsToDelete = selectedRows.length > 0
                ? selectedRows.map(row => row.dailySalesId.toString())
                : itemToDelete ? [itemToDelete.dailySalesId.toString()] : [];

            if (idsToDelete.length > 0) {
                await DailySalesService.deleteManyDailySalesService(idsToDelete);

                setData(prevData => prevData.filter(item => !idsToDelete.includes(item.dailySalesId.toString())));
                setSelectedRows([]);
                setItemToDelete(null);
            }
            setShowModal(false);
        } catch (error) {
            handleNotify(error.message || 'Error while deleting DailySales.', TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
        }
    };

    const handleModalClose = () => setShowModal(false);

    const filteredData = data.filter(item =>
        Object.keys(item).some(key =>
            item[key]?.toString().toLowerCase().includes(filterText.toLowerCase())
        )
    );

    const onChangePage = (pageIndex) => {
        setCurrentPage(pageIndex)
    };

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    if (loading) return <div>Loading...</div>;

    return (
        <>
            <AuthGuard page="allowDailySales">
                <div className="page-wrapper">
                    <div className="page-content">
                        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                            <div className="breadcrumb-title pe-3">Sales Info</div>
                            <div className="ps-3">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 p-0">
                                        <li className="breadcrumb-item"></li>
                                        <li className="breadcrumb-item active" aria-current="page">Daily Sales</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="page-breadcrumb d-xl-none d-xl-block align-items-center mb-3">
                            <div className="breadcrumb-item pe-3 ">Sales Info &gt; Daily Sales</div>
                        </div>
                        <hr />
                        <div className="bg-white col-md-12 p-3">
                            <div className="d-md-flex d-grid align-items-center gap-3 mb-5">
                                <div className="d-flex ">
                                    <button
                                        type="button"
                                        className="btn btn-primary px-4 mt-3 me-3"
                                        onClick={handleAddNewClick}
                                    >
                                        New
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-danger px-4 mt-3 ms-auto ms-md-0"
                                        onClick={handleDeleteSelectedRowClick}
                                    >
                                        Delete
                                    </button>
                                </div>
                                <div className="mt-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Search..."
                                        value={filterText}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            {filteredData &&
                                <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    pagination
                                    selectableRows
                                    onChangePage={onChangePage}
                                    onSelectedRowsChange={handleRowSelected}
                                    paginationDefaultPage={currentPage}
                                />
                            }
                        </div>

                    </div>
                </div>
                {showModal &&
                    <DeleteModelPopupComponent
                        show={showModal}
                        onClose={handleModalClose}
                        onConfirm={handleDeleteConfirm}
                        title="Confirm"
                        confirmText="Delete"
                        Message="Are you sure want to delete selected record(s)?"
                    />
                }
            </AuthGuard>
        </>
    );
};

export default DailySalesComponent;
