import React from "react";


const CommonCategoryForm = ({ formik, saveButtonText, onCancel }) => {

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <form onSubmit={formik.handleSubmit} className="row g-3 needs-validation">
                        <div className="col-md-6">
                            <label htmlFor="categoryName" className="form-label">Category Name: <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                name="categoryName"
                                id="categoryName"
                                autoComplete="off"
                                value={formik.values.categoryName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={`form-control ${formik.touched.categoryName && formik.errors.categoryName ? 'is-invalid' : ''}`}
                                placeholder="Category Name"
                            />
                            {formik.touched.categoryName && formik.errors.categoryName ? (
                                <div className='text-danger'>{formik.errors.categoryName}</div>
                            ) : null}
                        </div>
                        <div className="col-md-12 mt-3">
                            {/* <button type="submit" className="btn btn-primary px-4 me-3">
                                        {saveButtonText}
                                             </button> */}
                            <button type="submit" className="btn btn-primary me-3">

                                {saveButtonText ? saveButtonText : 'Update'}
                            </button>
                            <button type="button" className="btn btn-danger" onClick={onCancel}>
                                Cancel
                            </button>
                        </div>

                        {/* <div className="d-md-flex d-grid align-items-center gap-3">
                            <button type="submit" className="btn btn-primary px-4">{saveButtonText}</button>
                            <button type="button" className="btn btn-danger px-4" onClick={onCancel}>Cancel</button>
                        </div> */}
                    </form>
                </div>
            </div>
        </>
    )
}


export default CommonCategoryForm;