import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { logoutUser, selectStore } from "../../../redux/user/userslice";
import DeleteModelPopupComponent from "../../dashboard/common-model-popup/deleteModelPopup.component";

const DashboardHeaderComponent = () => {
  const user = useSelector(({ user }) => user, shallowEqual)
  const storeId = user.selectedStoreId
  const stores = user.stores
  const userdetail = useSelector((state) => state?.user);
  const store = stores?.find(store => store.storeId === storeId)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedStore, setSelectedStore] = useState();
  const [displayPopup, setDisplayPopup] = useState(false);


  const handleClose = () => {
    setDisplayPopup(!displayPopup)
  }

  const logoutClick = () => {
    dispatch(logoutUser());
    localStorage.clear();
    navigate("/login");
  };

  const onChange = (event) => {
    setSelectedStore(event.target.value);
    dispatch(selectStore(parseInt(event.target.value)));
  };

  const onConfirm = () => {
    setDisplayPopup(false)
    dispatch(selectStore(parseInt(selectedStore)));
  };

  const onSelect = (id) => {
    setSelectedStore(id);
    setDisplayPopup(true);
    // dispatch(selectStore(parseInt(id)));
  };

  return (
    <>
      <header>
        <div className="topbar d-flex align-items-center">
        <nav className="navbar d-flex justify-content-between align-items-center gap-3">
            <div className="mobile-toggle-menu">
              <i className="bx bx-menu" />
            </div>

            <div className="col-sm-7 d-none d-md-block"></div>
            <div className=" col-sm-">
              <div className="ms-auto">
                {stores.length > 0 &&
                  <div className="btn-group">
                    <button type="button" className="btn btn-primary">
                      {store?.storeName}
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary split-bg-primary dropdown-toggle dropdown-toggle-split"
                      data-bs-toggle="dropdown"
                    >
                      <span className="visually-hidden">Toggle Dropdown</span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">
                      {stores &&
                        stores.map((store, index) => (
                          <a
                            className={`dropdown-item ${store.storeId === storeId ? "bg-light-blue" : ""} drop-store`}
                            key={index}
                            onClick={() => onSelect(store.storeId)}
                          >
                            {store?.storeName}
                          </a>
                        ))}
                    </div>
                  </div>
                }

              </div>
            </div>
            {/* <div className="col-sm-10"></div> */}
            {/* <div className="user-box dropdown px-3 col-sm-2">  */}
            {/* <a
              className="d-flex align-items-center nav-link dropdown-toggle gap-3 dropdown-toggle-nocaret"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            > */}
            {/* <div
                className="d-flex align-items-center nav-link dropdown-toggle gap-3 dropdown-toggle-nocaret"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              > */}
            {/* <img
                src="../assets/images/avatars/avatar-2.png"
                className="user-img"
                alt="user avatar"
              /> */}
                <div className="user-info d-flex align-items-center">
                <div className="me-0">
                  <p className="user-name mb-0" id="firstname">
                    {userdetail.firstName}
                  </p>
                  <p className="designattion mb-0" id="rolename">{`(${userdetail.userRole})`}</p>
                </div>
                <a className="d-flex align-items-center px-3 logout-btn" style={{cursor:'pointer'}} onClick={logoutClick}>
                  <i className="font-22 bx bx-log-out"></i>
                  <span className="d-none d-md-block ms-2">Logout</span>
                </a>
                </div>
              {/* </div> */}
              {/* <ul className="dropdown-menu dropdown-menu-end"> */}
              {/* <li>
                <a 
                className="dropdown-item d-flex align-items-center" href="#">
                  <i className="bx bx-user fs-5" />
                  <span>Profile</span>
                </a>
              </li> */}
            {/* <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="#"
                >
                  <i className="bx bx-cog fs-5" />
                  <span>Settings</span>
                </a>
              </li> */}
            {/* <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="#"
                >
                  <i className="bx bx-home-circle fs-5" />
                  <span>Dashboard</span>
                </a>
              </li> */}
            {/* <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="#"
                >
                  <i className="bx bx-dollar-circle fs-5" />
                  <span>Earnings</span>
                </a>
              </li> */}
            {/* <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="#"
                >
                  <i className="bx bx-download fs-5" />
                  <span>Downloads</span>
                </a>
              </li> */}
            {/* <li>
                <div className="dropdown-divider mb-0" />
              </li> */}
            {/* <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="#"
                >
                  <i className="bx bx-log-out-circle" />
                  <span>Logout</span>
                </a>
              </li> */}
            {/* </ul> */}
            {/* </div> */}
          </nav>
        </div>
        {displayPopup &&
          <DeleteModelPopupComponent
            show={true}
            onClose={handleClose}
            onConfirm={onConfirm}
            title="Confirm"
            Message="Are you sure want to change the store?"
            confirmText="Yes"
          />
        }
      </header>
    </>
  );
};

export default DashboardHeaderComponent;