import { API_RESPONSE_STATUS, ResponseModel, handleGetAsync, handlePutAsync, handlePostAsync, handleDeleteAsync } from "../../components/common/commonclass";
import { EndPoint } from "../../components/common/config";
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from "../../components/common/notification/toaster_notify.component";

var responseModel = new ResponseModel();

export class CreditCardService {
    static async getCreditCards(storeId = null, searchText = "") {
        responseModel = new ResponseModel();
        let requestURL = EndPoint.FETCH_CREDIT_CARDS;


        const requestModel = { storeId, searchText };
        responseModel = await handleGetAsync(requestURL, true, requestModel);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            return responseModel.data;
        } else {
            throw new Error('Failed to fetch credit card data.');
        }
    }

    // Get by ID
    static async getCreditCardById(id) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.CREDIT_CARDS}/${id}`;

        responseModel = await handleGetAsync(requestURL, true);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            return responseModel.data;
        } else {
            throw new Error('Failed to fetch credit card details.');
        }
    }

    // Update credit card
    static async updateCreditCard(id, data) {
        const requestURL = `${EndPoint.CREDIT_CARDS}/${id}`;
        responseModel = new ResponseModel();

        responseModel = await handlePutAsync(requestURL, true, data);

        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify('Credit card data updated successfully.', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if (responseModel.status === API_RESPONSE_STATUS.FAIL) {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else {
            throw new Error('Failed to update credit card.');
        }
    }

    // Create new credit card
    static async createCreditCard(data) {
        const requestURL = EndPoint.CREDIT_CARDS;
        responseModel = new ResponseModel();

        responseModel = await handlePostAsync(requestURL, true, data);

        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify('Credit card created successfully!', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if (responseModel.status === API_RESPONSE_STATUS.FAIL) {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else {
            throw new Error('Failed to create credit card.');
        }
    }


    // Delete a credit card by ID
    static async deleteCreditCardById(id) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.CREDIT_CARDS}/${id}`; // endpoint

        try {
            responseModel = await handleDeleteAsync(requestURL, true);
            if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
                return responseModel.data;
            } else {
                throw new Error('Failed to delete credit card.');
            }
        } catch (error) {
            console.error('Error deleting credit card:', error);
            throw error;
        }
    }


    // delete many
    static async deleteManyCreditCard(ids) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.CREDIT_CARDS}/deletemany`; // endpoint

        responseModel = await handleDeleteAsync(requestURL, true, ids);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify(responseModel.message, TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel.data;
        } else {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
        }
    };
}