import React from "react";

const DeleteModelPopupComponent = ({ show, onClose, onConfirm, Message, confirmText, title }) => {


    return (
        <>
            <div className={`modal fade ${show ? 'show d-block' : ''}`} id="exampleModal" tabIndex={-1} aria-hidden={!show}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            {/* <h5 className="modal-title">Confirm Deletion</h5> */}
                            <h5 className="modal-title">{title}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose} />
                        </div>
                        <div className="modal-body">
                            {/* <p>Are you sure you want to delete {itemName} ? </p> */}
                            <p>{Message}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={onClose}>Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={onConfirm}>{confirmText}</button>
                        </div>
                    </div>
                </div>
            </div>
            {show && <div className="modal-backdrop fade show"></div>}
        </>
    )
}

export default DeleteModelPopupComponent;