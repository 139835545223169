import { API_RESPONSE_STATUS, ResponseModel, handleDeleteAsync, handleGetAsync, handlePostAsync, handlePutAsync } from "../../components/common/commonclass";
import { EndPoint } from "../../components/common/config";
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from "../../components/common/notification/toaster_notify.component";

var responseModel = new ResponseModel();

export class StoreService {
    static async getstores() {
        responseModel = new ResponseModel();
        const requestURL = EndPoint.STORE;

        responseModel = await handleGetAsync(requestURL, true);
        if (responseModel && (responseModel.status === API_RESPONSE_STATUS.SUCCESS)) {
            return responseModel.data;
        }
    }

    //get store by id api
    static async getStoreById(id) {
        responseModel = new ResponseModel();
        const requestURL = EndPoint.STORE + '/' + id;

        responseModel = await handleGetAsync(requestURL, true);

        if (responseModel && (responseModel.status === API_RESPONSE_STATUS.SUCCESS)) {
            return responseModel.data;
        }
    }

    //get user Store
    static async getStoreByUserId(userId) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.USER}/getstore`;
        const requestModel = { userId };

        try {
            responseModel = await handleGetAsync(requestURL, true, requestModel);
            if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
                return responseModel.data;
            } else {
                throw new Error('Failed to store data.');
            }
        } catch (error) {
            console.error('Error fetching store data:', error);
            throw error;
        }
    }

    //update Api
    static async updateStoreById(id, data) {
        responseModel = new ResponseModel();
        const requestURL = EndPoint.STORE + '/' + id;

        responseModel = await handlePutAsync(requestURL, true, data);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify('Store  data updated successfully.', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if (responseModel.status === API_RESPONSE_STATUS.FAIL) {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else {
            throw new Error('Store to update rebates sale.');
        }
    }

    //add
    static async addStore(data) {
        responseModel = new ResponseModel();
        const requestURL = EndPoint.STORE; // endpoint
        responseModel = await handlePostAsync(requestURL, true, data);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify('Store added successfully!', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if (responseModel.status === API_RESPONSE_STATUS.FAIL) {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        }
        else {
            throw new Error('Failed to create new Store sale.');
        }
    }

    //delete
    static async deleteStoreId(id) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.STORE}/${id}`; // endpoint
        try {

            responseModel = await handleDeleteAsync(requestURL, true);
            if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
                return responseModel;
            } else {
                throw new Error('Failed to delete store data');
            }
        } catch (error) {
            console.error('Error deleting store data:', error);
            throw error;
        }
    }

    //delete many
    static async deleteManyStore(ids) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.STORE}/deletemany`; // endpoint

        responseModel = await handleDeleteAsync(requestURL, true, ids);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify(responseModel.message, TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel.data;
        } else {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
        }
    }
}


