export const LeftMenuKeys={
    Wizard:'dailywizards',
    SalesInfo:'salesinfo',
    DailySales:'dailysales',
    CreditCards:'creditcards',
    FuelSales:'fuelsales',
    Rebates:'rebates',

    AccountSetup:'accountsetup',
    MyProfile:'myprofile',
    StoreMaster:'storemaster',
    VendorMaster:'vendormaster',
    CategoryMaster:'categorymaster',
    ChangePassword:'changepassword',

    Payment:'payment',
    Expenses:'expenses',

    PurchaseInfo:'purchaseinfo',
    FuelDelivery:'fueldelivery',

    Reports:'reports',
    GeneralReports:'generalreports',
    ProfitLossReports:'profitlossreports',
    MoneyCounter:"moneycounter",

}

export const ALL_STEPS = [
    { id: 1, title: 'Daily Sales', targetId: 'test-l-1', permission: 'allowDailySales',route:'salesinfo/dailysales',parent:'salesinfo',key:'dailysales' },
    { id: 2, title: 'Credit Cards', targetId: 'test-l-2', permission: 'allowCreditCards',route:'salesinfo/creditcards',parent:'salesinfo',key:'creditcards' },
    { id: 3, title: 'Fuel Sales', targetId: 'test-l-3', permission: 'allowFuelSales',route:'salesinfo/fuelsales',parent:'salesinfo',key:'fuelsales' },
    { id: 4, title: 'Rebates', targetId: 'test-l-4', permission: 'allowRebates',route:'salesinfo/creditcards',parent:'salesinfo',key:'creditcards' },
    { id: 5, title: 'Fuel Delivery', targetId: 'test-l-5', permission: 'allowFuelDelivery',route:'purchaseinfo/fueldelivery',parent:'purchaseinfo',key:'fueldelivery' },
    { id: 6, title: 'Payments', targetId: 'test-l-6', permission: 'allowPayments',route:'payment/payments',parent:'payment',key:'payment' },
    { id: 7, title: 'Expenses', targetId: 'test-l-7', permission: 'allowExpenses',route:'payment/expenses',parent:'payment',key:'expenses' }
];
