import React from 'react'
import AddStoreComponent from '../../components/dashboard/account-setup/add-store.component'
import AuthGuard from '../../components/common/authguard.component'

const CreateStore = () => {
  return (
    <AuthGuard>
      <AddStoreComponent screen={"root"}>
        <h3 className='py-2'>Create Store</h3>
        </AddStoreComponent>
    </AuthGuard>
  )
}

export default CreateStore
