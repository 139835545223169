import styled from 'styled-components';

//warnings hide 
if (process.env.NODE_ENV === 'development') {
    console.warn = () => {};
    console.error = () => {};
}

const StepperLine = styled.div`
    display: block;

    @media (max-width: 768px) {
        display: none;
    }
`;

const StepperCircle = styled.div`
  width: ${props => (props.isActive ? '50px' : '40px')};
  height: ${props => (props.isActive ? '50px' : '40px')};
  line-height: ${props => (props.isActive ? '50px' : '40px')};
  font-size: ${props => (props.isActive ? '18px' : '16px')};
  background-color: ${props => (props.isActive ? '#378805' : '#ddd')};
  color: ${props => (props.isActive ? '#fff' : '#000')};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    width: ${props => (props.isActive ? '40px' : '30px')};
    height: ${props => (props.isActive ? '40px' : '30px')};
    line-height: ${props => (props.isActive ? '40px' : '30px')};
    font-size: ${props => (props.isActive ? '16px' : '14px')};
  }

  @media (max-width: 480px) {
    width: ${props => (props.isActive ? '35px' : '25px')};
    height: ${props => (props.isActive ? '35px' : '25px')};
    line-height: ${props => (props.isActive ? '35px' : '25px')};
    font-size: ${props => (props.isActive ? '14px' : '12px')};
  }
`;
const WizardSteps = ({ currentStepIndex, steps,onCurrentStepClick,stores }) => {
    return (
        <div className="card-header">
            <div className="d-lg-flex flex-lg-row align-items-lg-center justify-content-lg-between">
                {steps.map((step, index) => (
                    <div key={step.id} className="d-flex align-items-center">
                        <div id={step.id} className={`step ${currentStepIndex === step.id ? 'active' : ''}`} data-target={`#${step.targetId}`} onClick={() => onCurrentStepClick(step.id)}>
                            <div className="step-trigger" role="tab" id={`stepper1trigger${index + 1}`} >
                                {/* <div className="bs-stepper-circle">{index + 1} </div> */}
                                <StepperCircle isActive={currentStepIndex === step.id}>
                                    {index + 1}
                                </StepperCircle>
                                <div>
                                    <h5 className="mb-0 steper-title">{step.title}</h5>
                                </div>
                            </div>
                        </div>
                        {index < steps.length-1 && <StepperLine className="bs-stepper-line"></StepperLine>}
                    </div>
                ))}
            </div>
        </div>
    );
};
export default WizardSteps;