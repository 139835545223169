import React, { useEffect, useState, useCallback } from "react";
import DataTable from 'react-data-table-component';
import { useLocation, useNavigate } from "react-router";
import DeleteModelPopupComponent from "../common-model-popup/deleteModelPopup.component";
import { useSelector } from "react-redux";
import { PaymentService } from "../../../services/payment/payment.services";
import AuthGuard from "../../common/authguard.component";
import moment from "moment";
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from "../../common/notification/toaster_notify.component";

const PaymentsComponent = () => {
    const [filterText, setFilterText] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const selectedStore = useSelector(state => state?.user?.selectedStoreId);
    const [data, setData] = useState([]);
    const pageNumber = location.state?.pageNumber || 1;
    const [currentPage, setCurrentPage] = useState(pageNumber);
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        PaymentService.getAllPayment(selectedStore).then(response => {
            if (response)
                setData(response);
        });
    }, [selectedStore]);

    const columns = [
        //{ width: "10%", name: 'Date', selector: row => row.invoiceDate, sortable: true },
        {
            width: '10%', name: 'Date',
            selector: row => {
                // Define the format of the input date
                const inputFormat = 'MM/DD/YYYY';
                const formattedDate = moment(row.invoiceDate, inputFormat).format('MM/DD/YYYY');
                return formattedDate;
            },
            sortable: true
        },
        { width: "10%", name: 'Invoice No', selector: row => row.invoiceNo, sortable: true },
        { width: "12%", name: 'Vendor Name', selector: row => row.vendorName, sortable: true },
        { width: "10%", name: 'Payment Method', selector: row => row.paymentMethod, sortable: true },
        { width: "10%", name: 'Check No', selector: row => row.checkNo, sortable: true },
        { width: "12%", name: 'amount', selector: row => row.amount, sortable: true },
        { width: "12%", name: 'Notes', selector: row => row.notes, sortable: true },
        {
            width: '20%', name: 'Actions', cell: row => (
                <div className="d-flex">
                    <button type="button"
                        className="btn btn-primary me-2"
                        onClick={() => handleEdit(row)} >
                        <span className="d-none d-md-block"> Edit</span>
                        <i className="d-md-none d-lg-none fadeIn animated bx bx-edit-alt"></i>
                    </button>

                    <button type="button"
                        className="btn btn-danger"
                        onClick={() => handleDeleteClick(row)}>
                        <span className="d-none d-md-block">Delete</span>
                        <i className="d-md-none d-lg-none fadeIn animated bx bx-trash-alt"></i>
                    </button>
                </div>
            ),
        }
    ];

    const filteredData = data.filter(item =>
        Object.keys(item).some(key =>
            item[key]?.toString().toLowerCase().includes(filterText.toLowerCase())
        )
    );

    const handleAddNewStoreClick = () => {
        navigate('/payments/addpayments', { state: { pageNumber: currentPage } });
    };

    const handleSearch = event => { setFilterText(event.target.value) };

    const handleEdit = (row) => {
        navigate('/payments/editpayments', { state: { paymentsId: row?.paymentsId, pageNumber: currentPage } });
    };

    const handleDeleteClick = (item) => {
        setItemToDelete(item);
        setShowModal(true);
    };

    const handleDeleteSelectedRowClick = () => {
        if (selectedRows.length > 0) {
            setShowModal(true);
            setSelectedRows(selectedRows)
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            const idsToDelete = selectedRows.length > 0
                ? selectedRows.map(row => row.paymentsId.toString())
                : itemToDelete ? [itemToDelete.paymentsId.toString()] : [];

            if (idsToDelete.length > 0) {
                await PaymentService.deleteManyPayment(idsToDelete);

                setData(prevData => prevData.filter(item => !idsToDelete.includes(item.paymentsId.toString())));
                setSelectedRows([]);
                setItemToDelete(null);
            }
            setShowModal(false);
        } catch (error) {
            handleNotify(error.message || 'Error while deleting DailySales.', TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
    };
    const onChangePage = (pageIndex) => {
        setCurrentPage(pageIndex)
    };

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    return (
        <AuthGuard page={"allowPayments"}>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Payment</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Payments</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="page-breadcrumb d-xl-none d-xl-block align-items-center mb-3">
                        <div className="breadcrumb-item pe-3 ">Payment &gt; Payments</div>
                    </div>
                    <hr />
                    <div className="bg-white col-md-12 p-3">
                        <div className="d-md-flex d-grid align-items-center gap-3">
                        <div className="d-flex ">
                                <button type="button"
                                    className="btn btn-primary px-4 mt-3 me-3"
                                    onClick={handleAddNewStoreClick}
                                >
                                    New
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger px-4 mt-3 ms-auto ms-md-0"
                                    onClick={handleDeleteSelectedRowClick}
                                >
                                    Delete
                                </button>
                            </div>
                            <div className="mt-3">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Search..."
                                    value={filterText}
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        subHeader
                        selectableRows
                        onChangePage={onChangePage}
                        onSelectedRowsChange={handleRowSelected}
                        paginationDefaultPage={currentPage}
                    />
                </div>
            </div>
            {showModal &&
                <DeleteModelPopupComponent
                    show={showModal}
                    onClose={handleModalClose}
                    onConfirm={handleDeleteConfirm}
                    title="Confirm"
                    confirmText="Delete"
                    Message="Are you sure want to delete selected record(s)?"
                />
            }
        </AuthGuard>
    );
};

export default PaymentsComponent;
