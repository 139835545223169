import React from "react";

export const ScriptsComponent = () => {
  return (
    <>
      {/* <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
       
        <title>Rocker - Bootstrap 5 Admin Dashboard Template</title>
      </head> */}

      {/* <!-- Bootstrap JS --> */}
      <script src="/assets/js/bootstrap.bundle.min.js"></script>
      {/* <!--plugins--> */}
      <script src="assets/js/jquery.min.js"></script>
      <script src="assets/plugins/simplebar/js/simplebar.min.js"></script>
      <script src="assets/plugins/metismenu/js/metisMenu.min.js"></script>
      <script src="assets/plugins/perfect-scrollbar/js/perfect-scrollbar.js"></script>
      <script src="assets/plugins/vectormap/jquery-jvectormap-2.0.2.min.js"></script>
      <script src="assets/plugins/vectormap/jquery-jvectormap-world-mill-en.js"></script>
      <script src="assets/plugins/chartjs/js/chart.js"></script>
      <script src="assets/js/index.js"></script>
      {/* <!--app JS--> */}
      <script src="assets/js/app.js"></script>
      {/* <script>
            new PerfectScrollbar(".app-container")
        </script> */}

      <script src="assets/plugins/bs-stepper/js/bs-stepper.min.js"></script>
      <script src="assets/plugins/bs-stepper/js/main.js"></script>
    </>
  );
};
