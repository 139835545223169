import React from "react";
import { Link, Outlet } from "react-router-dom";
import { addUser } from "../redux/user/userslice";
import { useDispatch } from "react-redux";

const DashboardMainComponent = () => {
  const dispatch = useDispatch();

  const adduserClick=()=>{
    dispatch(addUser('test'));
  }
  return (
        <div className="page-wrapper">
          <div className="page-content">
            <div className="row">
              <div>
                {/* <button onClick={adduserClick} className="btn btn-primary">click</button> */}
              </div>
            </div>
          </div>
        </div>
  );
};
export default DashboardMainComponent;
