import React, { useState, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component";
import { useNavigate, useLocation } from "react-router-dom";
import DeleteModelPopupComponent from "../common-model-popup/deleteModelPopup.component";
import { RebatesAndCommissionService } from "../../../services/user/rebates.services";
import { useSelector } from "react-redux";
import moment from "moment";
import AuthGuard from "../../common/authguard.component";
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from "../../common/notification/toaster_notify.component";


const RebatesAndCommissionComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [filterText, setFilterText] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const selectedStore = useSelector(state => state?.user?.selectedStoreId);
    const pageNumber = location.state?.pageNumber || 1;
    const [currentPage, setCurrentPage] = useState(pageNumber);
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        const fetchRebatesData = async () => {
            try {
                setLoading(true)
                const response = await RebatesAndCommissionService.getRebatesAndCommissions(selectedStore);
                setData(response);
            }
            catch (error) {
                console.error('Error fetching Fuel Sales data', error);
            }
            finally {
                setLoading(false)
            }
        }
        fetchRebatesData();
    }, [selectedStore]);

    const columns = [
        //{ width: '20%', name: 'Date', selector: row => moment(row.date).format('MM/DD/YYYY'), sortable: true },
        {
            width: '15%', name: 'Date',
            selector: row => {
                // Define the format of the input date
                const inputFormat = 'MM/DD/YYYY';
                const formattedDate = moment(row.date, inputFormat).format('MM/DD/YYYY');
                return formattedDate;
            },
            sortable: true
        },
        { width: '20%', name: 'Vendor Name', selector: row => row.vendorName, sortable: true },
        { width: '15%', name: 'Amount', selector: row => row.amount, sortable: true },
        { width: '20%', name: 'Description', selector: row => row.description, sortable: true },
        {
            width: '20%', name: 'Actions', cell: row => (
                <div className="d-flex">
                    <button type="button"
                        className="btn btn-primary me-2"
                        onClick={() => handleEditClick(row)} >
                        <span className="d-none d-md-block"> Edit</span>
                        <i className="d-md-none d-lg-none fadeIn animated bx bx-edit-alt"></i>
                    </button>

                    <button type="button"
                        className="btn btn-danger"
                        onClick={() => handleDeleteClick(row)} >
                        <span className="d-none d-md-block"> Delete</span>
                        <i className="d-md-none d-lg-none fadeIn animated bx bx-trash-alt"></i>
                    </button>
                </div>
            ),
        }
    ];

    const handleAddNewClick = () => {
        navigate('/salesinfo/rebates/create', { state: { pageNumber: currentPage } });
    };

    const handleEditClick = (row) => {
        navigate('/salesinfo/rebates/edit', { state: { rebatesId: row?.rebatesId, pageNumber: currentPage } });
    };

    const handleSearch = (event) => {
        setFilterText(event.target.value);
    };

    const handleDeleteClick = (item) => {
        setItemToDelete(item);
        setShowModal(true);
    };

    const handleDeleteSelectedRowClick = () => {
        if (selectedRows.length > 0) {
            setShowModal(true);
            setSelectedRows(selectedRows)
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            const idsToDelete = selectedRows.length > 0
                ? selectedRows.map(row => row.rebatesId.toString())
                : itemToDelete ? [itemToDelete.rebatesId.toString()] : [];

            if (idsToDelete.length > 0) {
                await RebatesAndCommissionService.deleteManyRebatesOrCommition(idsToDelete);

                setData(prevData => prevData.filter(item => !idsToDelete.includes(item.rebatesId.toString())));  
                setSelectedRows([]);
                setItemToDelete(null);
            }
                setShowModal(false);
        } catch (error) {
            handleNotify(error.message || 'Error while deleting DailySales.', TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
        }
    };


    const handleModalClose = () => {
        setShowModal(false);
    };

    const filteredData = (data || []).filter(item =>
        Object.keys(item).some(key =>
            item[key]?.toString().toLowerCase().includes(filterText.toLowerCase())
        )
    );

    const onChangePage = (pageIndex) => {
        setCurrentPage(pageIndex)
    };

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    return (
        <>
            <AuthGuard page="allowRebates">
                <div className="page-wrapper">
                    <div className="page-content">
                        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                            <div className="breadcrumb-title pe-3">Sales Info</div>
                            <div className="ps-3">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 p-0">
                                        <li className="breadcrumb-item"></li>
                                        <li className="breadcrumb-item active" aria-current="page">Rebates & Commission</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>

                        <div className="page-breadcrumb d-xl-none d-xl-block align-items-center mb-3">
                            <div className="breadcrumb-item pe-3 ">Sales Info &gt; Rebates & Commission</div>
                        </div>
                        <hr />
                        <div className="bg-white col-md-12 p-3">
                        <div className="d-md-flex d-grid align-items-center gap-3 mb-5"> 
                            <div className="d-flex ">
                                <button
                                    type="button"
                                    className="btn btn-primary px-4 mt-3 me-3"
                                    onClick={handleAddNewClick}
                                >
                                    New
                                </button>
                                <button
                                    type="button"
                                   className="btn btn-danger px-4 mt-3 ms-auto ms-md-0"
                                    onClick={handleDeleteSelectedRowClick}
                                >
                                    Delete
                                </button>
                                </div>
                                <div className="mt-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Search..."
                                        value={filterText}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            {loading ? (
                                <div>Loading...</div>
                            ) : (
                                <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    pagination
                                    selectableRows
                                    onChangePage={onChangePage}
                                    onSelectedRowsChange={handleRowSelected}
                                    paginationDefaultPage={currentPage}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {showModal &&
                    <DeleteModelPopupComponent
                        show={showModal}
                        onClose={handleModalClose}
                        onConfirm={handleDeleteConfirm}
                        title="Confirm"
                        confirmText="Delete"
                        Message="Are you sure want to delete selected record(s)?"
                    />
                }
            </AuthGuard>
        </>
    );
};

export default RebatesAndCommissionComponent;
