import { API_RESPONSE_STATUS, ResponseModel, handleGetAsync, handlePostAsync, handlePutAsync, handleDeleteAsync } from "../../components/common/commonclass";
import { EndPoint } from "../../components/common/config";
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from "../../components/common/notification/toaster_notify.component";


var responseModel = new ResponseModel();
export class PaymentService {
    static async getAllPayment(storeId, searchText = "") {
        responseModel = new ResponseModel();
        const requestURL = EndPoint.FETCH_PAYMENTS;
        const requestModel = {
            storeId: storeId,
            searchText: searchText
        };

        responseModel = await handleGetAsync(requestURL, true, requestModel);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            return responseModel.data;
        } else {
            console.error('Failed to fetch payment data.');
            return [];
        }
    }

    //create new payment
    static async addNewPayment(data) {
        responseModel = new ResponseModel();
        const requestURL = EndPoint.PAYMENT; // endpoint

        responseModel = await handlePostAsync(requestURL, true, data);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify('payment data added successfully!', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if (responseModel && responseModel.status === API_RESPONSE_STATUS.FAIL) {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else {
            throw new Error('Failed to create new payment data.');
        }
    }
        
    
    // Get by ID payment
    static async getPaymentById(id) {
        ;
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.PAYMENT}/${id}`; // endpoint

        responseModel = await handleGetAsync(requestURL, true);

        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            return responseModel.data;
        } else {
            throw new Error('fail to fetch Payment');
        }
    }

    // Update payment
    static async updatePaymentById(id, data) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.PAYMENT}/${id}`; // endpoint

        responseModel = await handlePutAsync(requestURL, true, data);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify('Payments data updated successfully.', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if (responseModel.status === API_RESPONSE_STATUS.FAIL) {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else {
            throw new Error('Failed to update Payments data.');
        }
    }


    // Delete payment
    static async deletePayment(id) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.PAYMENT}/${id}`; // endpoint

        responseModel = await handleDeleteAsync(requestURL, true);

        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            return responseModel.data;
        } else {
            console.error('Failed to delete Payments.');
        }
    }

    // Delete many payment
    static async deleteManyPayment(ids) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.PAYMENT}/deletemany`; // endpoint

        responseModel = await handleDeleteAsync(requestURL, true, ids);

        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify(responseModel.message, TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel.data;
        } else {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
        }
    }

    //payments expenses services with bind_expenses
    static async getAllExpense(storeId, searchText = "") {
        responseModel = new ResponseModel();
        const requestURL = EndPoint.FETCH_Expense;
        const requestModel = {
            storeId: storeId,
            searchText: searchText
        };

        responseModel = await handleGetAsync(requestURL, true, requestModel);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            return responseModel.data;
        } else {
            console.error('Failed to fetch payment data.');
            throw new Error('error fetching expenses');
        }
    }


    // //get all expenses
    // static async getAllExpenses(storeId, searchText = "") {
    //     responseModel = new ResponseModel();
    //     const requestURL = EndPoint.EXPENSES;
    //     const requestModel = {
    //         storeId: storeId,
    //         searchText: searchText
    //     };

    //     responseModel = await handleGetAsync(requestURL, false, requestModel);
    //     if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
    //         return responseModel.data;
    //     } else {
    //         console.error('Failed to fetch payment data.');
    //         throw new Error('error fetching expenses');
    //     }
    // }

    //create new expenses
    static async addNewExpense(data) {
        responseModel = new ResponseModel();
        const requestURL = EndPoint.EXPENSES; // endpoint

        responseModel = await handlePostAsync(requestURL, true, data);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify('Expenses data added successfully!', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if (responseModel && responseModel.status === API_RESPONSE_STATUS.FAIL) {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else {
            throw new Error('Failed to create new Expenses data.');
        }
    }
    
    // Get by ID
    static async getExpenseById(id) {
        ;
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.EXPENSES}/${id}`; // endpoint

        responseModel = await handleGetAsync(requestURL, true);

        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            return responseModel.data;
        } else {
            throw new Error('fail to fetch Expenses by id');
        }
    }

    // Update 
    static async updateExpenseById(id, data) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.EXPENSES}/${id}`; // endpoint

        responseModel = await handlePutAsync(requestURL, true, data);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify('Expense data updated successfully.', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if (responseModel.status === API_RESPONSE_STATUS.FAIL) {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else {
            throw new Error('Failed to update Expense data.');
        }
    }


    // Delete expenses
    static async deleteExpense(id) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.EXPENSES}/${id}`; // endpoint

        responseModel = await handleDeleteAsync(requestURL, true);

        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            return responseModel.data;
        } else {
            console.error('Failed to delete Expense.');
        }
    }

    // Delete many Expenses
    static async deleteManyExpense(ids) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.EXPENSES}/deletemany`; // endpoint

        responseModel = await handleDeleteAsync(requestURL, true, ids);

        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify(responseModel.message, TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel.data;
        } else {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
        }
    }

}
