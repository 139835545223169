import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import VendorModelComponent from '../reports/vendormodel.component';
import AuthGuard from '../../common/authguard.component';
import { useSelector } from 'react-redux';
import { VendorService } from '../../../services/user/vendor.service';
import { PaymentService } from '../../../services/payment/payment.services';
// import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from '../sales-info/notifications/toaster_notify.component';
import { handleNotify, TOASTER_TYPE, TOASTER_POSITION } from "../../common/notification/toaster_notify.component";
import moment from 'moment';
import AddVendorComponent from '../account-setup/add-vendor.component';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const AddPaymentComponent = ({
    isInWizard = false,
    onNext,
    onBack,
    showBreadcrumbs = true,
    showButtons = true,
    showWrapper = true,
}) => {

    const location = useLocation();
    const navigate = useNavigate();
    const pageNumber = location.state?.pageNumber || 0;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const selectedStore = useSelector(state => state?.user?.selectedStoreId);
    const [vendors, setVendors] = useState([]);
    const [newVendorId, setNewVendorId] = useState(null);
    const paymentsId = location.state?.paymentsId || 0;
    const isEditMode = !!paymentsId;
    const [vendorsRerender, setvendorsRerender] = useState(false);

    const formik = useFormik({
        initialValues: {
            paymentsId: paymentsId ?? 0,
            invoiceDate: moment(new Date()).format('MM/DD/YYYY'),
            invoiceNo: "",
            vendorId: '',
            paymentMethod: "",
            checkNo: '',
            amount: 0,
            notes: "",
            storeId: selectedStore,
        },
        validationSchema: Yup.object({
            invoiceDate: Yup.string().required('invoiceDate is required'),
            invoiceNo: Yup.string().required('Invoice Number is required'),
            vendorId: Yup.string().required('Vendor Name is required'),
            paymentMethod: Yup.string().required('Payment Method is required'),
        }),
        onSubmit: async (values) => {
            try {
                values.amount=(parseFloat(values.amount)) || 0;
                
                if (isEditMode) {
                    await PaymentService.updatePaymentById(paymentsId, values)
                        .then(response => {
                            if (response?.isSucess) {
                                navigate('/payment/payments', { state: { pageNumber: pageNumber } });
                            }
                        })
                }
                else {
                    await PaymentService.addNewPayment(values).then(response => {
                        if (response?.isSucess) {
                            if (isInWizard) {
                                onNext();
                            }
                            else {
                                navigate('/payment/payments', { state: { pageNumber: pageNumber } });
                            }
                        }
                    });
                }
            } catch (error) {
                handleNotify('An error occurred while saving the Payment.', TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            }
        }
    });
    const handleClosePopup = () => {
        setIsModalOpen(false)
        setvendorsRerender(!vendorsRerender)
        // alert("close")
    };
    const handleCancelClick = () => {
        navigate("/payment/payments", { state: { pageNumber: pageNumber } });
    };

    const fetchVendors = async () => {        try {
            const response = await VendorService.getvendors(selectedStore);
            const sortedVendors = response.sort((a, b) => b.vendorId - a.vendorId);
            formik.values.vendorId=sortedVendors?.[0]?.vendorId;
            formik.setFieldTouched('vendorId', '');
            formik.setFieldError('vendorId', '');

            setVendors(sortedVendors);
        }
        catch (error) {
            console.log('Error fetching Vendors', error);
        }
    };

    useEffect(() => {
        fetchVendors();
    }, [newVendorId, vendorsRerender]);

    useEffect(() => {
        if (isEditMode) {
            const fetchPaymentDetails = async () => {
                try {
                    const data = await PaymentService.getPaymentById(paymentsId);
                    if (data) {
                        formik.setValues({
                            paymentsId: data?.paymentsId,
                            invoiceDate: data?.invoiceDate ? moment(data?.invoiceDate).format('YYYY-MM-DD') : '',
                            invoiceNo: data?.invoiceNo,
                            vendorId: data?.vendorId,
                            paymentMethod: data?.paymentMethod,
                            checkNo: data?.checkNo,
                            amount: data?.amount,
                            notes: data?.notes,
                            storeId: data?.selectedStore,
                        })
                    }
                }
                catch (error) {
                    console.log('Error fetching payment detail', error)
                }
            }
            fetchPaymentDetails();
        }
    }, []);

    return (
        <AuthGuard page={"allowPayments"}>
            <div className={showWrapper ? 'page-wrapper' : ''}>
                <div className="page-content">

                    {showBreadcrumbs && !isInWizard && (
                        <>
                            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                <div className="breadcrumb-title pe-3">Payment</div>
                                <div className="ps-3">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 p-0">
                                            <li className="breadcrumb-item"></li>
                                            <li className="breadcrumb-item active" aria-current="page">{isEditMode ? 'Edit' : 'Add'} Payment</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="page-breadcrumb d-xl-none d-xl-block align-items-center mb-3">
                                <div className="breadcrumb-item pe-3 ">{isEditMode ? 'Edit Payment' : 'Add Payment'}</div>
                            </div>
                            <hr />
                        </>
                    )}
                    <div className="card">
                        <div className="card-body p-4">
                            <form onSubmit={formik.handleSubmit} className="row g-3 needs-validation">
                                {/* invoiceDate Field */}
                                <div className="col-md-12">
                                    <label htmlFor="invoiceDate" className="form-label">Date<span className="text-danger">*</span>:</label>
                                    <DatePicker
                                        selected={formik.values.invoiceDate}
                                        onChange={val => {
                                            formik.setFieldValue("invoiceDate", val);
                                        }}
                                        onBlur={formik.handleBlur}
                                        name="invoiceDate"
                                        dateFormat="MM/dd/YYYY" // Display format in the input field
                                        className={`form-control ${formik.touched.invoiceDate && formik.errors.invoiceDate ? 'is-invalid' : ''}`}
                                        id="invoiceDate"
                                    />
                                    {formik.touched.invoiceDate && formik.errors.invoiceDate ? (
                                        <div className='text-danger'>{formik.errors.invoiceDate}</div>
                                    ) : null}
                                </div>
                                {/* Invoice Number Field */}
                                <div className="col-md-6">
                                    <label htmlFor="invoiceNo" className="form-label">Invoice Number<span className="text-danger">*</span>:</label>
                                    <input
                                        type="text"
                                        className={`form-control ${formik.touched.invoiceNo && formik.errors.invoiceNo ? 'is-invalid' : ''}`}
                                        id="invoiceNo"
                                        name="invoiceNo"
                                        autoComplete="off"
                                        value={formik.values.invoiceNo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.invoiceNo && formik.errors.invoiceNo ? (
                                        <div className='text-danger'>{formik.errors.invoiceNo}</div>
                                    ) : null}
                                </div>
                                {/* Vendor Name Field */}
                                <div className="col-md-6">
                                    <label htmlFor="vendorId" className="form-label">Vendor Name<span className="text-danger">*</span>:</label>
                                    <div className="d-flex align-items-center">
                                        <select
                                            className={`form-select ${formik.errors.vendorId ? 'is-invalid' : ''}`}
                                            id="vendorId"
                                            name="vendorId"
                                            value={formik.values.vendorId}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        >
                                            {/* commenting this line ,add Vandor comes to the top position */}
                                            {/* <option value="">Select Vendor</option> */}
                                            {vendors && vendors.map(vendor => (
                                                <option key={vendor.vendorId} value={vendor.vendorId}>{vendor.vendorName}</option>
                                            ))}
                                        </select>
                                        <div className="circle-plus ms-2">
                                            <i className="lni lni-circle-plus" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add New Vendor" onClick={() => setIsModalOpen(true)}></i>
                                        </div>
                                    </div>
                                    {formik.touched.vendorId && formik.errors.vendorId ? (
                                        <div className='text-danger'>{formik.errors.vendorId}</div>
                                    ) : null}
                                </div>
                                {/* Payment Method Field */}
                                <div className="col-md-6">
                                    <label htmlFor="paymentMethod" className="form-label">Payment Method<span className="text-danger">*</span>:</label>
                                    <select
                                        className={`form-select ${formik.touched.paymentMethod && formik.errors.paymentMethod ? 'is-invalid' : ''}`}
                                        id="paymentMethod"
                                        name="paymentMethod"
                                        value={formik.values.paymentMethod}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    >
                                        <option value="">Select Payment Method</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Check">Check</option>
                                        <option value="EFT">EFT</option>
                                        <option value="MoneyOrder">Money Order</option>
                                    </select>
                                    {formik.touched.paymentMethod && formik.errors.paymentMethod ? (
                                        <div className='text-danger'>{formik.errors.paymentMethod}</div>
                                    ) : null}
                                </div>

                                {/* CheckNo */}
                                {formik.values.paymentMethod === 'Check' && (
                                    <div className="col-md-6">
                                        <label htmlFor="checkNo" className="form-label">Check No:</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="checkNo"
                                            name="checkNo"
                                            value={formik.values.checkNo}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                )}
                                {/* Extra div */}
                                 <div className="col-md-12"></div>
                                {/* amount Field */} 
                                <div className="col-md-6">
                                    <label htmlFor="amount" className="form-label">Amount:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="amount"
                                        name="amount"
                                        value={formik.values.amount}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                     </div> 
                                {/* Notes Field */}
                                <div className="col-md-12">
                                    <label htmlFor="notes" className="form-label">Notes:</label>
                                    <textarea
                                        className="form-control"
                                        id="notes"
                                        name="notes"
                                        rows="3"
                                        value={formik.values.notes}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    ></textarea>
                                </div>
                                {/* Buttons */}
                                <div className="col-md-12 mt-5">
                                    <div className="d-md-flex d-grid align-items-center gap-3">
                                        {showButtons && (
                                            <>
                                                {isInWizard ? (
                                                    <>
                                                        <button type="submit" className="btn btn-primary me-2">Save & Next</button>
                                                        <button type="button" className="btn btn-outline-secondary px-4" onClick={onBack}>
                                                            Back
                                                        </button>
                                                        <button type="button" className="btn btn-outline-secondary px-4" onClick={onNext}>
                                                            Skip
                                                        </button>
                                                        <button type="button" className="btn btn-outline-secondary px-4" onClick={() => navigate('/dashboard')}>
                                                            Finish
                                                        </button>
                                                    </>
                                                ) : (
                                                    <div className="col-md-12 mt-2">
                                                        <button type="submit" className="btn btn-primary px-4 me-3">
                                                            {isEditMode ? 'Update' : 'Save'}
                                                        </button>
                                                        <button type="button" className="btn btn-danger px-4" onClick={handleCancelClick}>
                                                            Cancel
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal for Adding New Vendor */}
            <div className={`modal fade ${isModalOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: isModalOpen ? 'block' : 'none' }}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add New Vendor</h5>
                            <button type="button" className="btn-close" onClick={() => setIsModalOpen(false)}></button>
                        </div>
                        <div className="modal-body">
                            <AddVendorComponent
                                isInPayment={true}
                                showBreadcrumbs={false}
                                showWrapper={false}
                                isPopUp={true}
                                handleClosePopup={handleClosePopup}
                                setNewVendorId={setNewVendorId}
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => setIsModalOpen(false)}>Close</button>
                        </div>
                    </div>
                </div>=
            </div>
        </AuthGuard>
    );
};

export default AddPaymentComponent;
