import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CommonCategoryForm from './common-categoryform.component';
import { CategoryService } from '../../../services/user/category.services';
import { useSelector } from 'react-redux';
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from '../../common/notification/toaster_notify.component';


const CategoryModal = ({ isOpen, onClose }) => {
    const selectedStore = useSelector(state => state?.user?.selectedStoreId)

    const formik = useFormik({
        initialValues: {
            categoryName: '',
            storeId: selectedStore
        },
        validationSchema: Yup.object({
            categoryName: Yup.string().required('Category Name is required')
        }),
        onSubmit: async (values) => {
            await CategoryService.addNewCategory(values)
            handleNotify('Category added successfully!', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            onClose()
        }
    });

    // if (!isOpen) return null;
    const handleCancelClick = () => {
        if (isOpen) {
            onClose();
        }
    }

    return (<>
        <div className="modal fade show d-block" tabIndex="-1" style={{ display: 'block' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add Category</h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className='modal-body'>
                        <CommonCategoryForm
                            formik={formik}
                            saveButtonText={isOpen ? 'Save' : ''}
                            onCancel={handleCancelClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
    );
};

export default CategoryModal;
