import axios, { HttpStatusCode } from "axios";
import { getToken, setLocalStorageItem } from "../../utility";
import { EndPoint } from "./config";

export class ResponseModel {
  data = [];
  status = 0;
  errormessage = "";
  isSucess = false;
  message = "";
}

export const API_RESPONSE_STATUS = {
  SUCCESS: 200,
  FAIL: 400,
  ERROR: 500,
  UNAUTHORIZED: 401,
  CHANGE: 5,
  ALREADYLOGIN: 6,
};

export const handleGetAsync = async (requestUrl, isAuthorizeRequired = false, requestModel) => {
  const { accessToken, refreshToken } = getToken()
  let responseModel = new ResponseModel();
  //new axios code
  let axiosInstance;
  if (isAuthorizeRequired) {
    axiosInstance = axios.create({
      baseURL: requestUrl,
      params: requestModel,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } else {
    axiosInstance = axios.create({
      baseURL: requestUrl,
      params: requestModel,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  //end axios code
  axiosInstance.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry && isAuthorizeRequired) {
        originalRequest._retry = true;
        try {
          const res = await axios.post(`${EndPoint.AUTH}/refresh`, { refreshToken: refreshToken });
          if (res) {
            const newAccessToken = res?.data.result?.data?.accessToken
            localStorage.setItem('accessToken', newAccessToken);
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
            return axiosInstance(originalRequest);
          }
        } catch (refreshError) {
          if (refreshError.response.status === HttpStatusCode.Unauthorized) {
            setLocalStorageItem("isRefreshExp", true)
          }
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    }
  );
  try {
    const res = await axiosInstance.get()
    responseModel = res.data.result;
    return responseModel;
  } catch (error) {
    console.log(error);
  }

};

//handlePostAsync
export const handlePostAsync = async (requestUrl, isAuthorizeRequired, requestModel) => {
  const { accessToken, refreshToken } = getToken()
  let responseModel = new ResponseModel();
  //new axios code
  let axiosInstance;
  if (isAuthorizeRequired) {
    axiosInstance = axios.create({
      // baseURL: requestUrl,
      // data: requestModel,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } else {
    axiosInstance = axios.create({
      // baseURL: requestUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  //end axios code
  axiosInstance.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry && isAuthorizeRequired) {
        originalRequest._retry = true;
        try {
          const res = await axios.post(`${EndPoint.AUTH}/refresh`, { refreshToken: refreshToken });
          const newAccessToken = res?.data.result?.data?.accessToken
          localStorage.setItem('accessToken', newAccessToken);
          axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          // originalRequest._retry = false;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          if (refreshError.response.status === HttpStatusCode.Unauthorized) {
            setLocalStorageItem("isRefreshExp", true)
          }
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    }
  );
  try {
    const res = await axiosInstance.post(requestUrl, requestModel)
    responseModel = res?.data?.result;
    return responseModel;
  } catch (error) {
    console.log(error);
    // if(error?.response?.status===401)
    //   return error.response.data.result;
  }

};

//handlePutAsync
export const handlePutAsync = async (requestUrl, isAuthorizeRequired, requestModel) => {
  const { accessToken, refreshToken } = getToken()
  let responseModel = new ResponseModel();
  //new axios code
  let axiosInstance;
  if (isAuthorizeRequired) {
    axiosInstance = axios.create({
      // baseURL: requestUrl,
      // data: requestModel,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } else {
    axiosInstance = axios.create({
      // baseURL: requestUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  //end axios code
  axiosInstance.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry && isAuthorizeRequired) {
        originalRequest._retry = true;
        try {
          const res = await axios.post(`${EndPoint.AUTH}/refresh`, { refreshToken: refreshToken });
          const newAccessToken = res?.data.result?.data?.accessToken
          localStorage.setItem('accessToken', newAccessToken);
          axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          // originalRequest._retry = false;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          if (refreshError.response.status === HttpStatusCode.Unauthorized) {
            setLocalStorageItem("isRefreshExp", true)
          }
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    }
  );
  try {
    const res = await axiosInstance.put(requestUrl, requestModel)
    responseModel = res.data.result;
    return responseModel;
  } catch (error) {
    console.log(error);
  }

};

//handledeleteAsync
export const handleDeleteAsync = async (requestUrl, isAuthorizeRequired, requestModel) => {


  const { accessToken, refreshToken } = getToken()
  let responseModel = new ResponseModel();
  //new axios code
  let axiosInstance;
  if (isAuthorizeRequired) {
    if(requestModel){
      axiosInstance = axios.create({
        // baseURL: requestUrl,
        // data: requestModel,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }else{
    axiosInstance = axios.create({
      // baseURL: requestUrl,
      data: requestModel,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
  } else {
    axiosInstance = axios.create({
      // baseURL: requestUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  //end axios code
  axiosInstance.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry && isAuthorizeRequired) {
        originalRequest._retry = true;
        try {
          const res = await axios.post(`${EndPoint.AUTH}/refresh`, { refreshToken: refreshToken });
          const newAccessToken = res?.data.result?.data?.accessToken
          localStorage.setItem('accessToken', newAccessToken);
          axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          // originalRequest._retry = false;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    }
  );
  try {
    let res;
    if (requestModel) {
      //res = await axiosInstance.delete(`${requestUrl}`);
      res = await axiosInstance.delete(requestUrl,{data:requestModel});
    } else {
      res = await axiosInstance.delete(requestUrl);
    }
    // const res = await axiosInstance.put(requestUrl,requestModel)
    responseModel = res.data.result;
    return responseModel;
  } catch (error) {
    console.log(error);
  }



};
