import moment from "moment";
export const getColumns = (reportName, reportType) => {
  let columns = [
    {
      Header: "Date",
      accessor: "invoiceDate",
    },
    {
      Header: "Inside Sales",
      accessor: "insideTotal",
    },
    {
      Header: "Fuel",
      accessor: "fuel",
    },
    {
      Header: "Cigarette",
      accessor: "cigarette",
    },
    {
      Header: "Beer",
      accessor: "beer",
    },
    {
      Header: "Tax Grocery",
      accessor: "taxGrocery",
    },
    {
      Header: "No Tax",
      accessor: "noTax",
    },
    {
      Header: "Lotto",
      accessor: "lotto",
    },
    {
      Header: "Scratch Off",
      accessor: "scratchOff",
    },
    {
      Header: "Money Order",
      accessor: "moneyOrder",
    },
    {
      Header: "Deli",
      accessor: "deli",
    },
    {
      Header: "Phone Cards",
      accessor: "phoneCards",
    },
    {
      Header: "Plu Not Found",
      accessor: "pluNotFound",
    },
    {
      Header: "Sales Tax",
      accessor: "salesTax",
    },
    {
      Header: "Total",
      accessor: "total",
    },
    {
      Header: "Credit",
      accessor: "credit",
    },
    {
      Header: "Debit",
      accessor: "debit",
    },
    {
      Header: "EBT",
      accessor: "ebt",
    },
    {
      Header: "Lotto Pay",
      accessor: "lottoPay",
    },
    {
      Header: "Pay Out",
      accessor: "payOut",
    },
    {
      Header: "Coupons",
      accessor: "coupons",
    },
    {
      Header: "House Accounts",
      accessor: "houseAccounts",
    },
    {
      Header: "Refunds",
      accessor: "refunds",
    },
    {
      Header: "Drive Off",
      accessor: "driveOff",
    },
    {
      Header: "Cash Deposit",
      accessor: "cashDeposit",
    },
    {
      Header: "Bank Deposit",
      accessor: "actualDeposit",
    },
    {
      Header: "Short Over",
      accessor: "shortOver",
    },
  ]

  if (reportName === 'Expenses' || reportName === 'Payments') {
    columns = [
      // {
      //   Header: "Sr No",
      //   accessor: "srNo",
      // },
      {
        Header: "Date",
        accessor: "invoiceDate",
        Cell: ({ value }) => {
          if (reportType === "daily") {
            return moment(value).format('MM/DD/YYYY')
          } else {
            return value;
          }
        }
        ,
      },
      {
        Header: "Invoice Amount",
        accessor: "amount",
      },
      {
        Header: "Vendor Name",
        accessor: "vendorName",
      },
      {
        Header: "Payment Method",
        accessor: "paymentMethod",
      },
      {
        Header: "Check No",
        accessor: "checkNo",
      },
    ];

  } else if (reportName === 'Credit Cards') {
    columns = [
      // {
      //   Header: "Sr No",
      //   accessor: "srNo",
      // },
      {
        Header: "Date",
        accessor: "invoiceDate",
      },
      {
        Header: "Invoice Amount",
        accessor: "amount",
      },
      {
        Header: "Fee",
        accessor: "fee",
      },
    ];

  }
  else if (reportName === 'Rebates/Commission') {
    columns = [
      // {
      //   Header: "Sr No",
      //   accessor: "srNo",
      // },
      {
        Header: "Date",
        accessor: "rdate",
      },
      {
        Header: "Vendor Name",
        accessor: "vendorName",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
    ];

  } else if (reportName === 'Fuel Delivery') {
    columns = [
      // {
      //   Header: "Sr No",
      //   accessor: "srNo",
      // },
      {
        Header: "Fuel Delivery Date",
        accessor: "fuelDeliveryDate",
      },
      {
        Header: "Invoice Amount",
        accessor: "invoiceAmount",
      },
      {
        Header: "Gallons Unleaded",
        accessor: "gallonsUnleaded",
      },
      {
        Header: "Total Cost Gallons",
        accessor: "totalCostGallons",
      },
      {
        Header: "Gallons Plus",
        accessor: "gallonsPlus",
      },
      {
        Header: "Total Cost Plus",
        accessor: "totalCostPlus",
      },
      {
        Header: "Gallons Premium",
        accessor: "gallonsPremium",
      },
      {
        Header: "Total Cost Premium",
        accessor: "totalCostPremium",
      },
      {
        Header: "Gallons Diesel",
        accessor: "gallonsDesiel",
      },
      {
        Header: "Total Cost Diesel",
        accessor: "totalCostDesiel",
      },
      {
        Header: "Freight On Gasoline",
        accessor: "freightOnGasoline",
      },
      {
        Header: "Freight Surcharge",
        accessor: "freightSurcharge",
      },
    ];

  }


  return columns;

}