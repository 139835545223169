import React, { useEffect } from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { FuelDeliveryService } from "../../../services/user/fueldelivery.service";
import moment from "moment"
import AuthGuard from "../../common/authguard.component";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AddNewFuelDeliveryComponent = ({
    isInWizard = false,
    onNext,
    onBack,
    showBreadcrumbs = true,
    showButtons = true,
    showWrapper = true,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const pageNumber = location.state?.pageNumber || 0;
    const fuelDeliveryData = location.state?.fuelDeliveryData || {};
    const isEditMode = !!location.state?.fuelDeliveryData;
    const selectedStore = useSelector(state => state?.user?.selectedStoreId);

    const formik = useFormik({
        initialValues: {
            storeId: selectedStore,
            date: fuelDeliveryData.fuelDeliveryDate ? moment(fuelDeliveryData.fuelDeliveryDate).format('YYYY-MM-DD') : moment(new Date()).format('MM/DD/YYYY'),
            invoice_no: fuelDeliveryData.invoiceNo || '',
            payment_date: fuelDeliveryData.paymentDate ? moment(fuelDeliveryData.paymentDate).format('YYYY-MM-DD') : moment(new Date()).format('MM/DD/YYYY'),
            invoice_amount: fuelDeliveryData.invoiceAmount || 0,
            gallons: {
                unleaded: fuelDeliveryData.gallonsUnleaded || 0,
                plus: fuelDeliveryData.gallonsPlus || 0,
                super: fuelDeliveryData.gallonsPremium || 0,
                desiel: fuelDeliveryData.gallonsDiesel || 0,
                freight_on_gasoline: fuelDeliveryData.freightOnGasoline || 0,
                freight_surcharge: fuelDeliveryData.freightSurcharge || 0,
                paid: fuelDeliveryData.paid || 'NO',
            },
            total_cost: {
                unleaded: fuelDeliveryData.totalCostGallons || 0,
                plus: fuelDeliveryData.totalCostPlus || 0,
                super: fuelDeliveryData.gallonsPremium || 0,
                desiel: fuelDeliveryData.totalCostDiesel || 0,
            }
        },

        validationSchema: Yup.object({
            date: Yup.date().required('Date is required').typeError('Invalid Date Format'),
            invoice_no: Yup.string().required('Invoice No is required'),
            payment_date: Yup.date().required('Payment Date is required').typeError('Invalid Date Format'),
        }),

        onSubmit: async (values) => {
            const data = {
                storeId: selectedStore,
                fuelDeliveryDate: values.date,
                // fuelDeliveryDate: moment(values.date, 'MM/DD/YYYY').toISOString(),
                invoiceNo: values.invoice_no,
                paymentDate: values.payment_date,
                // paymentDate: moment(values.payment_date, 'MM/DD/YYYY').toISOString(),
                invoiceAmount: parseFloat(values.invoice_amount) || 0,
                gallonsUnleaded: parseFloat(values.gallons.unleaded) || 0,
                totalCostGallons: parseFloat(values.total_cost.unleaded) || 0,
                gallonsPlus: parseFloat(values.gallons.plus) || 0,
                totalCostPlus: parseFloat(values.total_cost.plus) || 0,
                gallonsPremium: parseFloat(values.gallons.super) || 0,
                totalCostPremium: parseFloat(values.total_cost.super) || 0,
                gallonsDiesel: parseFloat(values.gallons.desiel) || 0,
                totalCostDiesel: parseFloat(values.total_cost.desiel) || 0,
                freightOnGasoline: parseFloat(values.gallons.freight_on_gasoline) || 0,
                freightSurcharge: parseFloat(values.gallons.freight_surcharge) || 0,
                paid: values.gallons.paid,
            }

            if (isEditMode) {
                const res = await FuelDeliveryService.updateFuelDelivery(fuelDeliveryData.fuelDeliveryId, data)
                if (res?.isSucess) {
                    navigate('/purchaseinfo/fueldelivery', { state: { pageNumber: pageNumber } });
                }
            } else {
               const res = await FuelDeliveryService.createFuelDelivery(data);
                if (res?.isSucess) {
                    if (isInWizard) {
                        onNext();
                    }
                    else {
                        navigate('/purchaseinfo/fueldelivery', { state: { pageNumber: pageNumber } });
                    }
                }
            }
            //handleReset();    
        }
    });

    // const handleReset = () => {
    //     formik.resetForm();
    // };

    const handleCancelClick = () => {
        navigate('/purchaseinfo/fueldelivery', { state: { pageNumber: pageNumber } });
    };

    useEffect(() => {
        if (isEditMode) {
            formik.setValues({
                storeId: selectedStore,
                date: fuelDeliveryData.fuelDeliveryDate ? moment(fuelDeliveryData.fuelDeliveryDate).format('YYYY-MM-DD') : '',
                invoice_no: fuelDeliveryData.invoiceNo || '',
                payment_date: fuelDeliveryData.paymentDate ? moment(fuelDeliveryData.paymentDate).format('YYYY-MM-DD') : '',
                invoice_amount: parseFloat(fuelDeliveryData.invoiceAmount) || 0,
                gallons: {
                    unleaded: parseFloat(fuelDeliveryData.gallonsUnleaded) || 0,
                    plus: parseFloat(fuelDeliveryData.gallonsPlus) || 0,
                    super: parseFloat(fuelDeliveryData.gallonsPremium) || 0,
                    desiel: parseFloat(fuelDeliveryData.gallonsDiesel) || 0,
                    freight_on_gasoline: parseFloat(fuelDeliveryData.freightOnGasoline) || 0,
                    freight_surcharge: parseFloat(fuelDeliveryData.freightSurcharge) || 0,
                    paid: fuelDeliveryData.paid || '0',
                },
                total_cost: {
                    unleaded: parseFloat(fuelDeliveryData.totalCostGallons) || 0,
                    plus: parseFloat(fuelDeliveryData.totalCostPlus) || 0,
                    super: parseFloat(fuelDeliveryData.gallonsPremium) || 0,
                    desiel: parseFloat(fuelDeliveryData.totalCostDiesel) || 0,
                }
            });
        }
    }, []);

    return (
        <AuthGuard page={"allowFuelDelivery"}>
            <div className={showWrapper ? 'page-wrapper' : ''}>
                <div className="page-content">

                    {showBreadcrumbs && !isInWizard && (
                        <>
                            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                <div className="breadcrumb-title pe-3">Fuel Delivery</div>
                                <div className="ps-3">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 p-0">
                                            <li className="breadcrumb-item"></li>
                                            <li className="breadcrumb-item active" aria-current="page">{isEditMode ? 'Edit Fuel Delivery' : 'Add Fuel Delivery'}</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="page-breadcrumb d-xl-none d-xl-block align-items-center mb-3">
                                <div className="breadcrumb-item pe-3 ">{isEditMode ? 'Edit Fuel Delivery' : 'Add Fuel Delivery'}</div>
                            </div>
                        </>
                    )}

                    {!isInWizard && (<hr />)}
                    <div className="row">
                        <div className="col-xl-12 mx-auto">
                            <div className="card">
                                <div className="card-body p-4">
                                    <form className="row g-3 needs-validation" onSubmit={formik.handleSubmit}>
                                        <div className="d-flex gap-2 mt-4">
                                            <div className="col-md-3 col-4">
                                                <label htmlFor="date" className="form-label">Date: <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-md-3 col-8">
                                                <DatePicker
                                                    selected={formik.values.date}
                                                    onChange={val => {
                                                        formik.setFieldValue("date", val);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    name="date"
                                                    dateFormat="MM/dd/YYYY" // Display format in the input field
                                                    className={`form-control ${formik.touched.date && formik.errors.date ? 'is-invalid' : ''}`}
                                                    id="date"
                                                />
                                                {formik.touched.date && formik.errors.date ? (
                                                    <div className='text-danger'>{formik.errors.date}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="d-flex gap-2">
                                            <div className="col-md-3 col-4">
                                                <label htmlFor="invoice_no" className="form-label">Invoice No: <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-md-3 col-8">
                                                <input
                                                    type="text"
                                                    name="invoice_no"
                                                    value={formik.values.invoice_no}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    className={`form-control ${formik.touched.invoice_no && formik.errors.invoice_no ? 'is-invalid' : ''}`}
                                                    id="invoice_no"
                                                    autoComplete="off"
                                                    placeholder="0"
                                                />
                                                {formik.touched.invoice_no && formik.errors.invoice_no ? (
                                                    <div className='text-danger'>{formik.errors.invoice_no}</div>
                                                ) : null}
                                            </div>

                                        </div>

                                        <div className="d-flex gap-2">
                                            <div className="col-md-3 col-4">
                                                <label htmlFor="payment_date" className="form-label">Payment Date: <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-md-3 col-8">
                                                <DatePicker
                                                    selected={formik.values.payment_date}
                                                    onChange={val => {
                                                        formik.setFieldValue("payment_date", val);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    name="payment_date"
                                                    dateFormat="MM/dd/YYYY" // Display format in the input field
                                                    className={`form-control ${formik.touched.payment_date && formik.errors.payment_date ? 'is-invalid' : ''}`}
                                                    id="payment_date"
                                                />
                                                {formik.touched.payment_date && formik.errors.payment_date ? (
                                                    <div className='text-danger'>{formik.errors.payment_date}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <div className="col-md-3 col-4">
                                                <label htmlFor="payment_date" className="form-label">Invoice Amount: <span className="text-danger"></span></label>
                                            </div>
                                            <div className="col-md-3 col-8">
                                                <input
                                                    type="number"
                                                    name="invoice_amount"
                                                    value={formik.values.invoice_amount}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    className={`form-control `}
                                                    id="invoice_amount"
                                                    placeholder="0"
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <div className="col-md-3 col-4"></div>
                                            <div className="col-md-3 col-4"><strong>Gallons</strong></div>
                                            <div className="col-md-3 col-4"><strong>Total Cost</strong></div>
                                        </div>
                                        {['unleaded', 'plus', 'super', 'desiel'].map((fuelType) => (
                                            <div className="d-flex gap-2" key={fuelType}>
                                                <div className="col-md-3 col-4">
                                                    <label htmlFor={fuelType} className="form-label">{fuelType.charAt(0).toUpperCase() + fuelType.slice(1)}</label>
                                                </div>
                                                <div className="col-md-3 col-4">
                                                    <input
                                                        type="number"
                                                        name={`gallons.${fuelType}`}
                                                        value={formik.values.gallons[fuelType]}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id={fuelType}
                                                        placeholder="0"
                                                    />
                                                </div>

                                                <div className="col-md-3 col-4">
                                                    <input
                                                        type="number"
                                                        name={`total_cost.${fuelType}`}
                                                        value={formik.values.total_cost[fuelType]}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        id={`${fuelType}_total_cost`}
                                                        placeholder="0"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        <div className="d-flex gap-2">
                                            <div className="col-md-3 col-4"><label htmlFor="freight_on_gasoline" className="form-label">Freight On Gasoline</label></div>
                                            <div className="col-md-3 col-8"><input
                                                type="number"
                                                name="gallons.freight_on_gasoline"
                                                value={formik.values.gallons.freight_on_gasoline}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                className="form-control"
                                                id="freight_on_gasoline"
                                                placeholder="0"
                                            /></div>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <div className="col-md-3 col-4">
                                                <label htmlFor="freight_surcharge" className="form-label">Freight Surcharge</label>
                                            </div>
                                            <div className="col-md-3 col-8">
                                                <input
                                                    type="number"
                                                    name="gallons.freight_surcharge"
                                                    value={formik.values.gallons.freight_surcharge}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    className="form-control"
                                                    id="freight_surcharge"
                                                    placeholder="0"
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <div className="col-md-3 col-4">
                                                <label htmlFor="paid" className="form-label">Paid</label>
                                            </div>
                                            <div className="col-md-3 col-8">
                                                <select
                                                    name="gallons.paid"
                                                    value={formik.values.gallons.paid}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    className="form-select"
                                                    id="paid"
                                                >
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                    
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-12 mt-5">
                                            <div className="d-md-flex d-grid align-items-center gap-3">
                                                {showButtons && (
                                                    <>
                                                        {isInWizard ? (
                                                            <>
                                                                <button type="submit" className="btn btn-primary me-2">Save & Next</button>

                                                                <button type="button" className="btn btn-outline-secondary px-4" onClick={onBack}>
                                                                    Back
                                                                </button>
                                                                <button type="button" className="btn btn-outline-secondary px-4" onClick={onNext}>
                                                                    Skip
                                                                </button>
                                                                <button type="button" className="btn btn-outline-secondary px-4" onClick={()=>navigate('/dashboard')}>
                                                                    Finish
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <div className="col-md-12 mt-2">
                                                                <button type="submit" className="btn btn-primary px-4 me-3" onClick={onNext}>
                                                                    {isEditMode ? 'Update' : 'Save'}
                                                                </button>
                                                                <button type="button" className="btn btn-danger px-4" onClick={handleCancelClick}>
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthGuard>
    );
};

export default AddNewFuelDeliveryComponent;
