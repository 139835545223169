import React, { useState, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component";
import { useNavigate, useLocation } from "react-router-dom";
// import DeleteModelPopupComponent from "../../common-model-popup/deleteModelPopup.component";
import { useSelector } from "react-redux";
import { CategoryService } from "../../../services/user/category.services";
import DeleteModelPopupComponent from "../common-model-popup/deleteModelPopup.component";
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from "../../common/notification/toaster_notify.component";

const CategoryMasterComponent = () => {
    const location = useLocation();
    const [filterText, setFilterText] = useState('');
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const selectedStore = useSelector(state => state?.user?.selectedStoreId);
    const [data, setData] = useState([]);
    const pageNumber = location.state?.pageNumber || 1;
    const [currentPage, setCurrentPage] = useState(pageNumber);
    const [selectedRows, setSelectedRows] = useState([]);


    // useEffect(() => {
    //     const fetchCategoryData = async () => {
    //         try {
    //             await CategoryService.getAllCategory(selectedStore).then(response => {
    //                 if (response) {
    //                     setData(response);
    //                 }
    //                 else {
    //                     setData([])
    //                 }
    //             })
    //         } catch (error) {
    //             console.log(error)
    //         }
    //     };
    //     fetchCategoryData();
    // }, [selectedStore]);

    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                const response = await CategoryService.getAllCategory(selectedStore);
                setData(response || []);
            } catch (error) {
                console.log(error)
            }
        };
        fetchCategoryData();
    }, [selectedStore]);

    const columns = [
        { width: '70%', name: 'Category Name', selector: row => row.categoryName, sortable: true },
        {
            width: '20%', name: 'Actions', cell: row => (
                <div className="d-flex">
                    <button type="button"
                        className="btn btn-primary me-2"
                        onClick={() => handleEditClick(row)} >
                        <span className="d-none d-md-block"> Edit</span>
                        <i className="d-md-none d-lg-none fadeIn animated bx bx-edit-alt"></i>
                    </button>

                    <button type="button"
                        className="btn btn-danger"
                        onClick={() => handleDeleteClick(row)}>
                        <span className="d-none d-md-block">Delete</span>
                        <i className="d-md-none d-lg-none fadeIn animated bx bx-trash-alt"></i>
                    </button>
                </div>
            ),
        }
    ];
    const handleAddCategoryClick = () => {
        navigate('/categorymaster/addcategory', { state: { pageNumber: currentPage } });
    };

    const handleEditClick = (row) => {
        navigate('/categorymaster/editcategory', { state: { categoryId: row.categoryId, pageNumber: currentPage } });
    };

    const handleSearch = event => {
        const { value } = event.target;
        setFilterText(value);
    };

    const handleDeleteClick = (item) => {
        setItemToDelete(item);
        setShowModal(true);
    };

    const handleDeleteSelectedRowClick = () => {
        if (selectedRows.length > 0) {
            setShowModal(true);
            setSelectedRows(selectedRows)
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            const idsToDelete = selectedRows.length > 0
                ? selectedRows.map(row => row.categoryId.toString())
                : itemToDelete ? [itemToDelete.categoryId.toString()] : [];

            if (idsToDelete.length > 0) {
                await CategoryService.deleteManyCategory(idsToDelete);

                setData(prevData => prevData.filter(item => !idsToDelete.includes(item.categoryId.toString())));
                setSelectedRows([]);
                setItemToDelete(null);
            }
            setShowModal(false);
        } catch (error) {
            handleNotify(error.message || 'Error while deleting category.', TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const filteredData = data.filter(item =>
        item.categoryName.toLowerCase().includes(filterText.toLowerCase())
    );

    const onChangePage = (pageIndex) => {
        setCurrentPage(pageIndex)
    };

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Account Setup</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item"></li>
                                    <li className="breadcrumb-item active" aria-current="page">Category Master</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="page-breadcrumb d-xl-none d-xl-block align-items-center mb-3">
                        <div className="breadcrumb-item pe-3 ">Account Setup &gt; Category Master</div>
                    </div>
                    <hr />
                    <div className="bg-white col-md-12 p-4">
                        <div className="d-md-flex d-grid align-items-center gap-3">
                            <div className="d-flex">
                                <button
                                    type="button"
                                    className="btn btn-primary px-4 mt-3 me-3"
                                    onClick={handleAddCategoryClick}
                                >
                                    New
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger px-4 mt-3 ms-auto ms-md-0"
                                    onClick={handleDeleteSelectedRowClick}
                                >
                                    Delete
                                </button>
                            </div>
                            <div className="mt-3">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Search..."
                                    value={filterText}
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        selectableRows
                        onChangePage={onChangePage}
                        onSelectedRowsChange={handleRowSelected}
                        paginationDefaultPage={currentPage}
                    />
                </div>
            </div>
            {showModal &&
                <DeleteModelPopupComponent
                    show={showModal}
                    onClose={handleModalClose}
                    onConfirm={handleDeleteConfirm}
                    title="Confirm"
                    confirmText="Delete"
                    Message="Are you sure want to delete selected record(s)?"
                />}
        </>
    );
};

export default CategoryMasterComponent;
