import { API_RESPONSE_STATUS, ResponseModel, handleGetAsync, handlePostAsync, handlePutAsync, handleDeleteAsync } from "../../components/common/commonclass";
import { EndPoint } from "../../components/common/config";
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from "../../components/common/notification/toaster_notify.component";


var responseModel = new ResponseModel();

export class VendorService {
    static async getvendors(storeId, searchText = "") {
        const requestModel = {
            storeId: storeId,
            searchText: searchText
        };

        responseModel = new ResponseModel();
        const requestURL = EndPoint.BIND_VENDOR;

        responseModel = await handleGetAsync(requestURL, true, requestModel);

        if (responseModel && (responseModel.status === API_RESPONSE_STATUS.SUCCESS)) {
            return responseModel.data;
        }
    }

    // get by ID
    static async getVendorById(id) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.VENDOR}/${id}`;

        try {
            responseModel = await handleGetAsync(requestURL, true);
            if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
                return responseModel.data;
            } else {
                throw new Error('Failed to fetch vendor details.');
            }
        } catch (error) {
            console.error('Error fetching vendor details:', error);
            throw error;
        }
    }
    // Update 
    static async updateVendorById(id, data) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.VENDOR}/${id}`;

        responseModel = await handlePutAsync(requestURL, true, data);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify('Vendor  data updated successfully.', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if (responseModel.status === API_RESPONSE_STATUS.FAIL) {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else {
            throw new Error('Failed to update Vendor.');
        }
    }

    // Create 
    static async createVendor(data) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.VENDOR}`;

        responseModel = await handlePostAsync(requestURL, true, data);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify('Vendor added successfully!', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if (responseModel.status === API_RESPONSE_STATUS.FAIL) {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        }
        else {
            throw new Error('Failed to create new Vendor.');
        }
    }

    // Delete 
    static async deleteVendorById(id) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.VENDOR}/${id}`; // endpoint

        try {
            responseModel = await handleDeleteAsync(requestURL, true);
            if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
                return responseModel.data;
            } else {
                throw new Error('Failed to delete vendor data.');
            }
        } catch (error) {
            console.error('Error deleting vendor data:', error);
            throw error;
        }
    }

    // Deletemany 
    static async deleteManyVendor(ids) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.VENDOR}/deletemany`; // endpoint

        responseModel = await handleDeleteAsync(requestURL, true, ids);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify(responseModel.message, TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel.data;
        } else {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
        }
    }
};
