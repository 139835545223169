import React, { useEffect, useState } from "react";
// import styled from "styled-components";
import { useTable, useSortBy, useFilters ,usePagination} from "react-table";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
// import XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { getColumns } from "../../../columns";

// import makeData from "./makeData";

// const Styles = styled.div`
//   padding: 1rem;

//   table {
//     border-spacing: 0;
//     border: 1px solid black;

//     tr {
//       :last-child {
//         td {
//           border-bottom: 0;
//         }
//       }
//     }

//     th,
//     td {
//       margin: 0;
//       padding: 0.5rem;
//       border-bottom: 1px solid black;
//       border-right: 1px solid black;

//       :last-child {
//         border-right: 0;
//       }
//     }
//   }
// `;

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

const defaultColumn = {
  Filter: DefaultColumnFilter,
};

function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === "csv") {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  } else if (fileType === "xlsx") {
    // XLSX example

    // const header = columns.map((c) => c.exportValue);
    // const compatibleData = data.map((row) => {
    //   const obj = {};
    //   header.forEach((col, index) => {
    //     obj[col] = row[index];
    //   });
    //   return obj;
    // });

    // let wb = XLSX.utils.book_new();
    // let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
    //   header,
    // });
    // XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    // XLSX.writeFile(wb, `${fileName}.xlsx`);

    // // Returning false as downloading of file is already taken care of
    // return false;
  }
  //PDF example
  if (fileType === "pdf") {
    const headerNames = columns.map((column) => column.exportValue);
    const doc = new JsPDF('l', 'mm', 'a4');
    doc.scale(3.0, 1.0);
    doc.autoTable({
      head: [headerNames],
      body: data,
      margin: { top: 20 },
      //   styles: {
      //     minCellHeight: 9,
      //     halign: "left",
      //     valign: "center",
      //     fontSize: 11,
      //   },
    });
    doc.save(`${fileName}.pdf`);

    return false;
  }

  // Other formats goes here
  return false;
}

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    exportData,
    page, 
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },

  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      getExportFileBlob,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    useExportData,
    usePagination
  );

  return (
    <>
      <div className="d-inline mt-2 mb-2 text-end">
        <button
          className="btn btn-dark ms-2 me-3 "
          onClick={() => {
            exportData("csv", true);
          }}
        >
          <i className="bi bi-filetype-csv"></i>
          Export To CSV
        </button>
        {/* <button
        onClick={() => {
          exportData("csv", false);
        }}
      >
        Export Current View as CSV
      </button> */}
        {/* <button
        onClick={() => {
          exportData("xlsx", true);
        }}
      >
        Export All as xlsx
      </button>
      <button
        onClick={() => {
          exportData("xlsx", false);
        }}
      >
        Export Current View as xlsx
      </button> */}
        {/* <button
      className="btn btn-dark ms-2 me-2"
        onClick={() => {
          exportData("pdf", true);
        }}
      >
        <i className="bi bi-filetype-pdf"></i>
        Export To PDF
      </button>{" "} */}
        {/* <button
        onClick={() => {
          exportData("pdf", false);
        }}
      >
        Export Current View as PDF
      </button> */}
      </div>
      <div className="table-responsive mx-2 rounded">
        <table {...getTableProps()} className="table table-success table-striped ">
          <thead >
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th {...column.getHeaderProps()} className="pe-4 bg-primary text-white"
                  style={{ fontWeight: 'normal' }} // Inline style to set font weight
                  >
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                    </span>
                    <div>
                      {/* {column.canFilter ? column.render("Filter") : null} */}
                      {/* <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span> */}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination d-flex justify-content-center">
          <button className="btn btn-primary me-2" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button className="btn btn-primary me-2" onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <button className="btn btn-primary me-2" onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button className="btn btn-primary me-2" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
          <span className="mt-2 ">
            Page{' '}
            <strong>
               {pageIndex + 1} of {pageOptions.length} 
            </strong>{' '}
          </span>
          {/* <span className="ms-1 mt-1">
             Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select> */}
        </div>
      </div>
      <br />
    </>
  );
}

export function ReportTable({ data, reportName,reportType }) {
  const [columns, setcolumns] = useState(getColumns(reportName,reportType))
  useEffect(() => {
    setcolumns(getColumns(reportName,reportType))
  }, [reportName])

  // const columns = React.useMemo(
  //   () => [
  //         {
  //           Header: "Date",
  //           accessor: "invoiceDate",
  //         },
  //         {
  //           Header: "Inside Sales",
  //           accessor: "insideTotal",
  //         },
  //         {
  //           Header: "Fuel",
  //           accessor: "fuel",
  //         },
  //         {
  //           Header: "Cigarette",
  //           accessor: "cigarette",
  //         },
  //         {
  //           Header: "Beer",
  //           accessor: "beer",
  //         },
  //         {
  //           Header: "Tax Grocery",
  //           accessor: "taxGrocery",
  //         },
  //         {
  //           Header: "No Tax",
  //           accessor: "noTax",
  //         },
  //         {
  //           Header: "Lotto",
  //           accessor: "lotto",
  //         },
  //         {
  //           Header: "Scratch Off",
  //           accessor: "scratchOff",
  //         },
  //         {
  //           Header: "Money Order",
  //           accessor: "moneyOrder",
  //         },
  //         {
  //           Header: "Deli",
  //           accessor: "deli",
  //         },
  //         {
  //           Header: "Phone Cards",
  //           accessor: "phoneCards",
  //         },
  //         {
  //           Header: "Plu Not Found",
  //           accessor: "pluNotFound",
  //         },
  //         {
  //           Header: "Sales Tax",
  //           accessor: "salesTax",
  //         },
  //         {
  //           Header: "Total",
  //           accessor: "total",
  //         },
  //         {
  //           Header: "Credit",
  //           accessor: "credit",
  //         },
  //         {
  //           Header: "Debit",
  //           accessor: "debit",
  //         },
  //         {
  //           Header: "EBT",
  //           accessor: "ebt",
  //         },
  //         {
  //           Header: "Lotto Pay",
  //           accessor: "lottoPay",
  //         },
  //         {
  //           Header: "Pay Out",
  //           accessor: "payOut",
  //         },
  //         {
  //           Header: "Coupons",
  //           accessor: "coupons",
  //         },
  //         {
  //           Header: "House Accounts",
  //           accessor: "houseAccounts",
  //         },
  //         {
  //           Header: "Refunds",
  //           accessor: "refunds",
  //         },
  //         {
  //           Header: "Drive Off",
  //           accessor: "driveOff",
  //         },
  //         {
  //           Header: "Cash Deposit",
  //           accessor: "cashDeposit",
  //         },
  //         {
  //           Header: "Bank Deposit",
  //           accessor: "actualDeposit",
  //         },
  //         {
  //           Header: "Short Over",
  //           accessor: "shortOver",
  //         },
  //   //     ],
  //   // }
  //   ],
  //   []
  // );

  //   const data = React.useMemo(() => makeData(20), []);

  return (
    // <Styles>
    <Table columns={columns} data={data} />
    // </Styles>
  );
}

// export default App;
