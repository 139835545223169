import moment from "moment"

export const getLeftMenuAccess = (storeDetail) => {
    let accessLeftMenu = {
        allowPayments: true,
        allowExpenses: true,
        allowDailySales: true,
        allowCreditCards: true,
        allowFuelSales: true,
        allowRebates: true,
        allowFuelDelivery: true,
        allowPaymentTab: true,
        allowSalesTab: true,

    }
    if (storeDetail) {
        const { allowPayments, allowExpenses, allowDailySales, allowCreditCards, allowFuelSales, allowRebates, allowFuelDelivery } = storeDetail
        const payment = [allowPayments, allowExpenses]
        const salesInfo = [allowDailySales, allowCreditCards, allowFuelSales, allowRebates]
        const { userStoreMappingId, userId, storeId, storeName, ...remainAccessObj } = storeDetail
        const accessObj = Object.entries(remainAccessObj);
        accessLeftMenu = accessObj.reduce((acc, [key, value]) => {
            acc[key] = !!value;
            return acc;
        }, {});
        accessLeftMenu.allowPaymentTab = payment.some(value => value === 1)
        accessLeftMenu.allowSalesTab = salesInfo.some(value => value === 1)
    }
    return accessLeftMenu;
}


export const REPORT_TYPE = {
    monthly: "monthly",
    yearly: "yearly",
    daily: "daily",
}


export const setToken = (accessToken, refreshToken) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
}
export const getToken = () => {
    let accessToken = localStorage.getItem('accessToken');
    let refreshToken = localStorage.getItem('refreshToken');
    return { accessToken, refreshToken }
}

export const setLocalStorageItem = (key, value) => {
    localStorage.setItem(key, value);
}

export const getLocalStorageItem=(key)=>{
    const keyValue=localStorage.getItem(key);
    return JSON.parse(keyValue)
}


export const counterField=[{
    value:100,
    name:"h_100",
},
{
    value:50,
    name:"h_50",
},
{
    value:20,
    name:"h_20",
},
{
    value:10,
    name:"h_10",
},
{
    value:5,
    name:"h_5",
},
{
    value:1,
    name:"h_1",
},
{
    value:"other",
    name:"other",
}
]

export const getTotalBil=(per,value)=>{
   const totalValue=parseFloat(value)
   let total;
   total =totalValue*per
   return total.toFixed(2) 
}

export const formateDate=(data)=>{
    data.map(item=>{
        item.invoiceDate=moment(item.invoiceDate).format('MM/DD/YYYY')
    })
    return data;
}
