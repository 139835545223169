import React, { useMemo, useState } from 'react'
import CounterInput from '../../components/dashboard/moneycounter/counter-input.component';
import { counterField, getTotalBil } from '../../utility';

const MoneyCounter = () => {
    const [noOfBills, setnoOfBills] = useState({
        h_100: "",
        h_50: "",
        h_20: "",
        h_10: "",
        h_5: "",
        h_1: "",
        other: ""
    })
    const [total, setTotal] = useState({
        h_100: 0,
        h_50: 0,
        h_20: 0,
        h_10: 0,
        h_5: 0,
        h_1: 0,
        other: 0
    })

    const handleChangeBill = (e) => {
        const { name, value } = e.target
        const isValid = (/^\d+(\.)?(\d+)?$/g).test(value);
        if(!isValid && value !== "") {
            return
        }
        setnoOfBills((prev) => ({
            ...prev,
            [name]: value
        }))

    }

    const handleOnBlurBill = (e, item) => {
        const { name, value } = e.target
        const numericValue = parseFloat(value) || 0;
        let totalAmount = name === "other" ? value : getTotalBil(item.value, value)
        if (!value) {
            totalAmount = 0
        }
        setTotal((prev) => ({
            ...prev,
            [name]: totalAmount
        }))

    }

    const handleChangeTotal = (e) => {
        const { name, value } = e.target
        const numericValue = value.replace(/[^0-9]/g, '');
        setTotal((prev) => ({
            ...prev,
            [name]: numericValue
        }))
    }

    const { h_1, h_10, h_100, h_20, h_5, h_50, other } = total;
    const getTotalBillAmount = useMemo(() => {
        const { h_1, h_10, h_100, h_20, h_5, h_50, other } = total;
        return (
        parseFloat(h_1) + 
        parseFloat(h_10) + 
        parseFloat(h_100) + 
        parseFloat(h_20) + 
        parseFloat(h_5) + 
        parseFloat(h_50) + 
        parseFloat(other)).toFixed(2)
    }, [h_100, h_50, h_20, h_10, h_5, h_1, other])



    return (
        <div className="page-wrapper">
            <div className="page-content">
                <div className="container">
                    <div className=" d-flex justify-content-center">
                        {/* <div className="row bg-warning bg-opacity-10 w-sm-100 w-lg-50 w-md-50 rounded-1 pb-4 px-5 shadow-sm"> */}
                        <div className="row bg-warning bg-opacity-10 w-sm-100 w-lg-50 w-md-50 rounded-1 pb-4 shadow-sm">
                            <div className="col-12">
                                <h4 className='mt-2 text-center'>Money counter</h4>
                                <hr />
                                <div className="row">
                                    <div className="col-4  rounded  pt-2 text-center"><h6>Bill Value</h6></div>
                                    <div className="col-4  rounded pt-2 text-center"> <h6># Of Bills:</h6></div>
                                    <div className="col-4  rounded pt-2 text-center"><h6>Total:</h6></div>
                                </div>
                                {
                                    counterField.map((item) => (
                                        <CounterInput
                                            key={item.name}
                                            item={item}
                                            noBill={noOfBills[item.name]}
                                            handleChangeBill={handleChangeBill}
                                            handleOnBlurBill={handleOnBlurBill}
                                            total={total[item.name]}
                                        />
                                    ))
                                }
                                <div className="row pb-2">
                                    <div className="col-4 rounded ">
                                        <h6 className='mt-2 d-flex justify-content-center align-items-center'>Total</h6></div>
                                    <div className="col-4 rounded ">
                                        <input type="text" className="form-control " aria-label="Server" value={""} onChange={() => { }} />
                                    </div>
                                    <div className="col-4 rounded ">
                                        <input type="text" className="form-control " aria-label="Server" value={getTotalBillAmount} readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoneyCounter;
