import { API_RESPONSE_STATUS, ResponseModel, handleGetAsync, handlePutAsync, handlePostAsync, handleDeleteAsync } from "../../components/common/commonclass";
import { EndPoint } from "../../components/common/config";
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from "../../components/common/notification/toaster_notify.component";


var responseModel = new ResponseModel();

export class RebatesAndCommissionService {
    static async getRebatesAndCommissions(storeId, searchText = "") {
        responseModel = new ResponseModel();
        const requestURL = EndPoint.FETCH_REBATES_AND_COMMISSION;
        const requestModel = {
            storeId: storeId,
            searchText: searchText
        };
        
        responseModel = await handleGetAsync(requestURL, true,requestModel);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            return responseModel.data;
        } else {
            console.error('Failed to fetch rebates and commissions data');
            return [];
        }
    }

    // Get by ID
    static async getRebateOrCommissionById(id) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.REBATES_AND_COMMISSION}/${id}`; // endpoint

        responseModel = await handleGetAsync(requestURL, true);

        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            return responseModel.data;
        } else {
            throw new Error('Failed to fetch rebate or commission details.');
        }
    }

    // Update 
    static async updateRebateOrCommission(id, data) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.REBATES_AND_COMMISSION}/${id}`; // endpoint

        responseModel = await handlePutAsync(requestURL, true, data);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify('Rebates  data updated successfully.', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if(responseModel.status === API_RESPONSE_STATUS.FAIL){
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        }  else {
            throw new Error('Rebates to update rebates sale.');
        }
    }

    // Add 
    static async addRebateOrCommission(data) {
        responseModel = new ResponseModel();
        const requestURL = EndPoint.REBATES_AND_COMMISSION; // endpoint

        responseModel = await handlePostAsync(requestURL, true, data);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify('Rebates added successfully!', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if(responseModel.status === API_RESPONSE_STATUS.FAIL){
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } 
        else {
            throw new Error('Failed to create new Rebates sale.');
        }
    }
     // Delete
     static async deleteRebateOrCommission(id) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.REBATES_AND_COMMISSION}/${id}`; // endpoint

        responseModel = await handleDeleteAsync(requestURL, true);

        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            return responseModel.data;
        } else {
            console.error('Failed to delete rebate or commission.');
        }
    }

    
    // delete many
    static async deleteManyRebatesOrCommition(ids) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.REBATES_AND_COMMISSION}/deletemany`;

        responseModel = await handleDeleteAsync(requestURL, true, ids);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify(responseModel.message, TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel.data;
        }
        else {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
        }
    }
};
