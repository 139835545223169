import React from 'react'

const CounterInput = ({item,noBill,total,handleChangeBill,handleOnBlurBill}) => {
    return (
        <div className="row pb-2 text-center">
            <div className="col-4 rounded "><h6 className='mt-2'>{item.value !== "other"?"$":""}{item.value}</h6></div>
            <div className="col-4 rounded "><input type="text"  name={item.name} onChange={handleChangeBill}  className="form-control " onBlur={(e)=>handleOnBlurBill(e,item)} aria-label="Server" value={noBill} /></div>
            <div className="col-4 rounded "><input type="text" readOnly className="form-control " aria-label="Server" value={total} /></div>
        </div>
    )
}

export default CounterInput
