import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import './css/style.css'
import './css/newstyle.css'
const Website = () => {
    const [isOpen, setisOpen] = useState(false)
    return (
        <>

            <div>
                <section className="navbar-area navbar-nine">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <nav className="navbar navbar-expand-lg">
                                    <a className="navbar-brand" href="index.html">
                                        <img src="/newassets/images/white-logo.svg" alt="Logo" />
                                    </a>
                                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNine" aria-controls="navbarNine" aria-expanded="false" aria-label="Toggle navigation" onClick={()=>setisOpen(true)}>
                                        <span className="toggler-icon" />
                                        <span className="toggler-icon" />
                                        <span className="toggler-icon" />
                                    </button>
                                    <div className="collapse navbar-collapse sub-menu-bar" id="navbarNine">
                                        <ul className="navbar-nav me-auto">
                                            <li className="nav-item">
                                                <a className="page-scroll active" href="#hero-area">Home</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#about">About</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#services">Services</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#pricing">Pricing</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#contact">Contact</a>
                                            </li>
                                            <li className="nav-item">
                                            <Link to="login">Login</Link>
                                                {/* <a className="page-scroll" href="https://compassionate-burnell.180-179-213-167.plesk.page">Login</a> */}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="navbar-btn d-none d-lg-inline-block">
                                        <a className="menu-bar" onClick={()=>setisOpen(true)}><i className="lni lni-menu" /></a>
                                    </div>
                                </nav>
                                {/* navbar */}
                            </div>
                        </div>
                        {/* row */}
                    </div>
                    {/* container */}
                </section>
                {/*====== NAVBAR NINE PART ENDS ======*/}
                {/*====== SIDEBAR PART START ======*/}
                <div className={`sidebar-left ${isOpen?"open":""}`}>
                    <div className="sidebar-close">
                        <a className="close" onClick={()=>setisOpen(false)}><i className="lni lni-close" /></a>
                    </div>
                    <div className="sidebar-content">
                        <div className="sidebar-logo">
                            <a href="index.html"><img src="/newassets/images/logo.svg" alt="Logo" /></a>
                        </div>
                        {/* logo */}
                        <div className="sidebar-menu">
                            <ul>
                                <li> <a className="page-scroll" href="#about">About</a></li>
                                <li><a className="page-scroll" href="#services">Services</a></li>
                                <li><a className="page-scroll" href="#pricing">Pricing</a></li>
                                <li><a className="page-scroll" href="#contact">Contact</a></li>
                                <li>
                                    {/* <a className="page-scroll" href="/login">Login</a> */}
                                    <Link to="login">Login</Link>
                                </li>
                            </ul>
                        </div>
                        {/* menu */}
                        <div className="sidebar-social align-items-center justify-content-center">
                            <h5 className="social-title">Follow Us On</h5>
                            <ul>
                                <li>
                                    <a href="javascript:void(0)"><i className="lni lni-facebook-filled" /></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i className="lni lni-twitter-original" /></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i className="lni lni-linkedin-original" /></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i className="lni lni-youtube" /></a>
                                </li>
                            </ul>
                        </div>
                        {/* sidebar social */}
                    </div>
                    {/* content */}
                </div>
                <div className={`overlay-left ${isOpen?"open":""}`} />
                {/*====== SIDEBAR PART ENDS ======*/}
                {/* Start header Area */}
                <section id="hero-area" className="header-area header-eight">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12 col-12">
                                <div className="header-content">
                                    <h1>Simplify Your Bookkeeping with DAYBOOK</h1>
                                    <p>
                                        Effortlessly Track Purchases, Sales, Fuel and Expenses in One Place
                                    </p>
                                    <div className="button">
                                        <a href="javascript:void(0)" className="btn primary-btn">Get Started</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-12">
                                <div className="header-image">
                                    <img src="/newassets/images/header/hero-image.jpg" alt="#" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End header Area */}
                {/*====== ABOUT FIVE PART START ======*/}
                <section id="about" className="about-area about-five">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-12">
                                <div className="about-image-five">
                                    <svg className="shape" width={106} height={134} viewBox="0 0 106 134" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="1.66654" cy="1.66679" r="1.66667" fill="#DADADA" />
                                        <circle cx="1.66654" cy="16.3335" r="1.66667" fill="#DADADA" />
                                        <circle cx="1.66654" cy="31.0001" r="1.66667" fill="#DADADA" />
                                        <circle cx="1.66654" cy="45.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx="1.66654" cy="60.3335" r="1.66667" fill="#DADADA" />
                                        <circle cx="1.66654" cy="88.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx="1.66654" cy="117.667" r="1.66667" fill="#DADADA" />
                                        <circle cx="1.66654" cy="74.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx="1.66654" cy={103} r="1.66667" fill="#DADADA" />
                                        <circle cx="1.66654" cy={132} r="1.66667" fill="#DADADA" />
                                        <circle cx="16.3333" cy="1.66679" r="1.66667" fill="#DADADA" />
                                        <circle cx="16.3333" cy="16.3335" r="1.66667" fill="#DADADA" />
                                        <circle cx="16.3333" cy="31.0001" r="1.66667" fill="#DADADA" />
                                        <circle cx="16.3333" cy="45.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx="16.333" cy="60.3335" r="1.66667" fill="#DADADA" />
                                        <circle cx="16.333" cy="88.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx="16.333" cy="117.667" r="1.66667" fill="#DADADA" />
                                        <circle cx="16.333" cy="74.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx="16.333" cy={103} r="1.66667" fill="#DADADA" />
                                        <circle cx="16.333" cy={132} r="1.66667" fill="#DADADA" />
                                        <circle cx="30.9998" cy="1.66679" r="1.66667" fill="#DADADA" />
                                        <circle cx="74.6665" cy="1.66679" r="1.66667" fill="#DADADA" />
                                        <circle cx="30.9998" cy="16.3335" r="1.66667" fill="#DADADA" />
                                        <circle cx="74.6665" cy="16.3335" r="1.66667" fill="#DADADA" />
                                        <circle cx="30.9998" cy="31.0001" r="1.66667" fill="#DADADA" />
                                        <circle cx="74.6665" cy="31.0001" r="1.66667" fill="#DADADA" />
                                        <circle cx="30.9998" cy="45.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx="74.6665" cy="45.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx={31} cy="60.3335" r="1.66667" fill="#DADADA" />
                                        <circle cx="74.6668" cy="60.3335" r="1.66667" fill="#DADADA" />
                                        <circle cx={31} cy="88.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx="74.6668" cy="88.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx={31} cy="117.667" r="1.66667" fill="#DADADA" />
                                        <circle cx="74.6668" cy="117.667" r="1.66667" fill="#DADADA" />
                                        <circle cx={31} cy="74.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx="74.6668" cy="74.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx={31} cy={103} r="1.66667" fill="#DADADA" />
                                        <circle cx="74.6668" cy={103} r="1.66667" fill="#DADADA" />
                                        <circle cx={31} cy={132} r="1.66667" fill="#DADADA" />
                                        <circle cx="74.6668" cy={132} r="1.66667" fill="#DADADA" />
                                        <circle cx="45.6665" cy="1.66679" r="1.66667" fill="#DADADA" />
                                        <circle cx="89.3333" cy="1.66679" r="1.66667" fill="#DADADA" />
                                        <circle cx="45.6665" cy="16.3335" r="1.66667" fill="#DADADA" />
                                        <circle cx="89.3333" cy="16.3335" r="1.66667" fill="#DADADA" />
                                        <circle cx="45.6665" cy="31.0001" r="1.66667" fill="#DADADA" />
                                        <circle cx="89.3333" cy="31.0001" r="1.66667" fill="#DADADA" />
                                        <circle cx="45.6665" cy="45.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx="89.3333" cy="45.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx="45.6665" cy="60.3335" r="1.66667" fill="#DADADA" />
                                        <circle cx="89.3333" cy="60.3335" r="1.66667" fill="#DADADA" />
                                        <circle cx="45.6665" cy="88.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx="89.3333" cy="88.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx="45.6665" cy="117.667" r="1.66667" fill="#DADADA" />
                                        <circle cx="89.3333" cy="117.667" r="1.66667" fill="#DADADA" />
                                        <circle cx="45.6665" cy="74.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx="89.3333" cy="74.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx="45.6665" cy={103} r="1.66667" fill="#DADADA" />
                                        <circle cx="89.3333" cy={103} r="1.66667" fill="#DADADA" />
                                        <circle cx="45.6665" cy={132} r="1.66667" fill="#DADADA" />
                                        <circle cx="89.3333" cy={132} r="1.66667" fill="#DADADA" />
                                        <circle cx="60.3333" cy="1.66679" r="1.66667" fill="#DADADA" />
                                        <circle cx={104} cy="1.66679" r="1.66667" fill="#DADADA" />
                                        <circle cx="60.3333" cy="16.3335" r="1.66667" fill="#DADADA" />
                                        <circle cx={104} cy="16.3335" r="1.66667" fill="#DADADA" />
                                        <circle cx="60.3333" cy="31.0001" r="1.66667" fill="#DADADA" />
                                        <circle cx={104} cy="31.0001" r="1.66667" fill="#DADADA" />
                                        <circle cx="60.3333" cy="45.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx={104} cy="45.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx="60.333" cy="60.3335" r="1.66667" fill="#DADADA" />
                                        <circle cx={104} cy="60.3335" r="1.66667" fill="#DADADA" />
                                        <circle cx="60.333" cy="88.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx={104} cy="88.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx="60.333" cy="117.667" r="1.66667" fill="#DADADA" />
                                        <circle cx={104} cy="117.667" r="1.66667" fill="#DADADA" />
                                        <circle cx="60.333" cy="74.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx={104} cy="74.6668" r="1.66667" fill="#DADADA" />
                                        <circle cx="60.333" cy={103} r="1.66667" fill="#DADADA" />
                                        <circle cx={104} cy={103} r="1.66667" fill="#DADADA" />
                                        <circle cx="60.333" cy={132} r="1.66667" fill="#DADADA" />
                                        <circle cx={104} cy={132} r="1.66667" fill="#DADADA" />
                                    </svg>
                                    <img src="/newassets/images/about/about-img1.jpg" alt="about" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="about-five-content">
                                    <h6 className="small-title text-lg">OUR STORY</h6>
                                    <h2 className="main-title fw-bold">Your Trusted Partner in Seamless Bookkeeping</h2>
                                    <div className="about-five-tab">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button className="nav-link active" id="nav-who-tab" data-bs-toggle="tab" data-bs-target="#nav-who" type="button" role="tab" aria-controls="nav-who" aria-selected="true">Who We Are</button>
                                                <button className="nav-link" id="nav-vision-tab" data-bs-toggle="tab" data-bs-target="#nav-vision" type="button" role="tab" aria-controls="nav-vision" aria-selected="false">our Vision</button>
                                                <button className="nav-link" id="nav-history-tab" data-bs-toggle="tab" data-bs-target="#nav-history" type="button" role="tab" aria-controls="nav-history" aria-selected="false">our History</button>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className="tab-pane fade show active" id="nav-who" role="tabpanel" aria-labelledby="nav-who-tab">
                                                <p>At DAYBOOK, we are dedicated to making your day-to-day bookkeeping effortless and efficient. Our comprehensive software solution is designed to streamline the management of purchases, sales, fuel, and expenses, ensuring that you have more time to focus on growing your business. With a user-friendly interface and powerful features, DAYBOOK is your reliable partner in maintaining accurate financial records and achieving operational excellence. </p>
                                            </div>
                                            <div className="tab-pane fade" id="nav-vision" role="tabpanel" aria-labelledby="nav-vision-tab">
                                                <p>At DAYBOOK, our vision is to revolutionize bookkeeping by providing innovative, user-friendly software solutions that empower businesses to manage their finances with ease and precision. We aim to be the leading platform for day-to-day financial management, enabling businesses of all sizes to thrive through efficient and accurate bookkeeping practices. </p>
                                            </div>
                                            <div className="tab-pane fade" id="nav-history" role="tabpanel" aria-labelledby="nav-history-tab">
                                                <p>Founded with a passion for simplifying financial management, DAYBOOK started its journey to create an intuitive bookkeeping solution that addresses the everyday challenges faced by businesses. Over the years, we have grown from a small startup into a trusted partner for businesses across various industries. Our relentless pursuit of excellence and innovation has driven us to develop a platform that not only meets but exceeds the expectations of our users. DAYBOOK is dedicated to helping our clients achieve financial clarity and success.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* container */}
                </section>
                {/*====== ABOUT FIVE PART ENDS ======*/}
                {/* ===== service-area start ===== */}
                <section id="services" className="services-area services-eight">
                    {/*======  Start Section Title Five ======*/}
                    <div className="section-title-five">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="content">
                                        <h2 className="fw-bold">Our Services</h2>
                                        <p>
                                            Comprehensive Bookkeeping Tailored for Your Business Needs
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* row */}
                        </div>
                        {/* container */}
                    </div>
                    {/*======  End Section Title Five ======*/}
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-services">
                                    <div className="service-icon">
                                        <i className="lni lni-capsule" />
                                    </div>
                                    <div className="service-content">
                                        <h4>User Friendly</h4>
                                        <p>
                                            Lorem ipsum dolor sit amet, adipscing elitr, sed diam nonumy
                                            eirmod tempor ividunt labor dolore magna.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-services">
                                    <div className="service-icon">
                                        <i className="lni lni-bootstrap" />
                                    </div>
                                    <div className="service-content">
                                        <h4>Daily Sales &amp; Purchases</h4>
                                        <p>
                                            Lorem ipsum dolor sit amet, adipscing elitr, sed diam nonumy
                                            eirmod tempor ividunt labor dolore magna.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-services">
                                    <div className="service-icon">
                                        <i className="lni lni-shortcode" />
                                    </div>
                                    <div className="service-content">
                                        <h4>Fuel</h4>
                                        <p>
                                            Lorem ipsum dolor sit amet, adipscing elitr, sed diam nonumy
                                            eirmod tempor ividunt labor dolore magna.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-services">
                                    <div className="service-icon">
                                        <i className="lni lni-dashboard" />
                                    </div>
                                    <div className="service-content">
                                        <h4>Payments</h4>
                                        <p>
                                            Lorem ipsum dolor sit amet, adipscing elitr, sed diam nonumy
                                            eirmod tempor ividunt labor dolore magna.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-services">
                                    <div className="service-icon">
                                        <i className="lni lni-layers" />
                                    </div>
                                    <div className="service-content">
                                        <h4>Reports</h4>
                                        <p>
                                            Lorem ipsum dolor sit amet, adipscing elitr, sed diam nonumy
                                            eirmod tempor ividunt labor dolore magna.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-services">
                                    <div className="service-icon">
                                        <i className="lni lni-reload" />
                                    </div>
                                    <div className="service-content">
                                        <h4>Store Manager Login</h4>
                                        <p>
                                            Lorem ipsum dolor sit amet, adipscing elitr, sed diam nonumy
                                            eirmod tempor ividunt labor dolore magna.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ===== service-area end ===== */}
                {/* Start Pricing  Area */}
                <section id="pricing" className="pricing-area pricing-fourteen">
                    {/*======  Start Section Title Five ======*/}
                    <div className="section-title-five">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="content">
                                        <h2 className="fw-bold">Pricing &amp; Plans</h2>
                                        <p>
                                            Flexible Options to Fit Your Business Budget </p>
                                    </div>
                                </div>
                            </div>
                            {/* row */}
                        </div>
                        {/* container */}
                    </div>
                    {/*======  End Section Title Five ======*/}
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="pricing-style-fourteen">
                                    <div className="table-head">
                                        <h6 className="title">Starter</h6>
                                        <p>Lorem Ipsum is simply dummy text of the printing and industry.</p>
                                        <div className="price">
                                            <h2 className="amount">
                                                <span className="currency">$</span>0<span className="duration">/mo </span>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="light-rounded-buttons">
                                        <a href="javascript:void(0)" className="btn primary-btn-outline">
                                            Start free trial
                                        </a>
                                    </div>
                                    <div className="table-content">
                                        <ul className="table-list">
                                            <li> <i className="lni lni-checkmark-circle" /> Cras justo odio.</li>
                                            <li> <i className="lni lni-checkmark-circle" /> Dapibus ac facilisis in.</li>
                                            <li> <i className="lni lni-checkmark-circle deactive" /> Morbi leo risus.</li>
                                            <li> <i className="lni lni-checkmark-circle deactive" /> Excepteur sint occaecat velit.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="pricing-style-fourteen middle">
                                    <div className="table-head">
                                        <h6 className="title">Exclusive</h6>
                                        <p>Lorem Ipsum is simply dummy text of the printing and industry.</p>
                                        <div className="price">
                                            <h2 className="amount">
                                                <span className="currency">$</span>99<span className="duration">/mo </span>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="light-rounded-buttons">
                                        <a href="javascript:void(0)" className="btn primary-btn">
                                            Start free trial
                                        </a>
                                    </div>
                                    <div className="table-content">
                                        <ul className="table-list">
                                            <li> <i className="lni lni-checkmark-circle" /> Cras justo odio.</li>
                                            <li> <i className="lni lni-checkmark-circle" /> Dapibus ac facilisis in.</li>
                                            <li> <i className="lni lni-checkmark-circle" /> Morbi leo risus.</li>
                                            <li> <i className="lni lni-checkmark-circle deactive" /> Excepteur sint occaecat velit.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="pricing-style-fourteen">
                                    <div className="table-head">
                                        <h6 className="title">Premium</h6>
                                        <p>Lorem Ipsum is simply dummy text of the printing and industry.</p>
                                        <div className="price">
                                            <h2 className="amount">
                                                <span className="currency">$</span>150<span className="duration">/mo </span>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="light-rounded-buttons">
                                        <a href="javascript:void(0)" className="btn primary-btn-outline">
                                            Start free trial
                                        </a>
                                    </div>
                                    <div className="table-content">
                                        <ul className="table-list">
                                            <li> <i className="lni lni-checkmark-circle" /> Cras justo odio.</li>
                                            <li> <i className="lni lni-checkmark-circle" /> Dapibus ac facilisis in.</li>
                                            <li> <i className="lni lni-checkmark-circle" /> Morbi leo risus.</li>
                                            <li> <i className="lni lni-checkmark-circle" /> Excepteur sint occaecat velit.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*/ End Pricing  Area */}
                {/* Start Cta Area */}
                <section id="call-action" className="call-action">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-9">
                                <div className="inner-content">
                                    <h2>Simplify Your Daily Bookkeeping Tasks          </h2>
                                    <p>
                                        Experience the simplicity and efficiency of DAYBOOK. Our intuitive platform is designed to help you effortlessly manage your daily financial activities, giving you more time to focus on what matters most growing your business.
                                    </p>
                                    <div className="light-rounded-buttons">
                                        <a href="javascript:void(0)" className="btn primary-btn-outline">Get Started</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Cta Area */}
                {/* ========================= contact-section start ========================= */}
                <section id="contact" className="contact-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4">
                                <div className="contact-item-wrapper">
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-xl-12">
                                            <div className="contact-item">
                                                <div className="contact-icon">
                                                    <i className="lni lni-phone" />
                                                </div>
                                                <div className="contact-content">
                                                    <h4>Contact</h4>
                                                    <p>0984537278623</p>
                                                    <p>yourmail@gmail.com</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-12">
                                            <div className="contact-item">
                                                <div className="contact-icon">
                                                    <i className="lni lni-map-marker" />
                                                </div>
                                                <div className="contact-content">
                                                    <h4>Address</h4>
                                                    <p>175 5th Ave, New York, NY 10010</p>
                                                    <p>United States</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-12">
                                            <div className="contact-item">
                                                <div className="contact-icon">
                                                    <i className="lni lni-alarm-clock" />
                                                </div>
                                                <div className="contact-content">
                                                    <h4>Schedule</h4>
                                                    <p>24 Hours / 7 Days Open</p>
                                                    <p>Office time: 10 AM - 5:30 PM</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8">
                                <div className="contact-form-wrapper">
                                    <div className="row">
                                        <div className="col-xl-10 col-lg-8 mx-auto">
                                            <div className="section-title text-center">
                                                <h2>
                                                    Contact Us
                                                </h2>
                                                <p>
                                                    We're Here to Help - Get in Touch with Us Today</p>
                                            </div>
                                        </div>
                                    </div>
                                    <form action="#" className="contact-form">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input type="text" name="name" id="name" placeholder="Name" required />
                                            </div>
                                            <div className="col-md-6">
                                                <input type="email" name="email" id="email" placeholder="Email" required />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input type="text" name="phone" id="phone" placeholder="Phone" required />
                                            </div>
                                            <div className="col-md-6">
                                                <input type="text" name="subject" id="email" placeholder="Subject" required />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea name="message" id="message" placeholder="Type Message" rows={5} defaultValue={""} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="button text-center rounded-buttons">
                                                    <button type="submit" className="btn primary-btn rounded-full">
                                                        Send Message
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ========================= contact-section end ========================= */}
                {/* ========================= map-section end ========================= */}
                <section className="map-section map-style-9">
                    <div className="map-container">
                        <object style={{ border: 0, height: 500, width: '100%' }} data="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3102.7887109309127!2d-77.44196278417968!3d38.95165507956235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzjCsDU3JzA2LjAiTiA3N8KwMjYnMjMuMiJX!5e0!3m2!1sen!2sbd!4v1545420879707" />
                    </div>
                </section>
                {/* ========================= map-section end ========================= */}
                {/* Start Footer Area */}
                <footer className="footer-area footer-eleven">
                    {/* Start Footer Top */}
                    <div className="footer-top">
                        <div className="container">
                            <div className="inner-content">
                                <div className="row">
                                    <div className="footer-widget f-about">
                                        <div className="logo">
                                            <a href="index.html">
                                                <img src="/newassets/images/logo.svg" alt="#" className="img-fluid" />
                                            </a>
                                        </div>
                                        <p>Efficient Bookkeeping for Modern Businesses
                                        </p>
                                        <hr />
                                        <p className="copyright-text">
                                            <span>© DayBook. All rights reserved. </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <a href="#" className="scroll-top btn-hover">
                    <i className="lni lni-chevron-up" />
                </a>
            </div>



        </>
    )
}

export default Website
