import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
import { CategoryService } from '../../../services/user/category.services';
import { useSelector } from 'react-redux';
// import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from '../../sales-info/notifications/toaster_notify.component';
import CommonCategoryForm from './common-categoryform.component';
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from '../../common/notification/toaster_notify.component';


const AddCategoryMasterComponent = ({setNewCategoryId}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const categoryId = location.state?.categoryId || 0;
    const pageNumber = location.state?.pageNumber || 0;
    const isEditMode = !!categoryId;
    const selectedStore = useSelector(state => state?.user?.selectedStoreId)

    const formik = useFormik({
        initialValues: {
            categoryId: categoryId ?? 0,
            categoryName: '',
            storeId: selectedStore,
        },

        validationSchema: Yup.object({
            categoryName: Yup.string().required('Category Name is required'),
        }),

        onSubmit: async (values) => {
            try {
                if (isEditMode) {
                    await CategoryService.updateCategoryById(categoryId, values);
                    handleNotify('Category updated successfully!', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
                } else {
                    await CategoryService.addNewCategory(values)
                    handleNotify('Category added successfully!', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
                }
                navigate('/accountsetup/categorymaster', { state: { pageNumber: pageNumber } });
            } catch (error) {
               // console.error('Error in submission:', error);
                handleNotify('A record with this field value already exists', TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            }
        }

    });

    useEffect(() => {
        if (isEditMode) {
            const fetchCategoryDetail = async () => {
                try {
                    const data = await CategoryService.getCategoryById(categoryId);
                    if (data) {
                        formik.setValues({
                            categoryId: data?.categoryId,
                            categoryName: data?.categoryName,
                            storeId: data?.selectedStore,
                        })
                    }
                }
                catch (error) {
                    console.log(error);
                }
            }
            fetchCategoryDetail();
        }
    }, []);

    const handleCancel = () => {
        // navigate('/accountsetup/categorymaster')
        navigate('/accountsetup/categorymaster', { state: { pageNumber: pageNumber } });
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Category Master</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item"></li>
                                    <li className="breadcrumb-item active" aria-current="page">{isEditMode ? 'Edit Category' : 'Add Category'}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                     <div className="page-breadcrumb d-xl-none d-xl-block align-items-center mb-3">
                        <div className="breadcrumb-item pe-3 ">{isEditMode ? 'Edit Category' : 'Add Category'}</div>
                    </div> 
                    <hr />
        
                     <div className="col-md-12 mt-2 px-4 px-4 me-3">
                        <CommonCategoryForm
                            formik={formik}
                            saveButtonText={isEditMode ? 'Update' : 'Save'}
                            onCancel={handleCancel}
                            setNewCategoryId={setNewCategoryId}
                        />
                    </div>
                </div>
            </div >
        </>
    );
};

export default AddCategoryMasterComponent;
