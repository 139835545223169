import React, { Fragment, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getLeftMenuAccess, getLocalStorageItem } from '../../utility'
import { useNavigate } from 'react-router'
import { logoutUser } from '../../redux/user/userslice'

const AuthGuard = ({ children, page }) => {
    const user = useSelector(({ user }) => user, shallowEqual)
    const navigate = useNavigate();
    const dispatch=useDispatch();
    const storeId = user.selectedStoreId
    const stores = user.stores
    const selectedStore = stores?.find(store => store.storeId === storeId)
    const leftMenuAccess = getLeftMenuAccess(selectedStore)
    const [isAccess, setisAccess] = useState(false)
    const [isAuthenticated, setisAuthenticated] = useState(false)


    useEffect(() => {
        const isExpireRefresh=getLocalStorageItem("isRefreshExp")
        if (isExpireRefresh) {
            dispatch(logoutUser());
            localStorage.clear();
            navigate('/login')
        }

        if (user.id !== "" && user.id !== undefined) {
            if(stores.length<=0 || stores==undefined){
                navigate('/createstore');
            }
            setisAuthenticated(true)
            setisAccess(true)
        } else {
            navigate('/login');
        }
        if (leftMenuAccess[page] === true && page) {
            setisAccess(leftMenuAccess[page])
            
        }
        if (leftMenuAccess[page] === false) {
            navigate('/404');
        }
    }, [])

    return (
        <Fragment>
            {(isAccess && isAuthenticated) && children}
        </Fragment>
    )
}

export default AuthGuard;
