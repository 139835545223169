import { API_RESPONSE_STATUS, ResponseModel, handleGetAsync, handlePostAsync, handlePutAsync, handleDeleteAsync } from "../../components/common/commonclass";
import { EndPoint } from "../../components/common/config";
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from "../../components/common/notification/toaster_notify.component";

let responseModal = new ResponseModel();

export class CategoryService{
    static async getAllCategory(storeId, searchText = ""){
        responseModal = new ResponseModel();
        const requestURL = EndPoint.BIND_CATEGORY;

        const requestModel = {
            storeId: storeId,
            searchText: searchText
        };

        responseModal = await handleGetAsync(requestURL, true, requestModel);
        if(responseModal && responseModal.status === API_RESPONSE_STATUS.SUCCESS){
            return responseModal.data;
        }
        else{
            console.error('Failed to Fetch Category');
            return [];
        }
    }

    //add new category
    static async addNewCategory(data) {
        responseModal = new ResponseModel();
        const requestURL = EndPoint.CATEGORY; // endpoint

        responseModal = await handlePostAsync(requestURL, true, data);

        if (responseModal && responseModal.status === API_RESPONSE_STATUS.SUCCESS) {
            return responseModal.data;
        } else {
            throw new Error('Failed to Category.');
        }
    }

    // Get by ID
    static async getCategoryById(id) {
        ;
        responseModal = new ResponseModel();
        const requestURL = `${EndPoint.CATEGORY}/${id}`; // endpoint

        responseModal = await handleGetAsync(requestURL, true);

        if (responseModal && responseModal.status === API_RESPONSE_STATUS.SUCCESS) {
            return responseModal.data;
        } else {
            throw new Error('fail to fetch category');
        }
    }

    // Update 
    static async updateCategoryById(id, data) {
        responseModal = new ResponseModel();
        const requestURL = `${EndPoint.CATEGORY}/${id}`; // endpoint

        responseModal = await handlePutAsync(requestURL, true, data);

        if (responseModal && responseModal.status === API_RESPONSE_STATUS.SUCCESS) {
            return responseModal.data;
        } else {
            throw new Error('Failed to update Category');
        }
    }


    //  // Delete
     static async deleteCategory(id) {
        responseModal = new ResponseModel();
        const requestURL = `${EndPoint.CATEGORY}/${id}`; // endpoint

        responseModal = await handleDeleteAsync(requestURL, true, id);

        if (responseModal && responseModal.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify('Category deleted successfully.', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);

            return responseModal.data;
        } else {
            console.error('Failed to delete Category.');
        }
    }

     // Delete many
     static async deleteManyCategory(ids) {
        responseModal = new ResponseModel();
        const requestURL = `${EndPoint.CATEGORY}/deletemany`; // endpoint

        responseModal = await handleDeleteAsync(requestURL, true, ids);

        if (responseModal && responseModal.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify(responseModal.message ,TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT)
            return responseModal.data;
        } else {
            handleNotify('Error while deleting selected categories' ,TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT)
            console.error('Failed to delete many Category.');
        }
    }
    
}