import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { handleNotify, TOASTER_TYPE, TOASTER_POSITION } from "../../components/common/notification/toaster_notify.component";
import { addUser } from "../../redux/user/userslice";
import { UserService } from "../../services/user/user.services";
import { API_RESPONSE_STATUS } from "../../components/common/commonclass";
import { setLocalStorageItem, setToken } from "../../utility";
import { LeftMenuKeys } from "../../components/common/constantkey";

const LoginComponent = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user, shallowEqual)
  const [load, setLoad] = useState(false)


  useEffect(() => {
    if (user?.id !== undefined && user?.id !== null && user?.id !== "") {
      navigate('/dailywizards')
    } else {
      setLoad(true)
    }
  }, [])
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      rememberMe: false,
    },

    validationSchema: Yup.object({
      username: Yup.string().required("Username is required"),
      password: Yup.string()
        .min(4, "Password should be of minimum 4 characters length")
        .required("Password is required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await UserService.login(values.username, values.password);
        if (response) {
          if (Object.keys(response.data).length === 0) {
            setErrorMessage(response.message);
            // handleNotify(response.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            handleNotify("User is not valid", TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
          } else if (response.isSucess === false && response.errormessage && response.status === API_RESPONSE_STATUS.UNAUTHORIZED) {
            setErrorMessage(response.errormessage);
            handleNotify(response.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
          } else if (response.isSucess === true && response.message) {
            handleNotify('Login successfully!', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);

            // Navigate to dailywizards and set menu state
            setLocalStorageItem("isRefreshExp", false)
            dispatch(addUser(response.data));
            setToken(response?.data?.accessToken, response?.data?.refreshToken)
            if (response.data?.stores?.length > 0) {
              localStorage.setItem('selectedChildMenu', LeftMenuKeys.Wizard);
              navigate("/dailywizards");
            } else {
              localStorage.setItem('selectedMenu', LeftMenuKeys.AccountSetup);
              localStorage.setItem('selectedChildMenu', LeftMenuKeys.StoreMaster);
              navigate('/createstore');
            }
          }
        }
      } catch (error) {
        console.error('Error:', error);
        handleNotify('An unexpected error occurred. Please try again.', TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
      }
    },
  });

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleRegisterClick = () => {
    navigate('/register');
  };
  
  return (
    <>
      {load &&
        <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0 mh-100">
          <div className="container">
            <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
              <div className="col mx-auto">
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="p-4">
                      <div className="d-flex align-items-center justify-content-center my-4">
                        <h3 className="mb-0 logo-text" style={{ fontSize: "2.5rem" }}>
                          {/* <img src="/assets/DB2.png" alt="Logo" className="logo" style={{ width: '200px' }} /> */}
                          {/* <Logo />  */}
                          <img src="/assets/white-logo.svg" alt="Logo" style={{ width: '200px', color: '#378805', filter: 'invert(50%) sepia(90%) hue-rotate(90deg) saturate(400%) brightness(70%)' }} />
                        </h3>
                      </div>
                      <div className="text-center mb-4">
                        <p className="mb-0">Please log in to your account</p>
                      </div>
                      <div className="form-body">
                        <form className="row g-3" onSubmit={formik.handleSubmit}>
                          <div className="col-12">
                            <label htmlFor="inputEmailAddress" className="form-label"> Username<span className="text-danger">*</span>: </label>
                            <input
                              type="text"
                              name="username"
                              className={`form-control ${formik.touched.username && formik.errors.username ? "is-invalid" : ""
                                }`}
                              id="inputEmailAddress"
                              placeholder="Username"
                              value={formik.values.username}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.username && formik.errors.username ? (
                              <div className="invalid-feedback">{formik.errors.username}</div>
                            ) : null}
                          </div>
                          <div className="col-12">
                            <label htmlFor="inputChoosePassword" className="form-label"> Password <span className="text-danger">*</span>: </label>
                            <div className="input-group" id="show_hide_password">
                              <input
                                type={showPassword ? "text" : "password"}
                                className={`form-control ${formik.touched.password && formik.errors.password ? "is-invalid" : ""
                                  }`}
                                id="inputChoosePassword"
                                name="password"
                                placeholder="Enter Password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              <button type="button" className="input-group-text bg-transparent" onClick={togglePasswordVisibility} >
                                <i className={`bx ${showPassword ? "bx-hide" : "bx-show"}`} />
                              </button>
                              {formik.touched.password && formik.errors.password ? (
                                <div className="invalid-feedback">{formik.errors.password}</div>
                              ) : null}
                            </div>
                          </div>
                          {errorMessage !== "" && (
                            <div className="col-12">
                              <div className="d-grid text-center">
                                <span className="text-danger">{errorMessage}</span>
                              </div>
                            </div>
                          )}
                          <div className="col-12">
                            <div className="text-center">
                              <button type="submit" className="btn btn-primary"> Sign in </button>

                              <button type="button" className="ms-3 btn btn-primary" onClick={handleRegisterClick} > Register </button>
                            </div>
                          </div>
                          {/* <div className="col-12">
                            <div className="d-grid text-center">
                              <button type="button" className="btn btn-primary" onClick={handleRegisterClick} > Register </button>
                            </div>
                          </div> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

export default LoginComponent;
