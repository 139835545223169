import { API_RESPONSE_STATUS, handleDeleteAsync, handleGetAsync, handlePostAsync, handlePutAsync, ResponseModel } from "../../components/common/commonclass";
import { EndPoint } from "../../components/common/config";
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from "../../components/common/notification/toaster_notify.component";


var responseModel = new ResponseModel();
export class FuelDeliveryService {
    static async getFuelDeliveries(storeId, searchText = "") {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.FETCH_FUEL_DELIVERY}`;
        const requestModel = { storeId, searchText };
        try {
            responseModel = await handleGetAsync(requestURL, true, requestModel);
            if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
                return responseModel.data;
            } else {
                throw new Error('Failed to fetch fueldelivery data.');
            }
        } catch (error) {
            console.error('Error fetching fueldelivery data:', error);
            throw error;
        }
    }

    // create
    static async createFuelDelivery(data) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.FUEL_DELIVERY}`; //endpoint

        responseModel = await handlePostAsync(requestURL, true, data);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify('Fuel Delivery data added successfully!', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if (responseModel && responseModel.status === API_RESPONSE_STATUS.FAIL) {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else {
            throw new Error('Failed to create new Fuel Delivery data.');
        }
    }

    //update
    static async updateFuelDelivery(id, data) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.FUEL_DELIVERY}/${id}`;

        responseModel = await handlePutAsync(requestURL, true, data);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify('Fuel Delivery data updated successfully.', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if (responseModel.status === API_RESPONSE_STATUS.FAIL) {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else {
            throw new Error('Failed to update Fuel Delivery data.');
        }
    }

    //delete
    static async deleteFuelDelivery(id) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.FUEL_DELIVERY}/${id}`; // endpoint
        try {

            responseModel = await handleDeleteAsync(requestURL, true);
            if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
                return responseModel.data;
            } else {
                throw new Error('Failed to delete fueldelivery data.');
            }
        } catch (error) {
            console.error('Error deleting fueldelivery data:', error);
            throw error;
        }
    }

    //delete many
    static async deleteManyFuelDelivery(ids) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.FUEL_DELIVERY}/deletemany`; // endpoint

        responseModel = await handleDeleteAsync(requestURL, true, ids);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify(responseModel.message, TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel.data;
        } else {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
        }
    }
}