import React, { useState, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteModelPopupComponent from "../common-model-popup/deleteModelPopup.component";
import { StoreService } from "../../../services/user/store.services";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { API_RESPONSE_STATUS } from "../../common/commonclass";
import { addStore, selectStore } from "../../../redux/user/userslice";
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from "../../common/notification/toaster_notify.component";

const StoreMasterComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [filterText, setFilterText] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState('');
    const [data, setData] = useState([]);
    const userdetail = useSelector((state) => state.user);
    const selectedStore = useSelector(state => state?.user?.selectedStoreId);
    const [isRefreshGrid, setisRefreshGrid] = useState(false)
    const user = useSelector(({ user }) => user, shallowEqual)
    const dispatch = useDispatch();
    const pageNumber = location.state?.pageNumber || 1;
    const [currentPage, setCurrentPage] = useState(pageNumber);
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        StoreService.getstores().then(response => {
            if (Array.isArray(response)) {
                const data = response?.filter((row) => {
                    return row.userId === userdetail.id
                })
                setData(data);
            }
        });
    }, [selectedStore, isRefreshGrid]);

    const columns = [
        { width: '15%', name: 'Store Name', selector: row => row.storeName, sortable: true },
        { width: '15%', name: 'Store City', selector: row => row.storeCity, sortable: true },
        { width: '15%', name: 'Store State', selector: row => row.storeState, sortable: true },
        { width: '15%', name: 'Store Phone', selector: row => row.storePhone, sortable: true },
        { width: '15%', name: 'Store Manager', selector: row => row.storeManager, sortable: true },
        {
            width: '20%', name: 'Actions', cell: row => (
                <div className="d-flex">
                    <button type="button"
                        className="btn btn-primary me-2"
                        onClick={() => handleEditClick(row)} >
                        <span className="d-none d-md-block"> Edit</span>
                        <i className="d-md-none d-lg-none fadeIn animated bx bx-edit-alt"></i>
                    </button>

                    <button type="button"
                        className="btn btn-danger"
                        onClick={() => handleDeleteClick(row)}>
                        <span className="d-none d-md-block">Delete</span>
                        <i className="d-md-none d-lg-none fadeIn animated bx bx-trash-alt"></i>
                    </button>
                </div>
            ),
        }
    ];

    const handleAddNewStoreClick = () => {
        navigate('/storemaster/addstore', { state: { pageNumber: currentPage } })
    }

    const handleEditClick = (row) => {
        navigate('/storemaster/editstore', { state: { storeData: row, pageNumber: currentPage } });
    };

    const handleSearch = event => {
        setFilterText(event.target.value);
    };

    const handleDeleteClick = (item) => {
        setItemToDelete(item);
        setShowModal(true);
    };

    const handleDeleteSelectedRowClick = () => {
        if (selectedRows.length > 0) {
            setShowModal(true);
            setSelectedRows(selectedRows)
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            const idsToDelete = selectedRows.length > 0
                ? selectedRows.map(row => row.storeId.toString())
                : itemToDelete ? [itemToDelete.storeId.toString()] : [];

            if (idsToDelete.length > 0) {
                await StoreService.deleteManyStore(idsToDelete);

                setData(prevData => prevData.filter(item => !idsToDelete.includes(item.storeId.toString())));
                setSelectedRows([]);
                setItemToDelete(null);
            }
            setShowModal(false);
        } catch (error) {
            handleNotify(error.message || 'Error while deleting DailySales.', TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const filteredData = data.filter(item =>
        Object.keys(item).some(key =>
            item[key]?.toString().toLowerCase().includes(filterText.toLowerCase())
        )
    );

    const onChangePage = (pageIndex) => {
        setCurrentPage(pageIndex)
    };

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Account Setup</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item"></li>
                                    <li className="breadcrumb-item active" aria-current="page">Store Master</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="page-breadcrumb d-xl-none d-xl-block align-items-center mb-3">
                        <div className="breadcrumb-item pe-3 ">Account Setup &gt; Store Master</div>
                    </div>
                    <hr />
                    <div className="bg-white col-md-12 p-4">
                        <div className="d-md-flex d-grid align-items-center gap-3">
                            <div className="d-flex">
                                <button
                                    type="button"
                                    className="btn btn-primary px-4 mt-3 me-3"
                                    onClick={handleAddNewStoreClick}
                                >
                                    New
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger px-4 mt-3 ms-auto ms-md-0"
                                    onClick={handleDeleteSelectedRowClick}
                                >
                                    Delete
                                </button>
                            </div>
                            <div className="mt-3">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Search..."
                                    value={filterText}
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={filteredData}
                            pagination
                            subHeader
                            selectableRows
                            onChangePage={onChangePage}
                            onSelectedRowsChange={handleRowSelected}
                            paginationDefaultPage={currentPage}
                        />
                    </div>
                </div>
            </div>
            {showModal &&
                <DeleteModelPopupComponent
                    show={showModal}
                    onClose={handleModalClose}
                    onConfirm={handleDeleteConfirm}
                    title="Confirm"
                    confirmText="Delete"
                    Message="Are you sure want to delete selected record(s)?"
                />
            }
        </>
    );
};

export default StoreMasterComponent;
