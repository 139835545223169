import { API_RESPONSE_STATUS, ResponseModel, handleDeleteAsync, handleGetAsync, handlePostAsync, handlePutAsync } from "../../components/common/commonclass";
import { EndPoint } from "../../components/common/config";

var responseModel=new ResponseModel();

export class ReportService{
    static async generateReportDaily(model){
        const requestModel = model;
        responseModel=new ResponseModel();
        const requestURL=`${EndPoint.REPORT}/getreportdaily`;
        
        responseModel=await handleGetAsync(requestURL,true,requestModel);
        if(responseModel && (responseModel.status===API_RESPONSE_STATUS.SUCCESS)){
            return responseModel.data;
        }
    }

    static async generateReportMonthly(model){
        const requestModel = model;
        responseModel=new ResponseModel();
        const requestURL=`${EndPoint.REPORT}/getreportmonthly`;
        
        responseModel=await handleGetAsync(requestURL,true,requestModel);
    
        if(responseModel && (responseModel.status===API_RESPONSE_STATUS.SUCCESS)){
            return responseModel.data;
        }
    }

    static async generateReportYearly(model){
        const requestModel = model;
        responseModel=new ResponseModel();
        const requestURL=`${EndPoint.REPORT}/getreportyearly`;
        
        responseModel=await handleGetAsync(requestURL,true,requestModel);
    
        if(responseModel && (responseModel.status===API_RESPONSE_STATUS.SUCCESS)){
            return responseModel.data;
        }
    }

    //get store by id api
   
}
