import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { UserService } from '../../services/user/user.services';
import TOASTER_POSITION from '../../components/common/notification/toaster_positions.component';
import TOASTER_TYPE from '../../components/common/notification/toaster_types.component';
import { handleNotify } from '../../components/common/notification/toaster_notify.component';
import { useNavigate } from 'react-router';

const RegisterComponent = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            contactNo: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            country: '',
            userName: '',
            userPassword: '',
            confirmPassword: '',
        },

        validationSchema: Yup.object({

            firstName: Yup.string()
                .required('First Name is required'),
            lastName: Yup.string()
                .required('Last Name is required'),
            email: Yup.string()
                .email('Invalid email address')
                .required('Email is required'),
            contactNo: Yup.string()
                .required('Contact Number is required'),
            userName: Yup.string()
                .required('User Name is required'),
            userPassword: Yup.string()
                .min(6, 'Password should be of minimum 6 characters length')
                .required('Password is required'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('userPassword'), null], 'Passwords must match')
                .required('Confirm Password is required')
        }),
        onSubmit: async (values) => {
            // debugger
            try {
                await UserService.createUser(values).then((response) => {
                    if (response && response.isSucess) {
                        // handleNotify(response.message, TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
                        navigate('/login')
                    }
                    //if (response && !response.isSucess){
                    // handleNotify(response.message, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
                    //}
                })

            }
            catch (error) {
                console.error('Failed to create Register data', error);
            }
        },
    })

    const handleLogin = () => {
        navigate('/login')
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <div className="d-flex justify-content-center align-items-center m-4">
            <div className="row">
                <div className="col-xl-6 mx-auto">
                    <div className="card">
                        <div className="card-header p-4">
                            <h5 className="mb-0">Create A Free Account</h5>
                        </div>
                        <div className="card-body p-4">
                            <form className="row g-3 needs-validation" onSubmit={formik.handleSubmit} noValidate>
                                <div className="col-md-6">
                                    <label htmlFor="bsValidation1" className="form-label">First Name:<span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className={`form-control ${formik.touched.firstName && formik.errors.firstName ? 'is-invalid' : ''}`}
                                        id="bsValidation1"
                                        name="firstName"
                                        tabIndex="1"
                                        autoComplete="off"
                                        placeholder="First Name"
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} required
                                    />
                                    {formik.touched.firstName && formik.errors.firstName ? (
                                        <div className='text-danger'>{formik.errors.firstName}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="bsValidation2" className="form-label">Last Name:<span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className={`form-control ${formik.touched.lastName && formik.errors.lastName ? 'is-invalid' : ''}`}
                                        id="bsValidation2"
                                        name="lastName"
                                        placeholder="Last Name"
                                        tabIndex="2"
                                        autoComplete="off"
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} required
                                    />
                                    {formik.touched.lastName && formik.errors.lastName ? (
                                        <div className='text-danger'>{formik.errors.lastName}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="bsValidationEmail" className="form-label">Email:<span className="text-danger">*</span></label>
                                    <input
                                        type="email"
                                        className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                                        id="bsValidationEmail"
                                        name="email"
                                        tabIndex="2"
                                        autoComplete="off"
                                        placeholder="Email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} required
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className='text-danger'>{formik.errors.email}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="bsValidationPhone" className="form-label">Contact Number:<span className="text-danger">*</span></label>
                                    <input
                                        type="tel"
                                        className={`form-control ${formik.touched.contactNo && formik.errors.contactNo ? 'is-invalid' : ''}`}
                                        id="bsValidationPhone"
                                        name="contactNo"
                                        placeholder="Contact Number"
                                        tabIndex="3"
                                        autoComplete="off"
                                        value={formik.values.contactNo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} required
                                    />
                                    {formik.touched.contactNo && formik.errors.contactNo ? (
                                        <div className='text-danger'>{formik.errors.contactNo}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="bsValidationAddress1" className="form-label">Address 1</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="bsValidationAddress1"
                                        name="address1"
                                        placeholder="Address 1"
                                        tabIndex="4"
                                        autoComplete="off"
                                        value={formik.values.address1}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} required
                                    />
                                    <div className="valid-feedback">
                                        Please enter a valid address.
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="bsValidationAddress2" className="form-label">Address 2</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="bsValidationAddress2"
                                        name="address2"
                                        placeholder="Address 2"
                                        tabIndex="5"
                                        autoComplete="off"
                                        value={formik.values.address2}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} required
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="bsValidationCity" className="form-label">City</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="bsValidationCity"
                                        name="city"
                                        placeholder="City"
                                        tabIndex="6"
                                        autoComplete="off"
                                        value={formik.values.city}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} required
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="bsValidationState" className="form-label">State</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="bsValidationState"
                                        name="state"
                                        placeholder="State"
                                        tabIndex="7"
                                        autoComplete="off"
                                        value={formik.values.state}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} required
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="bsValidationCountry" className="form-label">Country</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="bsValidationCountry"
                                        name="country"
                                        placeholder="Country"
                                        tabIndex="8"
                                        autoComplete="off"
                                        value={formik.values.country}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} required
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="bsValidationUsername" className="form-label">User Name:<span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className={`form-control ${formik.touched.userName && formik.errors.userName ? 'is-invalid' : ''}`}
                                        id="bsValidationUsername"
                                        name="userName"
                                        placeholder="UserName"
                                        tabIndex="9"
                                        autoComplete="off"
                                        value={formik.values.userName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} required
                                    />
                                    {formik.touched.userName && formik.errors.userName ? (
                                        <div className='text-danger'>{formik.errors.userName}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="bsValidationPassword" className="form-label">Password:<span className="text-danger">*</span></label>
                                    <div className="input-group">
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            className={`form-control ${formik.touched.userPassword && formik.errors.userPassword ? 'is-invalid' : ''}`}
                                            id="bsValidationPassword"
                                            name="userPassword"
                                            placeholder="Password"
                                            tabIndex="10"
                                            value={formik.values.userPassword}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            required
                                        />
                                        <button
                                            type="button"
                                            className="input-group-text bg-transparent"
                                            onClick={togglePasswordVisibility}
                                        >
                                            <i className={`bx ${showPassword ? 'bx-hide' : 'bx-show'}`} />
                                        </button>
                                    </div>
                                    {formik.touched.userPassword && formik.errors.userPassword ? (
                                        <div className='text-danger'>{formik.errors.userPassword}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="bsValidationConfirmPassword" className="form-label">Confirm Password:<span className="text-danger">*</span></label>
                                    <div className="input-group">
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            className={`form-control ${formik.touched.confirmPassword && formik.errors.confirmPassword ? 'is-invalid' : ''}`}
                                            id="bsValidationConfirmPassword"
                                            name="confirmPassword"
                                            placeholder="Confirm Password"
                                            tabIndex="11"
                                            value={formik.values.confirmPassword}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            required
                                        />
                                        <button
                                            type="button"
                                            className="input-group-text bg-transparent"
                                            onClick={togglePasswordVisibility}
                                        >
                                            <i className={`bx ${showPassword ? 'bx-hide' : 'bx-show'}`}
                                            />
                                        </button>
                                    </div>
                                    {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                        <div className='text-danger'>{formik.errors.confirmPassword}</div>
                                    ) : null}
                                </div>
                                <div>
                                    <div className="row">
                                        <div className="col">

                                            <button className="btn btn-primary" type="submit" tabIndex="12">Create Account</button>

                                            <button className="ms-3 btn btn-primary" tabIndex="13" type="button" onClick={handleLogin}>Login</button>
                                        </div>
                                        {/* <div className="col">
                                            <button className="btn btn-primary" type="button" onClick={handleLogin}>Login</button>
                                        </div> */}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default RegisterComponent;
