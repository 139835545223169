import React, { useEffect, useState, useCallback } from "react";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router";
import DeleteModelPopupComponent from "../common-model-popup/deleteModelPopup.component";
import { FuelDeliveryService } from "../../../services/user/fueldelivery.service";
import { useSelector } from "react-redux";
import AuthGuard from "../../common/authguard.component";
import moment from "moment";
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from "../../common/notification/toaster_notify.component";

const FuelDeliveryComponent = () => {
    const [filterText, setFilterText] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState('');
    const [data, setData] = useState([])
    const [isRefresGrid, setisRefresGrid] = useState(false)
    const storeId = useSelector(state => state?.user?.selectedStoreId)
    const pageNumber = location.state?.pageNumber || 1;
    const [currentPage, setCurrentPage] = useState(pageNumber);
    const [selectedRows, setSelectedRows] = useState([]);


    const getFuelDeliveriesData = async (storeId, serchText = '') => {
        try {
            const res = await FuelDeliveryService.getFuelDeliveries(storeId, serchText)
            if (res) {
                setData(res)
            }
        } catch (error) {

        }
    };

    useEffect(() => {
        getFuelDeliveriesData(storeId, '')
    }, [isRefresGrid])

    const columns = [
        // { width: '10%', name: 'Fuel Delivery Date', selector: row => row.fuelDeliveryDate ? moment(row.invoiceDate).format('MM/DD/YYYY') : '', sortable: true },
        {
            width: '10%', name: 'Fuel Delivery Date',
            selector: row => {
                // Define the format of the input date
                const inputFormat = 'MM/DD/YYYY';
                const formattedDate = moment(row.fuelDeliveryDate, inputFormat).format('MM/DD/YYYY');
                return formattedDate;
            },
            sortable: true
        },
        { width: '10%', name: 'Invoice No', selector: row => row.invoiceNo, sortable: true },
        { width: '12%', name: 'Invoice Amount', selector: row => row.invoiceAmount, sortable: true },
        { width: '10%', name: 'Unleaded', selector: row => row.gallonsUnleaded, sortable: true },
        { width: '8%', name: 'Plus', selector: row => row.gallonsPlus, sortable: true },
        { width: '8%', name: 'Super', selector: row => row.gallonsPremium, sortable: true },
        { width: '10%', name: 'Desiel', selector: row => row.freightOnGasoline, sortable: true },
        { width: '8%', name: 'Paid', selector: row => row.paid, sortable: true },
        {
            width: '20%', name: 'Actions', cell: row => (
                <div className="d-flex">
                    <button type="button"
                        className="btn btn-primary me-2"
                        onClick={() => handleEditClick(row)} >
                        <span className="d-none d-md-block"> Edit</span>
                        <i className="d-md-none d-lg-none fadeIn animated bx bx-edit-alt"></i>
                    </button>

                    <button type="button"
                        className="btn btn-danger"
                        onClick={() => handleDeleteClick(row)}>
                        <span className="d-none d-md-block">Delete</span>
                        <i className="d-md-none d-lg-none fadeIn animated bx bx-trash-alt"></i>
                    </button>
                </div>
            ),
        }
    ];

    // const data = [
    //     { fuel_delivery_date: '01/01/2012', invoice_no: '12345', invoice_amount: '15000.00', unleaded: '3.00', plus: '3.00', super: '5.00', desiel: '7.00', paid: 'YES' },
    // ];

    const handleAddNewClick = () => {
        navigate('/purchaseinfo/fueldelivery/create', { state: { pageNumber: currentPage } });
    };

    const handleSearch = event => {
        const { value } = event.target;
        setFilterText(value);
    };

    const handleEditClick = (item) => {
        navigate('/purchaseinfo/fueldelivery/edit', { state: { fuelDeliveryData: item, pageNumber: currentPage } });
    };

    const handleDeleteClick = (item) => {
        setItemToDelete(item);
        setShowModal(true);
    };

    const handleDeleteSelectedRowClick = () => {
        if (selectedRows.length > 0) {
            setShowModal(true);
            setSelectedRows(selectedRows)
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            const idsToDelete = selectedRows.length > 0
                ? selectedRows.map(row => row.fuelDeliveryId.toString())
                : itemToDelete ? [itemToDelete.fuelDeliveryId.toString()] : [];

            if (idsToDelete.length > 0) {
                await FuelDeliveryService.deleteManyFuelDelivery(idsToDelete);

                setData(prevData => prevData.filter(item => !idsToDelete.includes(item.fuelDeliveryId.toString())));
                setSelectedRows([]);
                setItemToDelete(null);
            }
            setShowModal(false);
        } catch (error) {
            handleNotify(error.message || 'Error while deleting DailySales.', TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const filteredData = (data || []).filter(item =>
        Object.keys(item).some(key =>
            item[key]?.toString().toLowerCase().includes(filterText.toLowerCase())
        )
    );

    const onChangePage = (pageIndex) => {
        setCurrentPage(pageIndex)
    };

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    return (
        <AuthGuard page={"allowFuelDelivery"}>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Purchase Info</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item"></li>
                                    <li className="breadcrumb-item active" aria-current="page">Fuel Delivery</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="page-breadcrumb d-xl-none d-xl-block align-items-center mb-3">
                        <div className="breadcrumb-item pe-3 ">Purchase Info &gt; Fuel Delivery</div>
                    </div>
                    <hr />
                    <div className="bg-white col-md-12 p-3">
                        <div className="d-md-flex d-grid align-items-center gap-3 mb-5">
                        <div className="d-flex ">
                                <button type="submit"
                                    className="btn btn-primary px-4 mt-3 me-3"
                                    onClick={handleAddNewClick}>New</button>
                                <button
                                    type="button"
                                    className="btn btn-danger px-4 mt-3 ms-auto ms-md-0"
                                    onClick={handleDeleteSelectedRowClick}
                                >
                                    Delete
                                </button>
                            </div>
                            <div className="mt-3">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Search..."
                                    value={filterText}
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        selectableRows
                        onChangePage={onChangePage}
                        onSelectedRowsChange={handleRowSelected}
                        paginationDefaultPage={currentPage}
                    />
                </div>
            </div>

            {showModal &&
                <DeleteModelPopupComponent
                    show={showModal}
                    onClose={handleModalClose}
                    onConfirm={handleDeleteConfirm}
                    title="Confirm"
                    confirmText="Delete"
                    Message="Are you sure want to delete selected record(s)?" />
            }

        </AuthGuard>
    );
}

export default FuelDeliveryComponent;
