import { API_RESPONSE_STATUS, ResponseModel, handleGetAsync, handlePostAsync, handlePutAsync, handleDeleteAsync } from "../../components/common/commonclass";
import { EndPoint } from "../../components/common/config";
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from "../../components/common/notification/toaster_notify.component";


var responseModel = new ResponseModel();

export class DailySalesService {
    static async getDailySalesService(storeId, searchText = "") {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.FETCH_DAILY_SALES}`;
        const requestModel = { storeId, searchText };

        try {
            responseModel = await handleGetAsync(requestURL, true, requestModel);
            if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
                return responseModel.data;
            } else {
                throw new Error('Failed to fetch daily sales data.');
            }
        } catch (error) {
            console.error('Error fetching daily sales data:', error);
            throw error;
        }
    }

    // get by id
    static async getItemById(id) {
        // responseModel = new ResponseModel();
        // const requestURL = `${EndPoint.DAILY_SALES}/${id}`;
        // try {
        //     responseModel = await handleGetAsync(requestURL, false);
        //     if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
        //         return responseModel.data;
        //     } else {
        //         throw new Error('Failed to fetch item details.');
        //     }
        // } catch (error) {
        //     console.error('Error fetching item details:', error);
        //     throw error;
        // }

        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.DAILY_SALES}/${id}`; // endpoint

        responseModel = await handleGetAsync(requestURL, true);

        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            return responseModel.data;
        } else {
            throw new Error('Error fetching item details.');
        }

    }

    // update
    static async updateDailySalesService(id, data) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.DAILY_SALES}/${id}`;

        responseModel = await handlePutAsync(requestURL, true, data);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify('Daily sales data updated successfully.', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if (responseModel.status === API_RESPONSE_STATUS.FAIL) {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else {
            throw new Error('Failed to update daily sales data.');
        }
    }

    // create
    static async createDailySalesService(data) {
        responseModel = new ResponseModel();
        const requestURL = EndPoint.DAILY_SALES;

        responseModel = await handlePostAsync(requestURL, true, data);

        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify('Daily sales data added successfully!', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else if (responseModel && responseModel.status === API_RESPONSE_STATUS.FAIL) {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            return responseModel;
        } else {
            throw new Error('Failed to create new daily sales data.');
        }
    }

    // delete a single daily sales by ID
    static async deleteDailySalesService(id) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.DAILY_SALES}/${id}`;

        try {
            responseModel = await handleDeleteAsync(requestURL, true);
            if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
                handleNotify('Daily sales data deleted successfully.', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
                return responseModel.data;
            } else {
                throw new Error('Failed to delete daily sales data.');
            }
        } catch (error) {
            console.error('Error deleting daily sales data:', error);
            throw error;
        }
    }

    // delete many
    static async deleteManyDailySalesService(ids) {
        responseModel = new ResponseModel();
        const requestURL = `${EndPoint.DAILY_SALES}/deletemany`;

        responseModel = await handleDeleteAsync(requestURL, true, ids);
        if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
            handleNotify(responseModel.message, TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
            return responseModel.data;
        } else {
            handleNotify(responseModel.errormessage, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
        }
    }
}
