import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { CreditCardService } from "../../../services/user/creditcard.services";
import { useSelector } from "react-redux";
import moment from "moment";
import { handleNotify, TOASTER_TYPE, TOASTER_POSITION } from "../../common/notification/toaster_notify.component";
import AuthGuard from "../../common/authguard.component";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AddNewCreditCardComponent = ({
    isInWizard = false,
    onNext,
    onBack,
    showBreadcrumbs = true,
    showButtons = true,
    showWrapper = true,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const pageNumber = location.state?.pageNumber || 0;
    // const creditCardData = location.state?.creditCardData || {};
    const creditCardId = location.state?.creditCardId || 0;
    const isEditMode = !!location.state?.creditCardId;
    const selectedStore = useSelector(state => state?.user?.selectedStoreId);
    const formik = useFormik({
        initialValues: {
            creditCardId: 0,
            invoiceDate: moment(new Date()).format('MM/DD/YYYY') || '',
            invoiceNo: '',
            amount: 0,
            fee: 0,
            total: 0,
            paid: 'NO',
            storeId: selectedStore,
        },
        validationSchema: Yup.object({
            invoiceDate: Yup.date().required('Date is required').typeError('Invalid Date Format'),
            invoiceNo: Yup.string().required('Invoice No is required'),
        }),
        onSubmit: async (values) => {
            try {
                values.amount=(parseFloat(values.amount)) || 0;
                values.fee=(parseFloat(values.fee)) || 0;

                if (isEditMode) {
                    await CreditCardService.updateCreditCard(creditCardId, values)
                        .then(response => {
                            if (response?.isSucess) {
                                navigate('/salesinfo/creditcards', { state: { pageNumber: pageNumber } });
                            }
                        });
                } else {

                    await CreditCardService.createCreditCard(values)
                        .then(response => {
                            if (response?.isSucess) {
                                if (isInWizard) {
                                    onNext();
                                }
                                else {
                                    navigate('/salesinfo/creditcards', { state: { pageNumber: pageNumber } });
                                }
                            }
                        });
                }
            } catch (error) {
                handleNotify('An error occurred while saving the daily sales.', TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            }
        }
    });

    useEffect(() => {

        if(isEditMode){
            const fetchCreditCardDetail = async () =>{
                try{
                    const data = await CreditCardService.getCreditCardById(creditCardId);
                    formik.setValues({
                        creditCardId: data?.creditCardId,
                        invoiceDate: moment(data?.invoiceDate).format('YYYY-MM-DD'),
                        invoiceNo: data?.invoiceNo || '',
                        amount: data?.amount || 0,
                        fee: data?.fee || 0,
                        total: data?.total || 0,
                        paid: data?.paid || 'NO',
                        storeId: data?.selectedStore || selectedStore,
                    })
                }
                catch(error){
                    console.error('Error fetching creditcard details:', error);
                }
            }
            fetchCreditCardDetail();
        }
    }, []);

    const calculateTotal = (amount, fee) => {
        const total = (parseFloat(amount) || 0) + (parseFloat(fee) || 0);
        return total.toFixed(2);
    };

    //     const handleChange = (e) => {
    //     //formik.handleChange(e);
    //     // const newTotal = addTotal(formik.values.fuel, formik.values.beer, e.target.value, formik.values.taxGrocery);
    //     formik.setFieldValue('total', calculateTotal(e));
    // 

    // useEffect(() => {
    //     formik.setFieldValue('total', calculateTotal());
    // }, [formik.values.amount, formik.values.fee]);

    const handleCancelClick = () => {
        navigate('/salesinfo/creditcards', { state: { pageNumber: pageNumber } });
    };

    return (
        <AuthGuard page={"allowCreditCards"}>
            <div className={showWrapper ? 'page-wrapper' : ''}>
                <div className="page-content">
                    {showBreadcrumbs && !isInWizard && (
                        <>
                            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                <div className="breadcrumb-title pe-3">Credit Cards</div>
                                <div className="ps-3">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 p-0">
                                            <li className="breadcrumb-item"></li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                {isEditMode ? 'Edit Credit Card' : 'Add Credit Card'}
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="page-breadcrumb d-xl-none d-xl-block align-items-center mb-3">
                                <div className="breadcrumb-item pe-3 ">{isEditMode ? 'Edit Credit Card' : 'Add Credit Card'}</div>
                            </div>
                        </>
                    )}
                    {!isInWizard && (<hr />)}
                    <div className="row">
                        <div className="col-xl-12 mx-auto">
                            <div className="card">
                                <div className="card-body p-4">
                                    <form className="row g-3 needs-validation" onSubmit={formik.handleSubmit}>
                                        <div className="col-md-12">
                                            <label htmlFor="invoiceDate" className="form-label">Date: <span className="text-danger">*</span></label>
                                            {/* <input
                                                type="date"
                                                name="invoiceDate"
                                                value={formik.values.invoiceDate}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                className={`form-control ${formik.touched.invoiceDate && formik.errors.invoiceDate ? 'is-invalid' : ''}`}
                                                id="invoiceDate"
                                            /> */}

                                            <DatePicker
                                                selected={formik.values.invoiceDate}
                                                onChange={val => {
                                                    formik.setFieldValue("invoiceDate", val);
                                                }}
                                                onBlur={formik.handleBlur}
                                                name="invoiceDate"
                                                dateFormat="MM/dd/YYYY" // Display format in the input field
                                                className={`form-control ${formik.touched.invoiceDate && formik.errors.invoiceDate ? 'is-invalid' : ''}`}
                                                id="invoiceDate"
                                            />
                                            {formik.touched.invoiceDate && formik.errors.invoiceDate && (
                                                <div className='text-danger'>{formik.errors.invoiceDate}</div>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="invoiceNo" className="form-label">Invoice No: <span className="text-danger">*</span></label>
                                            <input
                                                name="invoiceNo"
                                                autoComplete="off"
                                                value={formik.values.invoiceNo}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                type="text"
                                                className={`form-control ${formik.touched.invoiceNo && formik.errors.invoiceNo ? 'is-invalid' : ''}`}
                                                id="invoiceNo"
                                            />
                                            {formik.touched.invoiceNo && formik.errors.invoiceNo && (
                                                <div className='text-danger'>{formik.errors.invoiceNo}</div>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="amount" className="form-label">Amount</label>
                                            <input
                                                type="number"
                                                name="amount"
                                                value={formik.values.amount}
                                                // onChange={formik.handleChange}

                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    const newTotal = calculateTotal(e.target.value, formik.values.fee);
                                                    formik.setFieldValue('total', newTotal);
                                                }}
                                                onBlur={formik.handleBlur}
                                                className={`form-control ${formik.touched.amount && formik.errors.amount ? 'is-invalid' : ''}`}
                                                id="amount"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="fee" className="form-label">Fee</label>
                                            <input
                                                type="number"
                                                name="fee"
                                                value={formik.values.fee}
                                                // onChange={formik.handleChange}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    const newTotal = calculateTotal(e.target.value, formik.values.amount);
                                                    formik.setFieldValue('total', newTotal);
                                                }}
                                                onBlur={formik.handleBlur}
                                                className={`form-control ${formik.touched.fee && formik.errors.fee ? 'is-invalid' : ''}`}
                                                id="fee"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="total" className="form-label">Total</label>
                                            <input
                                                type="number"
                                                name="total"
                                                value={formik.values.total}
                                                // onChange={handleChange}
                                                onBlur={formik.handleBlur}
                                                className={`form-control ${formik.touched.total && formik.errors.total ? 'is-invalid' : ''}`}
                                                id="total"
                                                readOnly
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="paid" className="form-label">Paid</label>
                                            <select
                                                name="paid"
                                                id="paid"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.paid}
                                                className={`form-select ${formik.touched.paid && formik.errors.paid ? 'is-invalid' : ''}`}
                                            >
                                                <option value="YES">YES</option>
                                                <option value="NO">NO</option>
                                            </select>
                                        </div>
                                        <div className="col-md-12 mt-5">
                                            <div className="d-md-flex d-grid align-items-center gap-3">
                                                {showButtons && (
                                                    <>
                                                        {isInWizard ? (
                                                            <>
                                                                <button type="submit" className="btn btn-primary me-2">Save & Next</button>
                                                                <button type="button" className="btn btn-outline-secondary px-4" onClick={onBack}>Back</button>
                                                                <button type="button" className="btn btn-outline-secondary px-4" onClick={onNext}>Skip</button>
                                                                <button type="button" className="btn btn-outline-secondary px-4" onClick={()=>navigate('/dashboard')}>Finish</button>
                                                            </>
                                                        ) : (
                                                            <div className="col-md-12 mt-2">
                                                                <button type="submit" className="btn btn-primary px-4 me-3" onClick={onNext}>
                                                                    {isEditMode ? 'Update' : 'Save'}
                                                                </button>
                                                                <button type="button" className="btn btn-danger px-4" onClick={handleCancelClick}>
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </AuthGuard>
    );
};

export default AddNewCreditCardComponent;
