import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { UserService } from '../../../services/user/user.services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';


const MyProfileComponent = () => {
    const userdetail = useSelector((state) => state.user);
    const [showPassword, setShowPassword] = useState(false);
    //const [isEditingData, setIsEditingData] = useState(false);

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            contactNo: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            country: '',
            userName: '',
            userPassword: '',
            confirmPassword: '',
        },

        validationSchema: Yup.object({
            firstName: Yup.string()
                .required('First Name is required'),
            lastName: Yup.string()
                .required('Last Name is required'),
            email: Yup.string()
                .email('Invalid email address')
                .required('Email is required'),
            contactNo: Yup.string()
                .required('Contact Number is required'),
            userName: Yup.string()
                .required('User Name is required'),
            userPassword: Yup.string()
                .min(6, 'Password should be of minimum 6 characters length')
                .required('Password is required'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('userPassword'), null], 'Passwords must match')
                .required('Confirm Password is required')
        }),

        onSubmit: async (values) => {
            try {
                await UserService.updateUserById(userdetail.id, values)

                toast.success('User Data updated successfully ')
            }
            catch (error) {
                console.error('Failed to update userData', error);
            }
        },
    });

    const handleReset = () => {
        formik.resetForm();
        fetchUserData();
    };

    const fetchUserData = async () => {
        try {
            const response = await UserService.getuserbyid(userdetail.id);
            if (response) {
                formik.setValues({
                    address1: response.address1,
                    address2: response.address2,
                    email: response.email,
                    contactNo: response.contactNo,
                    city: response.city,
                    state: response.state,
                    country: response.country,
                    firstName: response.firstName,
                    lastName: response.lastName,
                    userPassword: response.userPassword,
                    userName: response.userName,
                    confirmPassword: ''
                });
            }
        }
        catch (error) {
            console.error('Failed to fetch user data', error)
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    }

    return (
        <>
            <div className="page-wrapper">
                {/* {formik.values.firstName !== '' && */}
                <>
                    <div className="page-content">
                        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                            <div className="breadcrumb-title pe-3">Account Setup</div>
                            <div className="ps-3">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 p-0">
                                        <li className="breadcrumb-item"></li>
                                        <li className="breadcrumb-item active" aria-current="page">Profile</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="page-breadcrumb d-xl-none d-xl-block align-items-center mb-3">
                            <div className="breadcrumb-item pe-3 ">Profile</div>
                        </div>
                        <hr />
                        <div className="card">
                            <div className="card-body p-4">
                                <form onSubmit={formik.handleSubmit} className="row g-3 needs-validation">
                                    <div className="col-md-6">
                                        <label htmlFor="bsFirstname" className="form-label">First Name<span className="text-danger">*</span>:</label>
                                        <input
                                            type="text"
                                            className={`form-control ${formik.touched.firstName && formik.errors.firstName ? 'is-invalid' : ''}`}
                                            id="bsFirstname"
                                            name="firstName"
                                            tabIndex="1"
                                            autoComplete="off"
                                            placeholder="First Name"
                                            value={formik.values.firstName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.firstName && formik.errors.firstName ? (
                                            <div className='text-danger'>{formik.errors.firstName}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="bsLastname" className="form-label">Last Name<span className="text-danger">*</span>:</label>
                                        <input
                                            type="text"
                                            className={`form-control ${formik.touched.lastName && formik.errors.lastName ? 'is-invalid' : ''}`}
                                            id="bsLastname"
                                            name="lastName"
                                            placeholder="Last Name"
                                            tabIndex="2"
                                            autoComplete="off"
                                            value={formik.values.lastName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.lastName && formik.errors.lastName ? (
                                            <div className='text-danger'>{formik.errors.lastName}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="bsEmail" className="form-label">Email<span className="text-danger">*</span>:</label>
                                        <input
                                            type="email"
                                            className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                                            id="bsEmail"
                                            name="email"
                                            placeholder="Email"
                                            tabIndex="3"
                                            autoComplete="off"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.email && formik.errors.email ? (
                                            <div className='text-danger'>{formik.errors.email}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="bsContactNo" className="form-label">Contact No<span className="text-danger">*</span>:</label>
                                        <input
                                            type="text"
                                            className={`form-control ${formik.touched.contactNo && formik.errors.contactNo ? 'is-invalid' : ''}`}
                                            id="bsContactNo"
                                            name="contactNo"
                                            placeholder="Contact No"
                                            tabIndex="4"
                                            autoComplete="off"
                                            value={formik.values.contactNo}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.contactNo && formik.errors.contactNo ? (
                                            <div className='text-danger'>{formik.errors.contactNo}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="bsAddress1" className="form-label">Address 1:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="bsAddress1"
                                            name="address1"
                                            placeholder="Address 1"
                                            tabIndex="5"
                                            autoComplete="off"
                                            value={formik.values.address1}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="bsAddress2" className="form-label">Address 2:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="bsAddress2"
                                            name="address2"
                                            placeholder="Address 2"
                                            tabIndex="6"
                                            autoComplete="off"
                                            value={formik.values.address2}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="bsCity" className="form-label">City:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="bsCity"
                                            name="city"
                                            placeholder="City"
                                            tabIndex="7"
                                            autoComplete="off"
                                            value={formik.values.city}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="bsState" className="form-label">State:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="bsState"
                                            name="state"
                                            placeholder="State"
                                            tabIndex="8"
                                            autoComplete="off"
                                            value={formik.values.state}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="bsCountry" className="form-label">Country:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="bsCountry"
                                            name="country"
                                            placeholder="Country"
                                            tabIndex="9"
                                            autoComplete="off"
                                            value={formik.values.country}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="bsUsername" className="form-label">User Name:<span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${formik.touched.userName && formik.errors.userName ? 'is-invalid' : ''}`}
                                            id="bsValidationUsername"
                                            name="userName"
                                            placeholder="UserName"
                                            tabIndex="10"
                                            value={formik.values.userName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} required
                                        />
                                        {formik.touched.userName && formik.errors.userName ? (
                                            <div className='text-danger'>{formik.errors.userName}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="bsUserPassword" className="form-label">Password:<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                className={`form-control ${formik.touched.userPassword && formik.errors.userPassword ? 'is-invalid' : ''}`}
                                                id="bsUserPassword"
                                                name="userPassword"
                                                placeholder="Password"
                                                tabIndex="11"
                                                value={formik.values.userPassword}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                required
                                            />
                                            <button
                                                type="button"
                                                className="input-group-text bg-transparent"
                                                onClick={togglePasswordVisibility}
                                            >
                                                <i className={`bx ${showPassword ? 'bx-hide' : 'bx-show'}`} />
                                            </button>
                                        </div>
                                        {formik.touched.userPassword && formik.errors.userPassword ? (
                                            <div className='text-danger'>{formik.errors.userPassword}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="bsConfirmPassword" className="form-label ">Confirm Password:<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                className={`form-control ${formik.touched.confirmPassword && formik.errors.confirmPassword ? 'is-invalid' : ''}`}
                                                id="bsConfirmPassword"
                                                name="confirmPassword"
                                                placeholder="Confirm Password"
                                                tabIndex="12"
                                                value={formik.values.confirmPassword}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                required
                                            />
                                            <button
                                                type="button"
                                                className="input-group-text bg-transparent"
                                                onClick={togglePasswordVisibility}
                                            >
                                                <i className={`bx ${showPassword ? 'bx-hide' : 'bx-show'}`}
                                                />
                                            </button>
                                        </div>
                                        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                            <div className='text-danger'>{formik.errors.confirmPassword}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-12 mt-4">
                                        <button className="btn btn-primary px-4 me-3" type="submit" tabIndex="13">Save</button>
                                        <button className="btn btn-danger px-4" type="button" tabIndex="14" onClick={handleReset}>Cancel</button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
                {/* } */}

            </div>
        </>
    );
};

export default MyProfileComponent;
