import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReportService } from '../../../services/user/report.service';
import { formateDate, getLeftMenuAccess, REPORT_TYPE } from '../../../utility';
import { shallowEqual, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { mkConfig, generateCsv, download } from "export-to-csv";
import { ReportTable } from './report-table.component';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { selectStore } from '../../../redux/user/userslice';
const csvConfig = mkConfig({ useKeysAsHeaders: true });

const GeneralReportsComponent = () => {
    const user = useSelector(({ user }) => user, shallowEqual);
    const storeId = user.selectedStoreId;
    const stores = user.stores;
    const selectedStore = stores?.find(store => store.storeId === storeId);
    //const storeId = useSelector(state => state?.user?.selectedStoreId);
    const [data, setdata] = useState([])
    const today = moment(new Date()).format('MM/DD/YYYY');
    const olddate = moment(today).add(-7, 'days').format('MM/DD/YYYY');
    const reportAccess = getLeftMenuAccess(selectedStore);

    const formik = useFormik({
        initialValues: {
            startDate: olddate,
            endDate: today,
            reportType: 'DailySales',
            reportTimeFrame: 'daily'
        },

        validationSchema: Yup.object({
            startDate: Yup.date()
                .required('Start Date is required')
                .nullable(),
            endDate: Yup.date()
                .required('End Date is required')
                .nullable(),
        }),

        onSubmit: async (values) => {
            const reportType = values.reportTimeFrame

            const model = {
                reportName: values.reportType,
                startDate: moment(values.startDate).format("YYYY-MM-DD"),
                endDate: moment(values.endDate).format("YYYY-MM-DD"),
                storeId: storeId,
                reportType: values.reportTimeFrame
            }
            switch (reportType) {
                case REPORT_TYPE.daily:
                    const reportDaily = await ReportService.generateReportDaily(model)
                    if (reportDaily)
                        if((values.reportType === "Payments") ||  (values.reportType === "Expenses")){
                            const data=formateDate(reportDaily)
                            setdata(data)
                        }else{
                            setdata(reportDaily)
                        }
                    break;
                case REPORT_TYPE.monthly:
                    const reportMonthly = await ReportService.generateReportMonthly(model)
                    if (reportMonthly)
                        setdata(reportMonthly)
                    break;
                case REPORT_TYPE.yearly:
                    const reportYearly = await ReportService.generateReportYearly(model)
                    if (reportYearly)
                        setdata(reportYearly)
                    break;
                default:

            }
        },
    });

    const handleReset = () => {
        formik.resetForm();
        setdata([])
    };

    const generate = () => {
        download(csvConfig)(generateCsv(csvConfig)(data))
    }
    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Reports Setup</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item"></li>
                                    <li className="breadcrumb-item active" aria-current="page">Generate Reports</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body p-4">
                            <form onSubmit={formik.handleSubmit} className="row g-3 needs-validation">
                                <div className="col-md-4">
                                    <label htmlFor="startDate" className="form-label">Start Date<span className="text-danger">*</span>:</label>
                                    {/* <input
                                        type="date"
                                        className={`form-control ${formik.touched.startDate && formik.errors.startDate ? 'is-invalid' : ''}`}
                                        id="startDate"
                                        name="startDate"
                                        value={formik.values.startDate}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    /> */}
                                    <DatePicker
                                        selected={formik.values.startDate}
                                        onChange={val => {
                                            formik.setFieldValue("startDate", val);
                                        }}
                                        onBlur={formik.handleBlur}
                                        name="startDate"
                                        //dateFormat="MM/dd/YYYY" // Display format in the input field
                                        className={`form-control ${formik.touched.startDate && formik.errors.startDate ? 'is-invalid' : ''}`}
                                        id="startDate"
                                    />
                                    {formik.touched.startDate && formik.errors.startDate ? (
                                        <div className='text-danger'>{formik.errors.startDate}</div>
                                    ) : null}
                                </div>

                                <div className="col-md-4 ">
                                    <label htmlFor="endDate" className="form-label">End Date<span className="text-danger">*</span>:</label>
                                    <DatePicker
                                        selected={formik.values.endDate}
                                        onChange={val => {
                                            formik.setFieldValue("endDate", val);
                                        }}
                                        onBlur={formik.handleBlur}
                                        name="endDate"
                                        className={`form-control ${formik.touched.endDate && formik.errors.endDate ? 'is-invalid' : ''}`}
                                        id="endDate"
                                    />

                                    {formik.touched.endDate && formik.errors.endDate ? (
                                        <div className='text-danger'>{formik.errors.endDate}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-4 "></div>
                                <div className="col-md-4">
                                    <fieldset className="mb-3">
                                        <legend className="col-form-label">Reports:</legend>
                                        <div>
                                            {stores.length > 0 && reportAccess.allowDailySales && (
                                                <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    id="DailySales"
                                                    name="reportType"
                                                    value="DailySales"
                                                    checked={formik.values.reportType === 'DailySales'}
                                                    onChange={(event) => {
                                                        formik.handleChange(event)
                                                        setdata([])
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="profitLossStatement">
                                                    Daily Sales
                                                </label>
                                            </div>
                                            )}
                                            
                                            {stores.length > 0 && reportAccess.allowExpenses && (
                                                <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    id="Expenses"
                                                    name="reportType"
                                                    value="Expenses"
                                                    checked={formik.values.reportType === 'Expenses'}
                                                    onChange={(event) => {
                                                        formik.handleChange(event)
                                                        setdata([])
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="Expenses">
                                                    Expenses
                                                </label>
                                            </div>
                                            )}


                                            {stores.length > 0 && reportAccess.allowPayments && (
                                                <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    id="Payments"
                                                    name="reportType"
                                                    value="Payments"
                                                    checked={formik.values.reportType === 'Payments'}
                                                    onChange={(event) => {
                                                        formik.handleChange(event)
                                                        setdata([])
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="Payments">
                                                    Payments
                                                </label>
                                            </div>
                                            )}
                                            

                                            {stores.length > 0 && reportAccess.allowCreditCards && (
                                                <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    id="storeGrossProfitByCategory"
                                                    name="reportType"
                                                    value="Credit Cards"
                                                    checked={formik.values.reportType === 'Credit Cards'}
                                                    onChange={(event) => {
                                                        formik.handleChange(event)
                                                        setdata([])
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="CreditCards">
                                                    Credit Card
                                                </label>
                                            </div>
                                            )}


                                            {stores.length > 0 && reportAccess.allowFuelDelivery && (
                                                <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    id="Fuel Delivery"
                                                    name="reportType"
                                                    value="Fuel Delivery"
                                                    checked={formik.values.reportType === 'Fuel Delivery'}
                                                    onChange={(event) => {
                                                        formik.handleChange(event)
                                                        setdata([])
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="FuelDelivery">
                                                    Fuel Delivery
                                                </label>
                                            </div>
                                            )}
                                            

                                            {stores.length > 0 && reportAccess.allowFuelDelivery && (
                                                <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    id="Rebates/Commission"
                                                    name="reportType"
                                                    value="Rebates/Commission"
                                                    checked={formik.values.reportType === 'Rebates/Commission'}
                                                    onChange={(event) => {
                                                        formik.handleChange(event)
                                                        setdata([])
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="RebatesCommission">
                                                    Rebates/Commission

                                                </label>
                                            </div>
                                            )}
                                        </div>
                                    </fieldset>
                                </div>
                                <div className="col-md-6">
                                    <fieldset className="mb-3">
                                        <legend className="col-form-label">Details:</legend>
                                        <div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    id="daily"
                                                    name="reportTimeFrame"
                                                    value="daily"
                                                    checked={formik.values.reportTimeFrame === 'daily'}
                                                    onChange={(event) => {
                                                        formik.handleChange(event)
                                                        setdata([])
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="profitLossStatement">
                                                    Daily
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    id="monthly"
                                                    name="reportTimeFrame"
                                                    value="monthly"
                                                    checked={formik.values.reportTimeFrame === 'monthly'}
                                                    onChange={(event) => {
                                                        formik.handleChange(event)
                                                        setdata([])
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="monthly">
                                                    Monthly
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    id="yearly"
                                                    name="reportTimeFrame"
                                                    value="yearly"
                                                    checked={formik.values.reportTimeFrame === 'yearly'}
                                                    onChange={(event) => {
                                                        formik.handleChange(event)
                                                        setdata([])
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="yearly">
                                                    Yearly
                                                </label>
                                            </div>

                                        </div>
                                    </fieldset>
                                </div>
                                <div className="col-md-12">
                                    <button className="btn btn-primary" type="submit" >View Report</button>
                                    <button className="btn btn-danger m-3" type="button" onClick={handleReset}>Cancel</button>
                                </div>
                            </form>
                        </div>

                        {/* <button className="btn btn-primary" onClick={generate}>Export To Excel</button> */}
                        {data?.length > 0 && <ReportTable data={data} reportName={formik.values.reportType} reportType={formik.values.reportTimeFrame} />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default GeneralReportsComponent;
