import { API_RESPONSE_STATUS, ResponseModel, handleGetAsync, handlePostAsync, handlePutAsync,  } from "../../components/common/commonclass"
import { EndPoint } from "../../components/common/config";
import { handleNotify, TOASTER_POSITION, TOASTER_TYPE } from "../../components/common/notification/toaster_notify.component";


let responseModel=new ResponseModel();

export class UserService {

    static async login(username,password)
    {
        responseModel=new ResponseModel();
        const requestURL=`${EndPoint.AUTH}/login`;
        const model={
            username:username,
            password:password
        }
        // responseModel=await handleGetAsync(requestURL,false,model);
        responseModel=await handlePostAsync(requestURL,false,model);
        if(responseModel && (responseModel.status===API_RESPONSE_STATUS.SUCCESS || responseModel.status===API_RESPONSE_STATUS.UNAUTHORIZED)){
            return responseModel;
        }
    }

    static async getusers(){
        responseModel=new ResponseModel();
        const requestURL=EndPoint.USER;
        
        responseModel=await handleGetAsync(requestURL,true);

        if(responseModel && (responseModel.status===API_RESPONSE_STATUS.SUCCESS ||responseModel.status === API_RESPONSE_STATUS.UNAUTHORIZED)){
            return responseModel.data;
        }
    }

    //get user by Id api
    static async getuserbyid(id){
        responseModel = new ResponseModel();
        const requestURL = EndPoint.USER+'/'+id;

        responseModel = await handleGetAsync(requestURL,true);

        if(responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS){
            return responseModel.data;
        }
        else{
            throw new Error('Fail to get users Data');
        }
    }

    //update User By id api
    static async updateUserById(id, data){
        responseModel = new ResponseModel();
        const requestURL = EndPoint.USER+'/'+id;

        responseModel = await handlePutAsync(requestURL, true, data);

        if(responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS){
            return responseModel.data;
        }
        else{
            throw new Error('Fail to update users Data');
        }
    }

     // create
     static async createUser(data) {
        const requestURL = EndPoint.USER;
        try {
            const responseModel = await handlePostAsync(requestURL, false, data);

            if (responseModel && responseModel.status === API_RESPONSE_STATUS.SUCCESS) {
                handleNotify('User created successfully!', TOASTER_TYPE.SUCCESS, TOASTER_POSITION.TOP_RIGHT);
                return responseModel;
            } else if (responseModel && responseModel.status === API_RESPONSE_STATUS.FAIL) {
                handleNotify("User Name is already in use, Please provide differnet User Name", TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT)
                //handleNotify(responseModel.errormessage || 'Failed to create user', TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
                return responseModel;
            } 
        } catch (error) {
           // handleNotify(error.message, TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            throw error;
        }
    }
}

