import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { RebatesAndCommissionService } from "../../../services/user/rebates.services";
import { useSelector } from "react-redux";
import moment from "moment";
import { handleNotify, TOASTER_TYPE, TOASTER_POSITION } from "../../common/notification/toaster_notify.component";
import { VendorService } from "../../../services/user/vendor.service";
import AuthGuard from "../../common/authguard.component";
import AddVendorComponent from "../account-setup/add-vendor.component";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AddNewRebatesAndCommission = ({
    isInWizard = false,
    onNext,
    onBack,
    showBreadcrumbs = true,
    showButtons = true,
    showWrapper = true,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isEditMode = !!location.state?.rebatesId;
    const pageNumber = location.state?.pageNumber || 0;
    const rebatesId = location.state?.rebatesId || 0;
    const selectedStore = useSelector(state => state?.user?.selectedStoreId);
    const [vendors, setVendors] = useState([]);
    const [newVendorId, setNewVendorId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [vendorsRerender, setvendorsRerender] = useState(false);

    const formik = useFormik({
        initialValues: {
            rebatesId: 0,
            date: moment(new Date()).format('MM/DD/YYYY') || '',
            vendorId: '',
            amount: 0,
            description: '',
            storeId: selectedStore,
        },

        validationSchema: Yup.object({
            date: Yup.date().required('Date is required').typeError('Invalid Date Format'),
            vendorId: Yup.string().required('Vendor Name is required'),
        }),
        
        onSubmit: async (values) => {
            try {
                values.amount=(parseFloat(values.amount)) || 0;

                if (isEditMode) {
                    await RebatesAndCommissionService.updateRebateOrCommission(rebatesId, values)
                        .then(response => {
                            if (response?.isSucess) {
                                navigate('/salesinfo/rebates', { state: { pageNumber: pageNumber } });
                            }
                        });
                } else {
                    await RebatesAndCommissionService.addRebateOrCommission(values)
                        .then(response => {
                            if (response?.isSucess) {
                                if (isInWizard) {
                                    onNext();
                                }
                                else {
                                    navigate('/salesinfo/rebates', { state: { pageNumber: pageNumber } });
                                }
                            }
                        });
                }
            } catch (error) {
                handleNotify('An error occurred while saving the Rebates.', TOASTER_TYPE.ERROR, TOASTER_POSITION.TOP_RIGHT);
            }
        }
    });

    const fetchVendors = async () => {
        try {
            const response = await VendorService.getvendors(selectedStore);
            const sortedVendors = response.sort((a,b) => b.vendorId - a.vendorId);
            formik.values.vendorId=sortedVendors?.[0]?.vendorId;
            formik.setFieldTouched('vendorId', '');
            formik.setFieldError('vendorId', '');
            setVendors(sortedVendors);

        } catch (error) {
            console.error("Error fetching vendors:", error);
        }
    };

    useEffect(() => {
        fetchVendors();
    }, [newVendorId, vendorsRerender])

    useEffect(() => {

        if (isEditMode) {
            const fetchRebateDetails = async () => {
                try {
                    const data = await RebatesAndCommissionService.getRebateOrCommissionById(rebatesId);
                    //formik.setValues(data);
                    formik.setValues({
                        rebatesId: data?.rebatesId,
                        date: data?.date ? moment(data?.date).toDate() : new Date(),
                        vendorId: data?.vendorId || 0,
                        amount: data?.amount || 0,
                        description: data?.description || '',
                        storeId: data?.selectedStore,
                    })
                } catch (error) {
                    console.error('Error fetching item details:', error);
                }
            };
            fetchRebateDetails();
        }
    }, []);

    const handleCancelClick = () => {
        navigate('/salesinfo/rebates', { state: { pageNumber: pageNumber } });
    };

    const handleClosePopup = () => {
        setIsModalOpen(false);
        // fetchVendors(); 
        setvendorsRerender(!vendorsRerender)
    }

    return (
        <AuthGuard page="allowRebates">
            <div className={showWrapper ? "page-wrapper" : ''}>
                <div className="page-content">
                    {showBreadcrumbs && !isInWizard && (
                        <>
                            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                <div className="breadcrumb-title pe-3">Rebates & Commission</div>
                                <div className="ps-3">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 p-0">
                                            <li className="breadcrumb-item"></li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                {isEditMode ? 'Edit Rebate & Commission' : 'Add Rebate & Commission'}
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="page-breadcrumb d-xl-none d-xl-block align-items-center mb-3">
                                <div className="breadcrumb-item pe-3 ">{isEditMode ? 'Edit Rebate & Commission' : 'Add Rebate & Commission'}</div>
                            </div>
                        </>
                    )}

                    {!isInWizard && (<hr />)}
                    <div className="row">
                        <div className="col-xl-12 mx-auto">
                            <div className="card">
                                <div className="card-body p-4">
                                    <form className="row g-3 needs-validation" onSubmit={formik.handleSubmit}>
                                        <div className="col-md-12">
                                            <label htmlFor="date" className="form-label">
                                                Date: <span className="text-danger">*</span>
                                            </label>
                                            {/* <input
                                                type="date"
                                                name="date"
                                                id="date"
                                                value={formik.values.date}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                className={`form-control ${formik.touched.date && formik.errors.date ? 'is-invalid' : ''}`}
                                                placeholder="Date"
                                            /> */}
                                            <DatePicker
                                                selected={formik.values.date}
                                                onChange={val => {
                                                    formik.setFieldValue("date", val);
                                                }}
                                                onBlur={formik.handleBlur}
                                                name="date"
                                                dateFormat="MM/dd/YYYY"
                                                className={`form-control ${formik.touched.date && formik.errors.date ? 'is-invalid' : ''}`}
                                                id="date"
                                            />
                                            {formik.touched.date && formik.errors.date ? (
                                                <div className="text-danger">{formik.errors.date}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="vendorId" className="form-label">Vendor Name<span className="text-danger">*</span>:</label>
                                            <div className="d-flex align-items-center">
                                                <select
                                                    className={`form-select ${formik.touched.vendorId && formik.errors.vendorId ? 'is-invalid' : ''}`}
                                                    id="vendorId"
                                                    name="vendorId"
                                                    value={formik.values.vendorId}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                >
                                                    {/* <option value="">Select Vendor</option> */}
                                                    {vendors && vendors.map(vendor => (
                                                          <option key={vendor.vendorId} value={vendor.vendorId}>
                                                            {vendor.vendorName}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="circle-plus ms-2" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add New Vendor" >
                                                    <i className="lni lni-circle-plus" onClick={() => setIsModalOpen(true)}>
                                                    </i>
                                                </div>
                                            </div>
                                            {formik.touched.vendorId && formik.errors.vendorId ? (
                                                <div className='text-danger'>{formik.errors.vendorId}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="amount" className="form-label">
                                                Amount:
                                            </label>
                                            <input
                                                type="number"
                                                name="amount"
                                                id="amount"
                                                value={formik.values.amount}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                className={`form-control ${formik.touched.amount && formik.errors.amount ? 'is-invalid' : ''}`}
                                                placeholder="Amount"
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="description" className="form-label">
                                                Description:
                                            </label>
                                            <textarea
                                                name="description"
                                                id="description"
                                                value={formik.values.description}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                className={`form-control ${formik.touched.description && formik.errors.description ? 'is-invalid' : ''}`}
                                                placeholder="Description"
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <div className="d-md-flex d-grid align-items-center gap-3 mt-5">
                                                {showButtons && (
                                                    <>
                                                        {isInWizard ? (
                                                            <>

                                                                <button type="submit" className="btn btn-primary me-2">Save & Next</button>

                                                                <button type="button" className="btn btn-outline-secondary px-4" onClick={onBack}>
                                                                    Back
                                                                </button>
                                                                <button type="button" className="btn btn-outline-secondary px-4" onClick={onNext}>
                                                                    Skip
                                                                </button>
                                                                <button type="button" className="btn btn-outline-secondary px-4" onClick={() => navigate('/dashboard')}>
                                                                    Finish
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <div className="col-md-12 mt-2">
                                                                <button type="submit" className="btn btn-primary px-4 me-3" onClick={onNext}>
                                                                    {isEditMode ? 'Update' : 'Save'}
                                                                </button>
                                                                <button type="button" className="btn btn-danger px-4" onClick={handleCancelClick}>
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal for Adding New Vendor */}
            <div className={`modal fade ${isModalOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: isModalOpen ? 'block' : 'none' }}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add New Vendor</h5>
                            <button type="button" className="btn-close" onClick={() => setIsModalOpen(false)}></button>
                        </div>
                        <div className="modal-body">
                            <AddVendorComponent isInPayment={true} showBreadcrumbs={false} showWrapper={false} isPopUp={true} handleClosePopup={handleClosePopup} onCancel={handleClosePopup} setNewVendorId={setNewVendorId} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => setIsModalOpen(false)}>Close</button>
                        </div>
                    </div>
                </div>=
            </div>
        </AuthGuard>
    );
};

export default AddNewRebatesAndCommission;
